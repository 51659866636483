import React, { useRef, useState, useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import PageHeader from "../../../shared/PageHeader";
import VIVGrid from "../../../shared/VIV-Grid";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import GridFilter from "../../../shared/GridFilter";
import httpClient from "../../../_util/api";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import "./style.scss";
import useDebounce from "../../../_util/UseDebounce";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../shared/VivitechToast";

const WarehouseList = () => {
  const [griddata, setGridData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [filterString, setFilterString] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsFrom, setRecordsFrom] = useState(0);
  const [recordsTo, setRecordsTo] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialMount = useRef(true);
  const toast_Ref = useRef(null);
  const [gridActionsList, setgridActionsList] = useState([{ label: "Edit", icon: "i-edit", disabled: false }, { label: "Delete", icon: "i-bin", disabled: false }]);
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const [headerItems, setheaderItems] = useState([{
    type: "button",
    icon: "",
    containerClass: "",
    classes: "",
    title: "Create Warehouse",
    disabled: false
  }]);
  useDebounce(() => { initialMount.current ? initialMount.current = false : LoadGridData(); }, [searchString], 1500);


  useEffect(() => {
    LoadGridData();
  }, [currentPage, pageSize]);

  const LoadGridData = async () => {
    dispatch(showLoader());

    let URL = `Warehouse/GetAllWarehouse?pageNumber=${currentPage}&pageSize=${pageSize}&searctTerm=${searchString}`;

    if (filterString !== "") {
      URL = URL + "&" + filterString;
    }
    const resp = await httpClient.get(URL).catch((error) => {
      dispatch(hideLoader());
    });

    if (resp?.data.isSuccess) {
      setTotalRecords(resp?.data.page_TotalRecords);
      setRecordsFrom(resp?.data.page_From);
      setRecordsTo(resp?.data.page_To);
      setGridData(resp?.data.details);
    }
    dispatch(hideLoader());
  };

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let gridActionList = gridActionsList;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if (module) {
      if (module.functions[1].actions[3].value) {
        currentHeaderItems[0].disabled = false;
      }
      else { currentHeaderItems[0].disabled = true; }

      //Edit Permission
      if (module.functions[1].actions[1].value) {
        gridActionList[0].disabled = false;
      }
      else {
        gridActionList[0].disabled = true;
      }

      //Delete Permission
      if (module.functions[3].actions[2].value) {
        gridActionList[1].disabled = false;
      }
      else {
        gridActionList[1].disabled = true;
      }

      setgridActionsList(gridActionList);
      setheaderItems(currentHeaderItems);
    }

  }

  const grid_columns = [
    {
      field: "warehouseName",
      header: "Warehouse",
      islink: true,
      statusfeild: false,
      visible: true,
    },
    // {
    //   field: "model",
    //   header: "Warehouse Code",
    //   islink: false,
    //   statusfeild: false,
    //   visible: true,
    // },
    {
      field: "timeZone",
      header: "Time zone",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "address1",
      header: "Address",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "sizeWarehouse",
      header: "Size",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "noofZones",
      header: "No. of Zones",
      islink: true,
      appendID: true,
      to: "/setup/warehouse/zone/list/",
      statusfeild: false,
      visible: true,
    },
    {
      field: "status",
      header: "Status",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "action",
      header: "Actions",
      islink: false,
      statusfeild: false,
      visible: true,
    },
  ];

  const filterData = [
    {
      label: "Warehouse",
      filter_key: "wareHouseName",
      feild: "input",
      value: "",
    },
    {
      label: "Warehouse Code",
      filter_key: "postalCode",
      feild: "input",
      value: "",
    },
    {
      label: "Time Zone",
      filter_key: "timeZone",
      feild: "dropdown",
      value: "",
      dataforselect: [
        { value: "EST", viewValue: "Eastern Time" },
        { value: "GMT", viewValue: "GMT +5" },
      ],
    },
    {
      label: "Address",
      filter_key: "address",
      feild: "input",
      value: "",
    },
    {
      label: "Size",
      filter_key: "sizeWareHouse",
      feild: "input",
      value: "",
    },
    {
      label: "Status",
      filter_key: "status",
      feild: "multiselect",
      value: "",
      dataforselect: [
        { value: "active", viewValue: "Active" },
        { value: "inactive", viewValue: "In-Active" },
      ],
    }
  ];

  const handlePageHeaderAction = (data) => {
    if (data.actiontype == "button") {
      //setShowFilter(true);
      navigate("/setup/warehouse/form");
    }
  };

  const handlePageChnage = (evnt) => {
    setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
    setpageSize(evnt.rows);
  };

  const handleFilters = (data) => {
    setFilterString(data);
  };

  const deleteWarehouse = async (id) => {
    dispatch(showLoader());
    let resp = await httpClient.delete(`Warehouse/RemoveWarehouse?id=${id}`).catch((error) => {
      //console.log(error); 
      dispatch(hideLoader());
      toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
    });

    if (resp?.status == 200) {
      dispatch(hideLoader());
      toast_Ref.current.showMessage("success", "Warehouse deleted successfully", "", "i-chk-circle");
      LoadGridData();
    }
  }

  const handleGridAction = (e) => {
    if (e?.action.toLowerCase() == "delete") {
      confirmDialog({
        message: 'Are you sure you want to delete this warehouse?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        draggable: false,
        accept: () => { deleteWarehouse(e.recordId) },
        reject: () => { }
      });
    }
    else {
      navigate(`/setup/warehouse/form/${e?.recordId}`);
    }
  }

  useEffect(() => {
    LoadRolebase_Actions()
  }, [])

  return (
    <>
      <ConfirmDialog />
      <VivToast ref={toast_Ref} />
      <GridFilter
        data={filterData}
        visible={showFilter}
        visibleState={setShowFilter}
        onApplyFilter={handleFilters}
      />
      <div className="page_content pb-0" ref={initialMount}>
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Warehouse"}
          HeaderItems={headerItems}
        />

        <div className="mt-4">
          <div className="flex gap-3 align-items-center justify-content-end">
            <span className="p-input-icon-left">
              <i className="i-search" />
              <InputText placeholder="Search" value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }} />
            </span>

            <Button
              label="Filter"
              icon="i-filter"
              className="bg-white justify-content-center clr-b"
              onClick={() => setShowFilter(true)}
              text
              raised
            />
          </div>

          <VIVGrid
            gridActions={gridActionsList}
            paginated={true}
            centerAlign={true}
            gridCol={grid_columns}
            selection={true}
            gridData={griddata}
            TotalRecords={totalRecords}
            recordfrom={recordsFrom}
            recordto={recordsTo}
            onPageChange={handlePageChnage}
            onLineAction={handleGridAction}
            baseURL={"/setup/warehouse/detail/"}
          />
        </div>
      </div>
    </>
  );
};
export default WarehouseList;
