import React, { useState, useEffect, useRef } from "react";
import "./login.scss";
import LoginSection from "../../shared/loginSection";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import OtpInput from "react-otp-input";
import { Password } from "primereact/password";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showLoader, hideLoader } from "../../redux/mainSlice";
import httpClient from "../../_util/api";
import Countdown, { zeroPad } from "react-countdown";
import VivToast from "../../shared/VivitechToast";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [date, setDate] = useState(Date.now() + 120000);
  const countdownRef = useRef(null);
  const dispatch = useDispatch();
  const toast_Ref = useRef(null);
  const [otp, setOtp] = useState("");
  const [OTPMessage, setOTPMessage] = useState("");
  const [OTPError, setOTPError] = useState(false);
  const [OTPSuccess, setOTPSuccess] = useState(false);
  const [resend, setResend] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [email, setEmail] = useState("");
  const [newPass, setNewPass] = useState({ password: "", confirmPassword: "" });

  // const handleEmail = (e) => {
  //   if (e.target.value !== "" && e.target.value) {
  //     console.log(e.target.value);

  //   }
  // };

  useEffect(() => {
    console.log(resend);
  }, [resend]);

  const handleTimer = () => {
    console.log("timer complete");
    setResend(true);
    setDate(Date.now() + 120000);
  };

  const timerRenderer = ({ minutes, seconds }) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)} mins
      </span>
    );
  };

  useEffect(() => {
    if (otp == "") {
      setOTPError(false);
      setOTPSuccess(false);
      setOTPMessage("");
    }
  }, [otp]);

  async function handleOTPInput(event) {
    if (otp) {
      dispatch(showLoader());
      const resp = await httpClient
        .post(`Account/VerifyOTP?email=${email}&otp=${otp}`)
        .then((resp) => {
          if (resp?.data.isSuccess) {
            setOTPMessage("");
            setOTPSuccess(true);
          }
        })
        .catch((error) => {
          dispatch(hideLoader());
          setOTPMessage(error.response.data.message);
          setOTPError(true);
        });
      dispatch(hideLoader());

      // else {
      //   setOTPMessage(resp.data.message);
      //   setOTPError(true);
      // }
    }
  }

  async function handleEmailSubmit(event) {
    if (email) {
      dispatch(showLoader());
      const resp = await httpClient
        .post(`Account/ForgetPassword?email=${email}`)
        .then((resp) => {
          if (resp?.data.isSuccess) {
            toast_Ref.current.showMessage(
              "success",
              resp.data.message,
              "",
              "i-chk-circle"
            );
            true;
            setShowOTP(true);
            countdownRef.current.start();
          }
        })
        .catch((error) => {
          toast_Ref.current.showMessage(
            "error",
            error.response.data.message,
            "",
            "i-notify"
          );
          dispatch(hideLoader());
        });
      dispatch(hideLoader());
    }
  }

  async function handleResend(event) {
    if (email) {
      dispatch(showLoader());
      const resp = await httpClient
        .post(`Account/ForgetPassword?email=${email}`)
        .catch((error) => {
          dispatch(hideLoader());
        });
      dispatch(hideLoader());
      if (resp?.data.isSuccess) {
        toast_Ref.current.showMessage(
          "success",
          resp.data.message,
          "",
          "i-chk-circle"
        );
        setResend(false);
        countdownRef.current.start();
      } else {
        toast_Ref.current.showMessage(
          "error",
          resp.data.message,
          "",
          "i-notify"
        );
      }
    }
  }

  function handleNewPassword(event) {
    const { name, value } = event.target;
    setNewPass((prevFormData) => ({ ...prevFormData, [name]: value }));
  }

  async function handleSubmit(event) {
    dispatch(showLoader());
    event.preventDefault();
    const resp = await httpClient
      .post("Account/ResetPassword", {
        email: email,
        newPassword: newPass.confirmPassword,
      })
      .catch((error) => {
        dispatch(hideLoader());
      });

    dispatch(hideLoader());
    if (resp?.data.isSuccess) {
      toast_Ref.current.showMessage(
        "success",
        resp?.data.message,
        "",
        "i-chk-circle"
      );
      navigate("/login");
    } else {
      toast_Ref.current.showMessage(
        "error",
        resp?.data.message,
        "",
        "i-notify"
      );
    }
  }

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="grid mr-0">
        <div className="hidden md:block md:col-6 pb-0 ">
          <LoginSection />
        </div>
        <div className="col-12 md:col-6 pb-0 flex flex-column align-items-center justify-content-around login_form">
          <div className="col-10 md:col-10 lg:col-8 mx-auto">
            <div className="sm:hidden mt-4 text-center">
              <img src="/images/mobile-logo.svg"></img>
            </div>
            <h1>{!OTPSuccess ? "OTP Verification" : "Set New Password"}</h1>
            {!showOTP ? (
              <p>
                Enter the email associated with your account and we’ll send you
                one-time password to your email
              </p>
            ) : OTPSuccess ? (
              <p>Enter new password</p>
            ) : (
              <p>Enter the OTP sent to {email}</p>
            )}
            {!showOTP && (
              <div className="flex flex-column gap-2 mt-4">
                <label>
                  Email Address <span className="text-danger">*</span>
                </label>
                <InputText
                  placeholder="Enter email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
            )}
            {
              <div className={showOTP && !OTPSuccess ? "mt-4" : "mt-4 hidden"}>
                <OtpInput
                  containerStyle={{ justifyContent: "space-between" }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={
                    <span className="otp-separator ">
                      <img src="/images/otp_line.svg"></img>
                    </span>
                  }
                  renderInput={(props) => (
                    <InputText
                      {...props}
                      className={
                        OTPSuccess
                          ? "otp_input  border-success"
                          : OTPError
                          ? "otp_input  border-danger"
                          : "otp_input"
                      }
                    />
                  )}
                />
                <div className="flex justify-content-between mt-2  ">
                  <div className="flex align-items-center text-danger">
                    {OTPMessage}
                  </div>

                  <p className="otp_time text-secondary ">
                    <Countdown
                      ref={countdownRef}
                      autoStart={false}
                      date={date}
                      renderer={timerRenderer}
                      onComplete={handleTimer}
                    />
                  </p>
                </div>
              </div>
            }
            {OTPSuccess && (
              <form>
                <div className="flex flex-column gap-2 mt-4">
                  <label htmlFor="username">
                    Password <span className="text-danger">*</span>
                  </label>
                  <Password
                    placeholder="Enter password"
                    feedback={false}
                    name="password"
                    value={newPass.password}
                    onChange={handleNewPassword}
                    showIcon={<i className="i-eye-hide"></i>}
                    hideIcon={<i className="i-eye-show"></i>}
                    toggleMask
                  />
                </div>
                <div className="flex flex-column gap-2 mt-4">
                  <label htmlFor="username">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <Password
                    placeholder="Re-enter password"
                    name="confirmPassword"
                    showIcon={<i className="i-eye-hide"></i>}
                    hideIcon={<i className="i-eye-show"></i>}
                    className={
                      newPass.password !== newPass.confirmPassword &&
                      newPass.confirmPassword !== ""
                        ? "p-invalid"
                        : ""
                    }
                    value={newPass.confirmPassword}
                    feedback={false}
                    toggleMask
                    onChange={handleNewPassword}
                  />
                </div>
              </form>
            )}
            {!showOTP && (
              <Button
                type="button"
                onClick={handleEmailSubmit}
                className="mt-4 w-full"
                label="Submit"
              />
            )}
            {showOTP && !resend && !OTPSuccess && (
              <Button
                type="button"
                onClick={handleOTPInput}
                className="mt-4 w-full"
                label="Verify"
              />
            )}
            {showOTP && resend && !OTPSuccess &&(
              <Button
                type="button"
                onClick={handleResend}
                className="mt-4 w-full"
                label="Resend"
              />
            )}
            {OTPSuccess && (
              <Button
                type="button"
                onClick={handleSubmit}
                className="mt-4 w-full"
                disabled={newPass.password == "" || newPass.confirmPassword == "" || (newPass.password !== newPass.confirmPassword)}
                label="Submit"
              />
            )}

            {!OTPSuccess && (
              <div className="for_pass mt-4 text-center">
                <p>
                  Back to{" "}
                  <Link to="/login" className="text-primary">
                    Login
                  </Link>
                </p>
              </div>
            )}
          </div>
          <div className="resp_footer">
            <p>© 2022 Vivitech Solutions, Inc.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
