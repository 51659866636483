import React, { useState, useRef } from "react";
import { Route, useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import Notification from "./Notification";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../redux/mainSlice";
import { showMobileBar } from "../redux/sidebarSlice";

const AppTopBar = () => {
  const [selectedStatus, setSelectedStatus] = useState("All");
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.mainSlice.userdetail);
  const userImage = useSelector((state) => state.mainSlice.userImage);
  //console.log(userImage, "user image");

  const statuses = [
    { name: "All" },
    { name: "Receiving" },
    { name: "Inventory" },
    { name: "Orders" },
    { name: "Outbound" },
    { name: "Setup" },
    { name: "Reports" },
  ];

  const accept = () => {
    localStorage.removeItem("user-token");
    navigate("/login");
  };

  const reject = () => {
    //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };

  const menuItems = [
    {
      label: "Profile",
      icon: "i-user",
      command: () => {
        navigate("/profile");
      },
    },
    {
      label: "Logout",
      icon: "i-signout",
      command: () => {
        confirmDialog({
          message: "Are you sure you want to logout?",
          header: "",
          icon: "pi i-warning",
          acceptLabel: "Confirm",
          rejectLabel: "Cancel",
          className: "notification-dialog",
          tagKey: "loin_confrm",
          draggable: false,
          accept,
          reject,
        });
      },
    },
  ];

  return (
    <div className="viv_header align-content-center flex justify-content-between">
      <ConfirmDialog tagKey="loin_confrm" />
      <Button className="mob-menuBtn hidden-xs hidden-lg hidden-md" icon={'pi i-menu'} onClick={() => {dispatch(showMobileBar())}} />
      <div className="search">
        <Dropdown
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.value)}
          options={statuses}
          optionLabel="name"
          placeholder="All"
        />
        <span className="p-input-icon-right w-19rem">
          <i className="i-search" />
          <InputText placeholder="Search" className="header_searchBar" />
        </span>
      </div>
      <div className="user_info align-content-center align-items-center flex gap-2 justify-content-between">
        <Button className="mob-menuBtn mobiledevice hidden-sm hidden-lg hidden-md" icon={'pi i-menu'} onClick={() => {dispatch(showMobileBar())}} />
        <Notification />
        <img
          style={{ borderRadius: "50%" }}
          width={50}
          height={50}
          src={userImage ? userImage : "/images/profile-pic.png"}
        />
        <div className="user_name flex flex-column">
          {
            userInfo?.roles?.includes('Admin') && <p id="user_role">Admin</p>
          }
          <p id="user">{userInfo?.username}</p>
        </div>
        <div className="align-items-center flex">
          <Menu
            model={menuItems}
            popup
            ref={menuLeft}
            className="popup_userInfo"
          />
          <Button
            icon="i-down-chev"
            className="user_optionMenu"
            rounded
            text
            aria-label="Filter"
            onClick={(event) => menuLeft.current.toggle(event)}
            aria-controls="popup_userInfo"
          />
        </div>
      </div>
    </div>
  );
};

export default AppTopBar;
