import { createSlice } from "@reduxjs/toolkit";

export const mainSlice = createSlice({
  name: "mainSlice",
  initialState: {
    value: "",
    showLoader: false,
    userdetail: {},
    userImage: null,
    userRoles: []
  },
  reducers: {
    showLoader: (state) => {
      state.showLoader = true;
    },
    hideLoader: (state) => {
      state.showLoader = false;
    },
    setUserDetail: (state, action) => {
      state.userdetail = action.payload;
    },
    setUserImage: (state, action) => {
      state.userImage = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRoles = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showLoader,
  hideLoader,
  setUserDetail,
  setUserImage,
  userImage,
  setUserRole
} = mainSlice.actions;

export default mainSlice.reducer;
