import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"
import { ProgressBar } from 'primereact/progressbar';

const UploadFileProgressBar = (props) => {
    const [value, setValue] = useState(0)
    const interval = useRef(null);

    useEffect(() => {
        let _val = value;

        interval.current = setInterval(() => {
            _val += Math.floor(Math.random() * 10) + 1;

            if (_val >= 100) {
                _val = 100;
                clearInterval(interval.current);
            }

            setValue(_val);
        }, 2000);

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        };
    }, []);
    return (
        <div className='progress-bar-container flex align-items-center justify-content-center flex-column' style={{backgroundColor:'white'}}>
            <img src='/images/upload-cloud.svg' />
            <h5>{props.fileName}</h5>
            <ProgressBar showValue={false} value={value} className='fileupload_progress'></ProgressBar>
            <span>{value}%</span>
        </div>
    )
}

export default UploadFileProgressBar