import axios from 'axios';

const httpClient = axios.create({
    // baseURL: "http://18.221.131.31:5000/api/",
    // baseURL: "http://backend-vivitech.default.svc.cluster.local/api",
    // baseURL: "http://192.168.100.17:30000/api/"
       baseURL: "https://api.vivitech.3plheroes.com/api/"
       
    // baseURL: process.env.APP_API_BASE_URL,:
});

httpClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('user-token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
}, function (error) { return Promise.reject(error); });

httpClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 403) {
        localStorage.removeItem('user-token');
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default httpClient;
