import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import LoginSection from "../../shared/loginSection";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Link, useLocation } from "react-router-dom";
import httpClient from "../../_util/api";
import { useDispatch } from "react-redux";
import {
  showLoader,
  hideLoader,
  setUserDetail,
  setUserImage,
  setUserRole
} from "../../redux/mainSlice";
import VivToast from "../../shared/VivitechToast";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const toast_Ref = useRef(null);
  const [checked, setChecked] = useState(false);
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [submitLoader, setSubmitLoader] = useState(false);
  const [Issubmit, setIssubmit] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    dispatch(showLoader());
    event.preventDefault();
    try {
      const resp = await httpClient
        .post("Account/UserLogin", {
        email: formData.username,
        password: formData.password,
      });
      if (resp?.data.isSuccess) {
        dispatch(setUserDetail(resp.data.userData));
        dispatch(setUserImage(resp.data.userData.profileAddress));
        dispatch(setUserRole(resp.data.modules));
        localStorage.setItem("user-token", resp.data.message);
        toast_Ref.current.showMessage(
          "success",
          "Login Successful",
          "",
          "i-chk-circle"
        );
        dispatch(hideLoader());
        setTimeout(() => {
          navigate("/receiving/upload-rma/list");
        }, 1000);
      } else {
        toast_Ref.current.showMessage(
          "error",
          resp?.data.message || "Incorrect Email Address or Password",
          "",
          "i-notify"
        );
        dispatch(hideLoader());
      }
    } catch (error) {
      toast_Ref.current.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "i-notify"
      );
      dispatch(hideLoader());
    }
  };
  

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="grid mr-0">
        <div className="hidden md:block md:col-6 pb-0 ">
          <LoginSection />
        </div>
        <form
          onSubmit={handleSubmit}
          className="col-12 md:col-6 pb-0 flex flex-column align-items-center justify-content-around login_form"
        >
          <div className="col-10 md:col-10 lg:col-8 mx-auto">
            <div className="sm:hidden mt-4 text-center">
              <img src="/images/mobile-logo.svg"></img>
            </div>
            <h1>Welcome!</h1>
            <p>Sign in to continue to Vivitech</p>
            <div className="flex flex-column gap-2 mt-4">
              <label placeholder="Enter email" htmlFor="username">
                Email Address <span className="text-danger">*</span>
              </label>
              <InputText
                placeholder="Enter email"
                name="username"
                value={formData.username}
                aria-describedby="username-help"
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-column gap-2 mt-4">
              <label htmlFor="username">
                Password <span className="text-danger">*</span>
              </label>
              <Password
                placeholder="Enter password"
                name="password"
                value={formData.password}
                feedback={false}
                toggleMask
                showIcon={<i className="i-eye-hide"></i>}
                hideIcon={<i className="i-eye-show"></i>}
                onChange={handleChange}
              />
            </div>
            <div className="flex justify-content-end for_pass mt-4">
              {/* <div className="flex align-items-center">
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
                <label className="ml-2">Remember me</label>
              </div> */}
              <p>
                <Link className="text-primary" to="/forgotPassword">
                  Forgot password ?
                </Link>
              </p>
            </div>
            <Button
              className="mt-4 w-full"
              label="Login"
              loading={submitLoader}
              disabled={formData.username == "" || formData.password == ""}
            />
          </div>
          <div className="resp_footer">
            <p>© 2022 Vivitech Solutions, Inc.</p>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
