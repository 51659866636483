import React from 'react'
import "./shared.scss"

const LoginSection = () => {
  return (
    <>
      <div className='pl-5 pr-5 pt-5 viv_login-bg' >
        <img src='/images/main_logo.svg'></img>
        <p className='mt-5 '>Reverse Logistics & Returns
          Management Made Easy!</p>
      </div>
    </>
  )
}

export default LoginSection