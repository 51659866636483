import React from "react";
import FormInput from "../../../../shared/Form-Input";


const Identifiers = (props) => {

    const handleInputChange = (e, field) => {
        props.onInputChange("identifier", field, e);
    };

    if (props?.viewmode == undefined || props?.viewmode == "form") {
        return (
            <>
                {/* <div className="grid pl-4 pr-4 mt-2">
                    <div className="col-4">
                        <FormInput
                            title={"Master SKU"}
                            required={true}
                            value={props.formData.master_SKU}
                            onchange={(e) => handleInputChange(e, "master_SKU")} />
                    </div>
                    <div className="col-4">
                        <FormInput
                            title={"Manufacturer SKU"}
                            value={props.formData.manafacturer_SKU}
                            onchange={(e) => handleInputChange(e, "manafacturer_SKU")} />
                    </div>
                </div> */}
                <div className="grid pl-4 pr-4 mt-2">
                    {/* <div className="col-4">
                        <FormInput 
                        title={"UPC"} 
                        value={props.formData.upc}
                        onchange={(e) => handleInputChange(e)} />
                    </div> */}
                    <div className="col-4">
                        <FormInput
                            title={"ASIN"}
                            required={true}
                            value={props.formData.asin}
                            onchange={(e) => handleInputChange(e, "asin")} />
                    </div>
                    <div className="col-4">
                        <FormInput
                            title={"FNSKU"}
                            required={true}
                            value={props.formData.fnsku}
                            onchange={(e) => handleInputChange(e, "fnsku")} />
                    </div>
                </div>
                <div className="grid pl-4 pr-4 mt-2">
                    <div className="col-4">
                        <FormInput
                            title={"EAN"}
                            required={true}
                            value={props.formData.ean}
                            onchange={(e) => handleInputChange(e, "ean")} />
                    </div>
                    <div className="col-4">
                        <FormInput
                            title={"GTIN"}
                            required={true}
                            value={props.formData.gtin}
                            onchange={(e) => handleInputChange(e, "gtin")} />
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div className="grid pl-4 pr-4 mt-2">
                    {/* <div className="col-4">
                        <div className="flex flex-column gap-2">
                            <p>Master SKU</p>
                            <p>{props?.formData.master_SKU ? props?.formData.master_SKU : "-"}</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="flex flex-column gap-2">
                            <p>Manufacturer SKU</p>
                            <p>{props?.formData.manafacturer_SKU ? props?.formData.manafacturer_SKU : "-"}</p>
                        </div>
                    </div> */}
                    <div className="col-4">
                        <div className="flex flex-column gap-2">
                            <p>ASIN</p>
                            <p>{props?.formData.asin ? props?.formData.asin : "-"}</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="flex flex-column gap-2">
                            <p>FNSKU</p>
                            <p>{props?.formData.fnsku ? props?.formData.fnsku : "-"}</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="flex flex-column gap-2">
                            <p>EAN</p>
                            <p>{props?.formData.ean ? props?.formData.ean : "-"}</p>
                        </div>
                    </div>
                    <div className="col-4 ">
                        <div className="flex flex-column gap-2">
                            <p>GTIN</p>
                            <p>{props?.formData.gtin ? props?.formData.gtin : "-"}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default Identifiers;