import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../shared/PageHeader";
import { Button } from "primereact/button";
import FormInput from "../../../shared/Form-Input";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import httpClient from "../../../_util/api";
import { useDispatch } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";
import {
  showLoader,
  hideLoader,
  setUserDetail,
} from "../../../redux/mainSlice";
import VivToast from "../../../shared/VivitechToast";

const UserSetupForm = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [roles, setRoles] = useState([]);
  const [formDetail, setFormDetail] = useState([]);
  const [roleDetail, setRoleDetail] = useState([]);
  const { id } = useParams();

  const [formData, setFormData] = useState({
    email: "",
    roles: null,
    username: "",
    isInvitationLinkSent: true,
    status: "Active",
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const toast_Ref = useRef(null);

  useEffect(() => {
    onload();
  }, []);

  const onload = async () => {
    await LoadRoles();
    if (id) {
      LoadFormDetail();
    }
  };

  const handlePageHeaderAction = (data) => {};

  const handleUpdate = async (e) => {
    dispatch(showLoader());
    e.preventDefault();

    let form_submitData = { ...formData };
    form_submitData.roles = formData.roles
      .map((item) => {
        return item.RoleName;
      })
      .join(",");

    let apiResp = await httpClient
      .put("UserManager/EditUser", form_submitData)
      .catch((error) => {
        toast_Ref.current.showMessage(
          "error",
          error.response.data.message,
          "",
          "i-notify"
        );
      });
    //console.log(apiResp.data, "api");
    if (apiResp?.data.isSuccess) {
      toast_Ref.current.showMessage(
        "success",
        apiResp?.data.message,
        "",
        "i-chk-circle"
      );
      setTimeout(() => {
        navigate("/setup/user/list");
      }, 1000);
    } else {
      toast_Ref.current.showMessage(
        "error",
        apiResp?.data.message,
        "",
        "i-notify"
      );
    }
    dispatch(hideLoader());
  };

  const handleRolesChange = (e) => {
    // if (id) {
    //   setRoleDetail([]);
    // }
    //console.log(e);
    setFormData((prevFormData) => ({ ...prevFormData, ["roles"]: e.value }));
  };

  const LoadFormDetail = async () => {
    dispatch(showLoader());
    let newRoles = [];
    let apiResp = await httpClient
      .get(`UserManager/GetUser?userId=${id}`)
      .catch((error) => {
        dispatch(hideLoader());
      });
    if (apiResp?.data.isSuccess) {
      setFormDetail(apiResp?.data.data);
      if (apiResp?.data.data.roles.includes(",")) {
        let rolesArray = apiResp?.data.data.roles.split(",");
        console.log(rolesArray, "roles array");

        for (let index = 0; index < rolesArray.length; index++) {
          newRoles.push({
            RoleName: rolesArray[index].trim(),
          });
        }
      } else {
        let rolesitem = apiResp?.data.data.roles;
        newRoles.push({ RoleName: rolesitem.trim() });
      }

      //console.log(newRoles, "new roless");

      let prev_data = {
        firstName: null,
        lastName: null,
        address: null,
        employeeCode: null,
        contactNo: null,
        profileAddress: null,
        id: Number(id),
        email: apiResp?.data.data.email,
        username: apiResp?.data.data.username,
        roles: [...newRoles],
        isInvitationLinkSent: apiResp?.data.data.isInvitationLinkSent,
        status: apiResp?.data.data.status === "Active" ? "Active" : "Inactive",
      };

      setFormData({ ...prev_data });
      dispatch(hideLoader());
    } else {
      dispatch(hideLoader());
    }
  };

  const handleSubmit = async (e) => {
    dispatch(showLoader());
    e.preventDefault();

    let form_submitData = { ...formData };
    form_submitData.roles = formData.roles
      .map((item) => {
        return item.RoleName;
      })
      .join(",");

    let apiResp = await httpClient
      .post("Account/CreateUser", form_submitData)
      .then((resp) => {
        if (resp?.data.isSuccess) {
          toast_Ref.current.showMessage(
            "success",
            resp?.data.message,
            "",
            "i-chk-circle"
          );
          dispatch(hideLoader());
          window.setTimeout(() => { navigate("/setup/user/list"); }, 1000);
          
        }
      })
      .catch((error) => {
        toast_Ref.current.showMessage(
          "error",
          error?.response.data.message,
          "",
          "i-notify"
        );
        dispatch(hideLoader());
      });

    dispatch(hideLoader());
  };

  const LoadRoles = async () => {
    var newArray = [];
    let apiResp = await httpClient.get("Role/GetRoles").catch((error) => {});
    apiResp?.data?.Roles?.map((role) => {
      const { RoleId, ...rest } = role;
      newArray.push(rest);
    });
    //console.log(newArray);
    setRoles(apiResp?.data.isSuccess ? newArray : []);
  };

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content pb-0">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Create User"}
          showBackButton={true}
          backButtonRoute="/setup/user/list"
          HeaderItems={[]}
        />

        <form onSubmit={handleSubmit}>
          <div className="user_tabs_content content-section bg-white mt-3">
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-4">
                <FormInput
                  title={"User Name"}
                  value={formData.username}
                  onchange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      ["username"]: e,
                    }))
                  }
                  isDisabled={id}
                />
              </div>
              <div className="col-4">
                <FormInput
                  title={"Email"}
                  value={formData.email}
                  inputtype={"email"}
                  onchange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      ["email"]: e,
                    }))
                  }
                  isDisabled={id}
                />
              </div>
            </div>

            <div className="grid pl-4 pr-4">
              <div className="col-4 flex flex-column gap-2">
                <label htmlFor="username">Roles</label>
                <MultiSelect
                  value={formData.roles}
                  onChange={handleRolesChange}
                  options={roles}
                  optionLabel="RoleName"
                  display="chip"
                  placeholder="Select"
                  maxSelectedLabels={3}
                  className="w-full"
                />
              </div>
            </div>
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <label>Send Invitation Link</label>
                  <Checkbox
                    onChange={(e) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        ["isInvitationLinkSent"]: e.checked,
                      }))
                    }
                    checked={formData.isInvitationLinkSent}
                  ></Checkbox>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <label>Is Active</label>
                  <InputSwitch
                    value={formData.status}
                    checked={formData.status == "Active" ? true : false}
                    onChange={(e) =>
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        ["status"]: e.value ? "Active" : "Inactive",
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="page_footer flex gap-3 justify-content-end pt-3">
            <Button
              label={"Cancel"}
              className="btn-secondary"
              onClick={() => navigate("/setup/user/list")}
            />
            {!id && (
              <Button
                label={"Create"}
                disabled={
                  formData.username == "" ||
                  formData.email == "" ||
                  formData.roles == null
                }
              />
            )}
            {id && (
              <Button
                disabled={
                  formData.username == "" ||
                  formData.email == "" ||
                  formData.roles == null
                }
                onClick={handleUpdate}
                label={"Update"}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default UserSetupForm;
