import React, { useRef, useState, useEffect } from 'react';
import './style.scss';
import { FileUpload } from 'primereact/fileupload';

const VIV_FileUpload = (props) => {

    const fileUploadRef = useRef(null);
    const fileInput = useRef(null);

    const headerTemplate = (options) => {

        return (
            <></>
        );
    };

    const emptyTemplate = () => {
        return (
            <>
                <div className="upload_container flex align-items-center flex-column">
                    <img src='/images/upload-cloud.svg' />
                    <h5>Drag & drop files or <span className='text-primary cursor-pointer' onClick={() => fileInput.current.click()}>Browse</span></h5>
                    <h6>{props?.subHeading ? props?.subHeading : 'Supported formats: PDF, JPEG, PNG'}</h6>

                    <input type='file' ref={fileInput}
                        accept={props?.accept ? props.accept : "image/*"}
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileUpload(e.target.files)} />
                </div>
            </>
        );
    };

    const itemTemplate = (data) => {
        fileUploadRef.current.clear();
    };

    const handleFileUpload = async (data) => {
        props?.onUpload(data);
    }

    return (
        <>
            <FileUpload ref={fileUploadRef}
                multiple={false}
                accept={props?.accept ? props.accept : "image/*"}
                headerTemplate={headerTemplate}
                emptyTemplate={emptyTemplate}
                itemTemplate={itemTemplate}
                onSelect={handleFileUpload}
                onUpload={handleFileUpload}
            />
        </>

    )
}

export default VIV_FileUpload;