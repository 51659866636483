import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import FormInput from "../../../../shared/Form-Input";
import "../style.scss";

const InsertNewItemDialog = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [title, setTitle] = useState(null);
    const [fieldTitle, setFieldTitle] = useState("");

    useImperativeHandle(ref, () => ({
        showPopUp(title, fieldTitle) {
            setInputValue("");
            setTitle(title);
            setFieldTitle(fieldTitle);
            setVisible(true);
        },
        hidePopUp(){
            setVisible(false);
        }
    }));

    const handleSubmit = () => {
        if(props?.onItemEnter){
            let obj = {
                type: title.includes("Category") ? "Category" : "Brand",
                data: inputValue
            };
            props?.onItemEnter(obj);
        }
    }

    const footerContent = (
        <div className="mt-3">
            <Button
                label="Cancel"
                onClick={() => setVisible(false)}
                className="p-button-text"
            />
            <Button label="Add" disabled={inputValue == ""} onClick={() => handleSubmit()} autoFocus />
        </div>
    );

    return (
        <Dialog
            header={""}
            visible={visible}
            draggable={false}
            showHeader={false}
            className="viv_dialog insertItem_popup"
            style={{ width: "40vw" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
        >
            <div className="grid mt-5">
                <div className="col-12 text-center">
                    <h4>{title}</h4>
                </div>
                <div className="col-12 align-content-center flex flex-column">
                    <FormInput
                        title={fieldTitle}
                        required={true}
                        value={inputValue}
                        onchange={(e) => {setInputValue(e);}}
                    />

                </div>
            </div>
        </Dialog>
    );
});

export default InsertNewItemDialog;