import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { Button } from 'primereact/button';
import VIV_EditableGrid from "../../../../shared/VIV-Grid/Editable-Grid";
import VIVGrid from "../../../../shared/VIV-Grid";

const PackingInfo = forwardRef((props, ref) => {

    const [validateGrid, setValidateGrid] = useState(false)
    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        setGridData(props?.formData);
    }, []);

    useImperativeHandle(ref, () => ({
        validate() {
            setValidateGrid(true);
        }
    }));

    const grid_columns = [
        { field: 'title', header: 'Title', islink: false, editable: true, required: true, widthClass: "w-12rem", feildtype: 'text', isNumeric: false, visible: true },
        { field: 'uomid', header: 'UOM', islink: false, editable: true, required: true, widthClass: "w-8rem", feildtype: 'dropdown', options: props.dropdowns.uoms, visible: true },
        { field: 'quantity', header: 'Quantity', islink: false, editable: true, required: true, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'width', header: 'Width (in)', islink: false, editable: true, required: true, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'height', header: 'Height (in)', islink: false, editable: true, required: true, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'length', header: 'Length (in)', islink: false, editable: true, required: true, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'weight', header: 'Weight (gm)', islink: false, editable: true, required: true, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'action', header: 'Actions', islink: false, editable: false, required: true, widthClass: "w-4rem", feildtype: 'menu', visible: true }
    ];

    const detail_grid_columns = [
        { field: 'title', header: 'Title', islink: false, editable: false, required: true, widthClass: "w-12rem", feildtype: 'text', isNumeric: false, visible: true },
        { field: 'uomid', header: 'UOM', islink: false, editable: false, required: true, widthClass: "w-8rem", feildtype: 'text', visible: true },
        { field: 'quantity', header: 'Quantity', islink: false, editable: false, required: true, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'width', header: 'Width (in)', islink: false, editable: false, required: false, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'height', header: 'Height (in)', islink: false, editable: false, required: false, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'length', header: 'Length (in)', islink: false, editable: false, required: false, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'weight', header: 'Weight (gm)', islink: false, editable: false, required: false, widthClass: "w-4rem", feildtype: 'text', isNumeric: true, visible: true },
        { field: 'action', header: 'Actions', islink: false, editable: false, required: false, widthClass: "w-4rem", feildtype: 'menu', visible: false }
    ];

    const gridActionsList = [
        {
            label: 'Delete',
            icon: 'i-bin'
        },
    ];

    const handleInsertlevel = () => {
        setValidateGrid(false);
        let currentGridData = [...gridData];
        currentGridData.push({
            title: '',
            uomid: 5,
            quantity: 0,
            width: 0,
            height: 0,
            length: 0,
            weight: 0
        });
        setGridData([...currentGridData]);

        scrollToBottom();
    }

    const handlGridFieldChange = (data, ind) => {
        let currentData = [...gridData];
        currentData[ind] = data;
        setGridData(currentData);
        props.onInputChange("packingInfo", "", currentData);
    }

    const handleGridAction = (e) => {
        if (e?.action.toLowerCase() == "delete") {
            let currentData = gridData;
            currentData.splice(e.recordId, 1);
            setGridData([...currentData]);
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            var objDiv = document.getElementsByClassName("p-datatable-wrapper");
            objDiv[0].scrollTop = objDiv[0].scrollHeight;
        }, 100);
    }

    if (props?.viewmode == undefined || props?.viewmode == "form") {
        return (
            <>
                <div className="pkg-info-wrapper wrapper p-4">
                    <div className="flex w-full justify-content-end mb-2">
                        <Button label={'Add Level'} icon="pi i-plus" onClick={handleInsertlevel} />
                    </div>

                    <VIV_EditableGrid
                        selection={false}
                        validate={validateGrid}
                        gridCol={grid_columns}
                        gridData={gridData}
                        gridActions={gridActionsList}
                        onLineAction={handleGridAction}
                        onFieldChange={handlGridFieldChange} />
                </div>

            </>
        )
    }
    else {
        return (
            <>
                <div className="grid pkg-info-wrapper wrapper p-4">
                    <div className="col-12">
                        <VIVGrid
                            gridActions={[]}
                            paginated={false}
                            gridCol={detail_grid_columns}
                            selection={false}
                            gridData={props?.formData}
                            centerAlign={true}
                        />
                    </div>
                </div>

            </>
        )
    }

})

export default PackingInfo;