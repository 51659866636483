import React from 'react'
import { Dialog } from 'primereact/dialog';
import "../style.scss";
import VIVGrid from '../../../../shared/VIV-Grid';
import { Button } from 'primereact/button';

const LocationDialog = ({ visible, onHide, gridData }) => {

  const grid_columns = [
    { header: "Location", visible: true, field: "locationName" },
    { header: "Pallet#", visible: true, field: "stockNo" },
    { header: "Total Item Qty", visible: true, field: "totalItemQuantity" },
    { header: "Condition", visible: true, field: "condition" },
  ];

  const footerContent = (
    <div className="mt-3">
      <Button
        label="Cancel"
        onClick={() => onHide()}
        className="p-button-text"
      />
    </div>
  );


  return (
    <div className='inventory-dialog'>
      <Dialog header=""
        showHeader={false}
        draggable={false}
        footer={footerContent}
        style={{ width: "72vw" }}
        className={"viv_dialog inventoryLoc_popup"}
        visible={visible} onHide={onHide}>
        <div className='flex justify-content-between align-items-center'>
          <div className='dialogLabel'>
            <h5>Locations</h5>
            <h6>UPC: </h6>
          </div>
          <div>
            <Button icon="pi pi-times" 
            className='btn-secondary' 
            onClick={() => { onHide() }}
            style={{ width: "2rem", height: "2rem" }} rounded aria-label="Cancel" />
          </div>
        </div>

        <VIVGrid
          gridCol={grid_columns}
          gridData={gridData}
          centerAlign={true}
        />

      </Dialog>
    </div>
  );
};


export default LocationDialog
