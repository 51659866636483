import React, { useState, useEffect, useRef } from 'react';
import '../style.scss';
import Location from "../../../../assets/images/locationlogofinal.svg";
import FormInput from '../../../../shared/Form-Input';
import FormInputGroup from '../../../../shared/Form-InputGroup';
import { Button } from 'primereact/button';
import VIVGrid from '../../../../shared/VIV-Grid';
import { useDispatch } from 'react-redux';
import httpClient from '../../../../_util/api';
import { showLoader, hideLoader } from '../../../../redux/mainSlice';
import VivToast from '../../../../shared/VivitechToast';
import PageHeader from '../../../../shared/PageHeader';
import { Message } from 'primereact/message';
import VivitechConfirmDialog from '../../../../shared/VIV-ConfirmDialog';

const PalletDetail = (props) => {
    const toast_Ref = useRef(null);
    const [scannedItems, setScannedItems] = useState([]);
    const [recallLocations, setRecallLocations] = useState([]);
    const [scannedLocation, setScannedLocation] = useState("");
    const [quantity, setQuantity] = useState(null);
    const [showInfoMessage, setShowInfoMessage] = useState(false);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        setUpItemMasterId: 0,
        url: "",
        product: "",
        upc: "",
        modelNo: "",
        rA_Number: "",
        quantity: undefined,
        stockNo: null
    });

    const handleScannedItem = () => {
        let currentItems = scannedItems;

        //check if item already scanned
        let itemIndex = currentItems.findIndex(i => i.setUpItemMasterId == formData.setUpItemMasterId);
        if(itemIndex == -1)
        {
            currentItems.push({
                product: formData.product,
                setUpItemMasterId: formData.setUpItemMasterId,
                url: formData.url,
                quantity: quantity,
                rA_Number: formData.rA_Number,
                modelNo: formData.modelNo,
                stockNo: null,
                isLoose: false
            });
        }
        else{
            currentItems[itemIndex].quantity = quantity;
        }
        setScannedItems([...currentItems]);

        setQuantity(null);
        setShowInfoMessage(false);
        setFormData({
            setUpItemMasterId: 0,
            url: "",
            product: "",
            upc: "",
            modelNo: "",
            rA_Number: "",
            quantity: undefined,
            stockNo: null
        });
    };

    // useEffect(() => {
    // }, [scannedItems]);

    const grid_columns = [
        { field: "product", header: "Product", isProduct: true, visible: true },
        { field: "modelNo", header: "Model #", visible: true },
        { field: "quantity", header: "Qty", visible: true },
    ];


    const handleInputChange = (data, field) => {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: data }));
    };

    const handleScanUPC = async (e) => {
        if(formData.upc !== ""){
            dispatch(showLoader());
            let url;
    
            if (props?.viewmode == "CreateCarton") {
                url = `Stock/GetItemsForMainfestByUPC?upc_st_No=${formData.upc}`;
            }
            else {
                url = `Stock/GetItemsForMainfestByUPC?upc_st_No=7459054624`;
            }
    
            let resp = await httpClient.get(url).catch((error) => { dispatch(hideLoader()); });
    
    
            if (resp?.status == 200) {
                dispatch(hideLoader());
                if (props?.viewmode == "CreateCarton") {
                    setFormData(resp.data[0]);
                    setShowInfoMessage(true);
                }
                else {
                    setShowInfoMessage(false);
                }
            }
        }
    }

    const handleCompleteScan = async () => {
        dispatch(showLoader());

        let post_data = {
            locationID: null,
            stockItems: scannedItems
        };

        let resp = await httpClient.post('Stock/CreateStock', post_data).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
        });

        if (resp?.status == 200) {
            dispatch(hideLoader());
            console.log(resp);
            //props?.changeMode(resp?.data.message);
        }
    }


    return (
        <>
            {/* <VivitechConfirmDialog ref={confirmDialog_Ref} 
            message={['Are you sure you want to cancel?','All items scanned to the package will be discarded.']}
            icon={'pi i-warning'}
            acceptLabel={'Confirm'} 
            rejectLabel={'Cancel'} accept={() => { props?.changeMode(""); }} /> */}

            <VivToast ref={toast_Ref} />


            <div className="page_content pb-0">
                <PageHeader
                    onPageActoin={() => { }}
                    PageTitle={props?.viewmode == "CreateCarton" ? "Create New Carton" : "Pallet Manifest# 13254"}
                    HeaderItems={[]}
                />
                <div className='scanRMA_content mt-4'>
                    <div className='grid mt-2'>
                        <div className='col-6 pt-0'>
                            <div className='grid'>
                                <div className='col-4'>
                                    <div className="flex align-items-center justify-content-center pl-3 pr-3 gap-3 h-5rem bg-white border-round" style={{ border: "1px solid #D6e4f7" }}>
                                        <div className='scan'><img className="image" src={Location} /></div>
                                        <div>
                                            <p className='scanfont'> Location </p>
                                            <p className='scanfont'>Staging Area</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 scandoc'>
                                <div className='grid'>
                                    <div className='col-7 scanfont'>

                                        <FormInputGroup inputtype={"text"} icon={"i-scanner"}
                                            title={"Scan item UPC"}
                                            placeholder={"Scan Item UPC"}
                                            required={true}
                                            value={formData.upc}
                                            onchange={(e) => handleInputChange(e, "upc")}
                                            onBlur={(e) => handleScanUPC(e)} />

                                    </div>
                                    <div className='col-4 scanfont'>
                                        <FormInput
                                            title="Quantity"
                                            placeholder="0"
                                            inputtype="number"
                                            required={true}
                                            onchange={(e) => { setQuantity(e); }}
                                            value={quantity}

                                        />
                                    </div>
                                    <div className='align-items-center col-1 flex justify-content-center mt-4 p-0'>
                                        <Button onClick={handleScannedItem}
                                            icon={"i-arrow-right"}
                                            disabled={formData.upc == "" || quantity == undefined || quantity === 0}
                                            style={{ height: "38px", width: "38px" }} />
                                    </div>
                                </div>

                            </div>

                            {showInfoMessage && <Message severity="info" text={`The Pre-set pack qty for the scanned UPC is ${formData.quantity ? formData.quantity : 0}`} className='mt-4 w-10' icon={'i-info'} />}
                        </div>

                        <div className='col-6 scant '>

                            <p className="scanfont2" style={{ fontWeight: "400px", color: "#293752" }}>Scanned Items</p>
                            <div className='scanrmagrid' >
                                <VIVGrid
                                    gridCol={grid_columns}
                                    gridData={scannedItems}
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="page_footer flex justify-content-between gap-3 pt-3">
                    <div>
                        <Button style={{ backgroundColor: "white", color: "#0097FE" }} disabled={scannedItems?.length == 0} label="Exit" onClick={() => {}} />
                    </div>
                    <div>
                        <Button style={{ marginLeft: "10px" }} label="Generate" disabled={scannedItems?.length == 0} onClick={handleCompleteScan} />
                    </div>


                </div>
            </div>
        </>
    )
}

export default PalletDetail