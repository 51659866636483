import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../../shared/PageHeader";
import { Button } from "primereact/button";
import FormInput from "../../../../shared/Form-Input";
import VIV_Dropdown from "../../../../shared/Form-Select";
import { InputTextarea } from "primereact/inputtextarea";
import { useAsyncError, useNavigate, useParams } from "react-router-dom";
import httpClient from "../../../../_util/api";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/mainSlice";
import VivToast from "../../../../shared/VivitechToast";
import { InputSwitch } from "primereact/inputswitch";


const ZoneSetupForm = () => {
  const [multiSelect, setMultiSelect] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isEmptyPermissions, setIsEmptyPermissions] = useState(true)
  const [zoneData, setZoneData] = useState(
    {
      zoneName: "",
      zoneCode: "",
      zoneTypeID: "",
      description: "",
      warehouseId: 0,
      status: "Active",
    }
  )


  let navigate = useNavigate();
  const dispatch = useDispatch()
  const toast_Ref = useRef(null);
  const handlePageHeaderAction = (data) => { };

  const handleTabChange = (data) => { };
  const { warehouseId } = useParams();
  const { id } = useParams()


  const handleDDChange = (field, data) => {
    setZoneData({ ...zoneData, [field]: data });
  }

  const isFieldEmpty = () => {
    if (!zoneData.zoneName || !zoneData.zoneCode || !zoneData.zoneTypeID || !zoneData.description || !zoneData.status) {
      setIsEmptyPermissions(true)
    }
    else {
      setIsEmptyPermissions(false)
    }
  }



  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(showLoader())
    if (id) {
      const res = await httpClient.put(`Zone/UpdateZone`, {
        id: id,
        warehouseId: warehouseId,
        zoneName: zoneData.zoneName,
        zoneCode: zoneData.zoneCode,
        zoneTypeID: zoneData.zoneTypeID,
        description: zoneData.description,
        status: zoneData.status,
      })
      .catch((error) => {
        toast_Ref.current.showMessage(
          "error",
          error?.response.data.message,
          "",
          "i-notify"
        );
        //console.log("Error: ", error)
        dispatch(hideLoader());
      });
      if (res?.status == 200) {
        toast_Ref.current.showMessage(
          "success",
          res?.data.message,
          "",
          "i-chk-circle"
        )
        setTimeout(() => {
          navigate(`/setup/warehouse/zone/list/${warehouseId}`)
        }, 1000);
      }
      dispatch(hideLoader());
    }
    else {
      const res = await httpClient.post('Zone/CreateZone', {
        warehouseId: warehouseId,
        noofLocations: 3,
        zoneName: zoneData.zoneName,
        zoneCode: zoneData.zoneCode,
        zoneTypeID: zoneData.zoneTypeID,
        description: zoneData.description,
        status: zoneData.status,
      })
        .catch((error) => {
          toast_Ref.current.showMessage(
            "error",
            error?.response.data.message,
            "",
            "i-notify"
          );
          dispatch(hideLoader());
        });
      if (res?.status == 200) {
        toast_Ref.current.showMessage(
          "success",
          res?.data.message,
          "",
          "i-chk-circle"
        );

        setTimeout(() => {
          navigate(`/setup/warehouse/zone/list/${warehouseId}`)
        }, 1000);
      }
      dispatch(hideLoader());

    }
  }

  const loadZoneDetail = async () => {
    dispatch(showLoader());
    let apiResp = await httpClient.get(`Zone/GetZoneByID?id=${id}`).catch((error) => { dispatch(hideLoader()); });

    if (apiResp?.status == 200) {
      setZoneData(apiResp?.data.data);
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    if (id) {
      loadZoneDetail();
    }
  }, []);

  useEffect(() => {
    isFieldEmpty();
  }, [zoneData.zoneName, zoneData.zoneCode, zoneData.zoneTypeID, zoneData.status, zoneData.description])

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={id ? "Update Zone" : "Create Zone"}
          showBackButton={true}
          backButtonRoute={`/setup/warehouse/zone/list/${warehouseId}`}
          HeaderItems={[]}
        />

        <div className="user_tabs_content bg-white mt-3">
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4">
              <FormInput
                title={"Zone Name"}
                value={zoneData.zoneName}
                onchange={(e) => setZoneData({ ...zoneData, zoneName: e })}
              />


            </div>
            <div className="col-4">
              <FormInput
                title={"Zone Code"}
                value={zoneData.zoneCode}
                onchange={(e) => setZoneData({ ...zoneData, zoneCode: e })}
              />
            </div>
          </div>
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4">
              <VIV_Dropdown
                title={"Default Type"}
                selectedOption={zoneData.zoneTypeID}
                options={[
                  { name: "Staging Area", id: 1 },
                  { name: "Unmanifest Area", id: 2 },
                  { name: "Recall Area", id: 3 },
                ]}
                onSelect={(e) => handleDDChange("zoneTypeID", e)}
              />
            </div>
            <div className="col-4">
              <div className="flex flex-column gap-2">
                <label>Is Active</label>
                <InputSwitch
                  value={zoneData.status}
                  checked={zoneData.status == "Active" ? true : false}
                  onChange={(e) =>
                    setZoneData((prevZoneData) => ({
                      ...prevZoneData,
                      ["status"]: e.value ? "Active" : "Inactive",
                    }))
                  }
                />
              </div>
            </div>
            <div className="col-6">
              <div className="flex flex-column gap-2">
                <label>Description</label>
                <InputTextarea id="username" value={zoneData.description} onChange={(e) => setZoneData({ ...zoneData, description: e.target.value })} rows={4} cols={30} />
              </div>
            </div>
          </div>
        </div>
        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button
            label={"Cancel"}
            className="btn-secondary"
            onClick={() => navigate(`/setup/warehouse/zone/list/${warehouseId}`)}
          />
          <Button
            disabled={isEmptyPermissions ? true : false}
            label={id ? "Update" : "Create"}
            onClick={submitHandler}
          />
        </div>
      </div>
    </>
  );
};

export default ZoneSetupForm;
