import React, { useState, useEffect } from "react";
import PageHeader from "../../../shared/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ProgressBar } from 'primereact/progressbar';
import { Badge } from "primereact/badge";
import { hideLoader, showLoader } from '../../../redux/mainSlice';
import httpClient from "../../../_util/api";
import VIVGrid from "../../../shared/VIV-Grid";
import "./style.scss";

const ReceivedRMADetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [rma_detail, setRMADetail] = useState();
    const [grid_data, setGridData] = useState([]);

    const grid_columns = [
        {
            field: "lineNo",
            header: "Line#",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "upc",
            header: "UPC",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "modelNo",
            header: "Model#",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "description",
            header: "Description",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "quantity",
            header: "Qty",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "cost",
            header: "Cost",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "totalCost",
            header: "Total Cost",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "re_pack",
            header: "In Re-Packing",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
    ];

    const fetchRMADetail = async () => {
        dispatch(showLoader());
        let resp = await httpClient.get(`RMA/GetReceivedRMAById?id=${id}`).catch((error) => { 
            dispatch(hideLoader());
        });

        if (resp?.status == 200) {
            setRMADetail(resp?.data);
            let new_arr = resp?.data.items.map((prevData, ind) => ({ ...prevData, lineNo: ind + 1  }));
            setGridData(new_arr);
            dispatch(hideLoader());
        }
    }

    useEffect(() => {
        fetchRMADetail();
    }, []);

    return (
        <>
            <div className="page_content pb-0">
                <PageHeader
                    onPageActoin={() => { }}
                    PageTitle={"RMA# 80134938"}
                    showBackButton={true}
                    backButtonRoute="/receiving/received-rma/list"
                    HeaderItems={[]}
                />

                <div className="content-section border-none bg-transparent mt-3">

                    <div className="viv-card bg-white mt-2 flex justify-content-around pt-3 pb-5">
                        <div>
                            <p className="detail-lable">RMA#</p>
                            <Link to={''}>{rma_detail?.rmaNumber}</Link>
                        </div>
                        <div>
                            <p className="detail-lable">Date</p>
                            <p className="detail-text">{rma_detail?.date}</p>
                        </div>
                        <div>
                            <p className="detail-lable">Ship From</p>
                            <p className="detail-text">{rma_detail?.shipFromName}</p>
                        </div>
                        <div>
                            <p className="detail-lable">Repack Progress</p>
                            <div className="flex align-items-center gap-2">
                                <ProgressBar value={rma_detail?.repackProgress} showValue={false} style={{ height: "0.4rem", width: "85%" }} />
                                <p className="detail-text">{rma_detail?.repackProgress}%</p>
                            </div>

                        </div>
                        <div>
                            <p className="detail-lable">Status</p>
                            <p className="detail-text"><Badge severity={'info'}></Badge> {rma_detail?.status}</p>
                        </div>
                    </div>

                    <div className="viv-card bg-white mt-2 pl-4 pr-4">
                        <VIVGrid
                            styleClass={"head-white"}
                            gridActions={[]}
                            paginated={false}
                            centerAlign={true}
                            gridCol={grid_columns}
                            selection={false}
                            gridData={grid_data}
                            onLineAction={() => { }}
                            onPageChange={() => { }}
                            baseURL={''}
                        />
                    </div>
                </div>

                <div className="page_footer flex gap-3 justify-content-end">
                    <Button
                        label={"Close"}
                        type="button"
                        className="btn-secondary"
                        onClick={() => navigate("/receiving/received-rma/list")}
                    />
                </div>
            </div>
        </>
    )
}

export default ReceivedRMADetail;