import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Chips } from 'primereact/chips';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";

const FormInputGroup = (props) => {
    
    const handleChange = (e) => {
        props.onchange(e);
    };

    const handleBlur = (e) => {
        if(props?.onBlur){
            props?.onBlur(e);
        }
    }

    const handleEnter = () => {
        if(props?.onEnterKeyPress){
            props?.onEnterKeyPress();
        }
    }

    const handleKeyUp = (e) => {
        if(e?.key == "Enter"){
            handleEnter();
        }
    }

    const handleIconButtonClick = () => {
        if(props?.onIconTap){
            props?.onIconTap();
        }
    }

    return (
        <div className={`flex flex-column gap-2 ${props?.containerClass}`}>
            {props.title &&
                <label>{props.title} {props?.required && <span className="text-danger">*</span>} </label>
            }
            {props.inputtype == "number" && <div className="p-inputgroup flex-1">
                <InputNumber
                    useGrouping={false}
                    min={0}
                    mode={props.mode ? props.mode : "decimal"}
                    currency={props.currency ? props.currency : "USD"}
                    maxLength={props.maxLength ? props.maxLength : null}
                    className={`w-full ` + props.styleclass}
                    placeholder={props.placeholder ? props.placeholder : "Type here"}
                    disabled={props.isDisabled ? true : false}
                    onChange={(e) => handleChange(e.value)}
                    value={props.value && props.value} />
                <Button icon={`pi ${props.icon}`} className="p-button-primary" outlined  />
            </div>
            }

            {props.inputtype == "phone" &&
                <InputMask
                    className={`w-full ` + props.styleclass}
                    mask="(999) 999-9999"
                    placeholder={props.placeholder ? props.placeholder : '(999) 999-9999'}
                    disabled={props.isDisabled ? true : false}
                    onChange={(e) => handleChange(e.target.value)}
                    value={props.value && props.value} />

            }

            {props.inputtype == "chip" &&
                <Chips
                    value={props.value}
                    placeholder={props.placeholder ? props.placeholder : "Type here"}
                    onChange={(e) => handleChipsChange(e.value)} />
            }

            {props.inputtype == "textarea" &&
                <InputTextarea
                    value={props.value}
                    placeholder={props.placeholder ? props.placeholder : "Type here"}
                    onChange={(e) => handleChange(e.target.value)} rows={1} />
            }

            {(!props.inputtype || props.inputtype == "email" || props.inputtype == "text") &&
                <div className="p-inputgroup flex-1">
                    <InputText
                        className={`w-full ` + props.styleclass}
                        placeholder={props.placeholder ? props.placeholder : "Type here"}
                        type={props.inputtype ? props.inputtype : "text"}
                        disabled={props.isDisabled ? true : false}
                        onChange={(e) => handleChange(e.target.value)}
                        onBlur={(e) => handleBlur(e.target.value)}
                        onKeyUp={handleKeyUp}
                        value={props.value && props.value}
                    />
                    <Button type="button" icon={`pi ${props.icon}`} 
                    className="p-button-primary" 
                    onClick={handleIconButtonClick}
                    outlined />
                </div>
            }

        </div>
    );
};

export default FormInputGroup;
