import React, { useRef, useState, useEffect } from "react";
import PageHeader from "../../../shared/PageHeader";
import GridTabs from "../../../shared/GridTabs";
import VIVGrid from "../../../shared/VIV-Grid";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import GridFilter from "../../../shared/GridFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import useDebounce from "../../../_util/UseDebounce";
import { confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../shared/VivitechToast";

const PackList = () => {
    const initialMount = useRef(true);
    const toast_Ref = useRef(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [grid_data, setGridData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordsFrom, setRecordsFrom] = useState(0);
    const [recordsTo, setRecordsTo] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [filterString, setFilterString] = useState("");
    const [gridActionsList, setgridActionsList] = useState([{ label: "Edit", icon: "i-edit", disabled: false }, { label: "Delete", icon: "i-bin", disabled: false }]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useDebounce(() => { initialMount.current ? initialMount.current = false : LoadGridData(); }, [searchString], 1500);

    const gridTabs = [
        { label: "All" },
        { label: "Carton" },
        { label: "Pallet" },
    ];
   
    const grid_columns = [
        {
            field: "locationName",
            header: "Location",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "upc",
            header: "UPC",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "modelNo",
            header: "Model No",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "progress",
            header: "UOM",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "quantity",
            header: "Qty",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "packageType",
            header: "Package Type",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
       
    ];

    const filterData = [
        {
            label: "Package Type",
            filter_key: "PackageType",
            feild: "input",
            value: "",
        },
        {
            label: "Location",
            filter_key: "location",
            feild: "input",
            value: "",
        },
        {
            label: "Upc",
            filter_key: "upc",
            feild: "input",
            value: "",
        },
        {
            label: "Model No",
            filter_key: "modelno",
            feild: "input",
            value: "",
        },
     
    ];

    const LoadGridData = async () => {
        dispatch(showLoader());

        let URL = `RMA/GetPackListGrid?pageNumber=${currentPage}&pageSize=${pageSize}&search=${searchString}&PackageType=${gridTabs[activeTabIndex].label}`;

        if (filterString !== "") {
            URL = URL + "&" + filterString;
        }
        const resp = await httpClient.get(URL).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
        });

   
        if (resp?.data.isSuccess) {
            
            setTotalRecords(resp?.data.page_TotalRecords);
            setRecordsFrom(resp?.data.page_From);
            setRecordsTo(resp?.data.page_To);
            setGridData(resp?.data.details);

        } else {
            setTotalRecords(0);
        setRecordsFrom(0);
        setRecordsTo(0);
            setGridData([]);
        }
        dispatch(hideLoader());
    };

    useEffect(() => {
        LoadGridData();
    }, [currentPage, pageSize, filterString, activeTabIndex]);

 

    const handleTabChange = (data) => {
        setCurrentPage(1);
        setActiveTabIndex(data);
       
    };

    const handlePageChnage = (evnt) => {
        setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
        setpageSize(evnt.rows);
    };

    const handleFilters = (data) => {
        setFilterString(data);
    };

    const deleteRMA = async (id) => {
        dispatch(showLoader());
        let resp = await httpClient.delete(`RMA/DeleteRMA?Id=${id}`).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
        });

        if (resp?.status == 200) {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("success", resp?.data.message, "", "i-chk-circle");
            LoadGridData();
        }
    }

    const handleGridAction = (e) => {
        if (e?.action.toLowerCase() == "delete") {
            confirmDialog({
                message: 'Are you sure you want to delete this RMA?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                draggable: false,
                accept: () => { deleteRMA(e.recordId) },
                reject: () => { }
            });
        }
        else {
            navigate(`/receiving/upload-rma/form/${e?.recordId}`);
        }
    }

    return (
        <>
           
            <VivToast ref={toast_Ref} />
            <GridFilter data={filterData} visible={showFilter} visibleState={setShowFilter} onApplyFilter={handleFilters} />
            <div className="page_content pb-0" ref={initialMount}>


                <PageHeader
                    onPageActoin={[]}
                    PageTitle={"Pack List"}
                    HeaderItems={[]}
                />

                <div className="mt-5">
                    <div className="grid">
                        <div className="col-8">
                            <GridTabs
                                tabs={gridTabs}
                                onTabChange={handleTabChange}
                                activeIndex={activeTabIndex}
                            />
                        </div>
                        <div className="col-4 align-items-center flex gap-3 justify-content-between">
                            <span className="p-input-icon-left w-full">
                                <i className="i-search" />
                                <InputText placeholder="Search" value={searchString}
                                    onChange={(e) => {
                                        setSearchString(e.target.value);
                                    }} />
                            </span>

                            <Button
                                label="Filter"
                                icon="i-filter"
                                onClick={() => setShowFilter(true)}
                                className="bg-white justify-content-center clr-b"
                                text
                                raised
                            />
                        </div>
                    </div>
                    <VIVGrid
                        gridActions={gridActionsList}
                        paginated={true}
                        gridCol={grid_columns}
                        selection={true}
                        gridData={grid_data}
                        TotalRecords={totalRecords}
                        recordfrom={recordsFrom}
                        recordto={recordsTo}
                        onLineAction={handleGridAction}
                        onPageChange={handlePageChnage}
                        baseURL={"/receiving/pack-list"}
                    />
                </div>
            </div>
        </>
    );
};

export default PackList;
