import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { SplitButton } from "primereact/splitbutton";
import FormInput from "../../Form-Input";
import './style.scss';

const VIV_EditableGrid = (props) => {

    const [selectAll, setSelectAll] = useState(false);
    const [selections, setSelections] = useState([]);

    const handleFieldChange = (rowData, ind) => {
        props.onFieldChange(rowData, ind);
    }

    const actionMenuItems = (id) => {
        let arrGridActn = [];
        if (props?.gridActions) {
            props?.gridActions.forEach((item) => {
                arrGridActn.push({
                    label: item.label,
                    icon: item.icon,
                    disabled: item?.disabled,
                    command: (e) => onLineAction({ action: item.label, recordId: id }),
                });
            });
        }
        return arrGridActn;
    };

    const onLineAction = (e) => {
        props.onLineAction(e);
    };

    const cellTemplate = (rowData, col, ind) => {
        if (col.feildtype == "text") {
            return (
                <FormInput
                    value={rowData[col.field]}
                    styleclass={props?.validate && col?.required && (rowData[col.field] === "" || rowData[col.field] === 0 || rowData[col.field] == undefined) ? "p-invalid" : ""}
                    inputtype={col?.isNumeric ? "number" : "text"}
                    onchange={(e) => { rowData[col.field] = e; handleFieldChange(rowData, ind) }}
                />
            )
        }
        else if (col.feildtype == "dropdown") {
            return (
                <Dropdown
                    value={rowData[col.field]}
                    options={col.options}
                    optionLabel="unitofMeasure"
                    optionValue="id"
                    placeholder="Select"
                    className={`w-full grid_DD ${props?.validate && (col?.required && rowData[col.field] == 0) ? "p-invalid" : ""}`}
                    onChange={(e) => { rowData[col.field] = e.value; handleFieldChange(rowData, ind); }}
                />
            );
        }
        else if (col.feildtype == "menu") {
            return (
                <SplitButton
                    label="Save"
                    menuClassName="grid_actionsList"
                    dropdownIcon="i-ellipse"
                    model={actionMenuItems(ind)}
                />
            );
        }
        else if (col.feildtype == "label"){
            return(
                <>{ind + 1}</>
            )
        }
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);

        if (e.target.checked) {
            props.gridData.forEach((item) => {
                let new_selections = [];
                new_selections.push(item.id);

                setSelections([...new_selections]);
            });
        }
        else {
            setSelections([]);
        }

    }

    const handleRowSelection = (e, recordId) => {
        let currentSelection = selections;

        if (e.target.checked) {
            currentSelection.push(recordId);
        }
        else {
            currentSelection.splice(currentSelection.indexOf(recordId), 1);
        }
        setSelections([...currentSelection]);
    }

    const checkIsSelected = (recordId) => {
        let findRecord = selections.find(s => s == recordId);
        return findRecord ? true : false;
    }

    return (
        <>
            <div className="p-datatable p-component p-datatable-responsive-scroll viv_editablegrid">
                <div className="p-datatable-wrapper">
                    <table className="p-datatable-table p-datatable-scrollable-table">
                        <thead className="p-datatable-thead">
                            <tr>
                                {props.selection && <th className="w-3rem text-center"> <Checkbox value={selectAll} onChange={handleSelectAll} checked={selectAll} /> </th>}
                                {props.gridCol.map((col, ind) => {
                                    return (
                                        <th className={col?.widthClass ? col.widthClass : null} key={uuidv4()}>
                                            <div className={ind == 0 ? "p-column-header-content" : "p-column-header-content"}>
                                                <span className="p-column-title">
                                                    {col.header}
                                                </span>
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="p-datatable-tbody">
                            {props.gridData.map((row, rowInd) => {
                                return (
                                    <tr>
                                        {props.selection && <td className="pb-2 pl-2 pr-2 pt-2 text-center"> <Checkbox onChange={(e) => { handleRowSelection(e, row?.id) }} checked={checkIsSelected(row?.id)} /> </td>}
                                        {
                                            props.gridCol.map((col, ind) => {
                                                return (
                                                    <td className="pb-2 pl-2 pr-2 pt-2">
                                                        {cellTemplate(row, col, rowInd)}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default VIV_EditableGrid;