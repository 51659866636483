import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../shared/PageHeader";
import GridTabs from "../../../shared/GridTabs";
import { Button } from "primereact/button";
import ProductSetup from "./components/product_setup";
import Pricing from "./components/pricing";
import Identifiers from "./components/identifiers";
import PackingInfo from "./components/packing-info";
import httpClient from "../../../_util/api";
import VivToast from "../../../shared/VivitechToast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader, hideLoader } from "../../../redux/mainSlice";

import "./style.scss";

const ItemMasrterForm = () => {
  const packagingInfo_Ref = useRef();
  const toast_Ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState(0);
  const { id } = useParams();

  const [form_DDs, setFormDDs] = useState({
    brands: [],
    categories: [],
    companies: [],
    conditions: [],
    uoms: []
  });

  const [formData, setFormData] = useState({
    productSetUp: {
      "image": null,
      "upc": "",
      "modelNo": "",
      "itemRecall": "no",
      "status": "Active",
      "hs_code": undefined,
      "description": "",
      "tags": null,
      "createdBy": "",
      "createdOn": new Date(),
      "brandID": 0,
      "categoryID": 0,
      "conditionID": 1,
      "companyID": null,
      "uomid": 0
    },
    pricing: {
      "walmartPrice": null,
      "targetPrice": null,
      "amazonPrice": null,
      "averageCost": null,
    },
    identifier: {
      "master_SKU": "",
      "fnsku": "",
      "gtin": "",
      "manafacturer_SKU": "",
      "asin": "",
      "ean": ""
    },
    packingInfo: [
      {
        "id": 1,
        "title": "",
        "uomid": 5,
        "quantity": 0,
        "width": 0,
        "height": 0,
        "length": 0,
        "weight": 0
      }
    ]
  });

  const gridTabs = [
    { label: "Product Setup" },
    { label: "Pricing" },
    { label: "Identifiers" },
    { label: "Packing Info" },
  ];

  const onLoad = async () => {
    let resp = await httpClient.get(`ItemMaster/GetAllProductSetupDropDownsData`).catch((error) => { console.log(error); });
    if (resp?.status == 200) {
      setFormDDs(resp.data.data);
    }
  }

  const fetchItemMasterDetail = async () => {
    dispatch(showLoader());
    let resp = await httpClient.get(`ItemMaster/GetItemMasterDetailsById?Id=${id}`).catch((error) => { dispatch(hideLoader()); });

    if (resp?.status == 200) {
      resp.data.productSetUp.createdOn = new Date(resp?.data?.productSetUp.createdOn);
      setFormData(resp?.data);
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    onLoad();
    if (id) {
      fetchItemMasterDetail();
    }
  }, []);

  const handlePageHeaderAction = (data) => { };

  const handleTabChange = (data) => {
    setactiveTab(data);
  };

  const handleNext = () => {
    let currentTab = activeTab;
    if ((currentTab + 1) < gridTabs.length) {
      setactiveTab(currentTab + 1);
    }
  }

  const handlePrev = () => {
    let currentTab = activeTab;
    if (currentTab !== 0) {
      setactiveTab(currentTab - 1);
    }
  }

  const handleInputChange = async (module, field, data) => {
    if (module == "productsetup") {
      let product_setupData = formData.productSetUp;
      product_setupData[field] = data;
      setFormData((prevFormData) => ({ ...prevFormData, productSetUp: product_setupData }));
    }
    else if (module == "pricing") {
      let pricing_Data = formData.pricing;
      pricing_Data[field] = data;
      setFormData((prevFormData) => ({ ...prevFormData, pricing: pricing_Data }));
    }
    else if (module == "identifier") {
      let identifier_Data = formData.identifier;
      identifier_Data[field] = data;
      setFormData((prevFormData) => ({ ...prevFormData, identifier: identifier_Data }));
    }
    else if (module == "packingInfo") {
      setFormData((prevFormData) => ({ ...prevFormData, packingInfo: data }));
    }

  }

  const handleSubmit = async () => {
    packagingInfo_Ref.current.validate();

    if (formData.packingInfo.find(x => x.title == "" || x.quantity <= 0)) {

    }
    else {
      dispatch(showLoader());
      formData.productSetUp.uomid = formData.productSetUp.uomid == 0 ? null : formData.productSetUp.uomid;

      if (id) {
        let resp = await httpClient.put("ItemMaster/UpdateItem", formData).catch((error) => {
          dispatch(hideLoader());
          toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
        });

        if (resp?.status == 200) {
          toast_Ref.current.showMessage("success", "Item updated successfully", "", "i-chk-circle");
          dispatch(hideLoader());
          window.setTimeout(() => { navigate("/setup/item/list"); }, 1000);
        }
      }
      else {
        let resp = await httpClient.post("ItemMaster/AddItem", formData).catch((error) => {
          dispatch(hideLoader());
          console.log(error);
          toast_Ref.current.showMessage("error", error.response.data?.errors ? error.response.data.errors[0].error : error?.response.data.message, "", "i-notify");
        });

        if (resp?.status == 200) {
          toast_Ref.current.showMessage("success", "Item created successfully", "", "i-chk-circle");
          dispatch(hideLoader());
          window.setTimeout(() => { navigate("/setup/item/list"); }, 1000);
        }
      }
    }
  }

  const handelFetchUPCDetail = async () => {
    dispatch(showLoader());
    let resp = await httpClient.get(`ItemMaster/GetProductByUPC?UPC=${formData.productSetUp.upc}`).catch((error) => { 
      dispatch(hideLoader());
    });

    if (resp?.status == 200) {
      dispatch(hideLoader());
      let item_data = resp?.data;
      
      if(item_data.packingInfo == null){
        item_data.packingInfo = formData.packingInfo;
      }
      setFormData(item_data);
    }
  }



  return (

    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content pb-0">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Item Master"}
          HeaderItems={[]}
        />

        <div className="grid mt-2">
          <div className="col-9">
            <GridTabs
              tabs={gridTabs}
              allowTabClick={false}
              onTabChange={handleTabChange}
              activeIndex={activeTab}
            />
          </div>
        </div>

        <div className={activeTab == 3 ? `tabs_content bg-white mt-2 overflow-block` : `tabs_content bg-white mt-2`}>
          {activeTab == 0 && <ProductSetup formData={formData.productSetUp} dropdowns={form_DDs} onInputChange={handleInputChange} fetchUPCDetail={handelFetchUPCDetail} reloadDropdowns={() => { onLoad() }} />}
          {activeTab == 1 && <Pricing formData={formData.pricing} onInputChange={handleInputChange} />}
          {activeTab == 2 && <Identifiers formData={formData.identifier} onInputChange={handleInputChange} />}
          {activeTab == 3 && <PackingInfo ref={packagingInfo_Ref} formData={formData.packingInfo} dropdowns={form_DDs} onInputChange={handleInputChange} />}
        </div>

        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button type="button" label={'Cancel'} className="btn-secondary" onClick={() => { navigate('/setup/item/list') }} />
          <Button type="button" disabled={activeTab == 0 ? true : false} label={'Previous'} onClick={handlePrev} />
          {activeTab == 3 ? <Button type="button" label={id ? 'Update' : 'Add'} onClick={handleSubmit} /> :
            <Button type="button" label={activeTab == 3 ? 'Add' : 'Next'}
              onClick={handleNext}
              disabled={
                (activeTab == 0 && (formData.productSetUp.upc == "" || formData.productSetUp.modelNo == "" || formData.productSetUp.brandID == 0
                  || formData.productSetUp.categoryID == 0 || formData.productSetUp.hs_code == undefined || formData.productSetUp.hs_code.length < 6)) ||
                (activeTab == 2 && (formData.identifier.asin == "" || formData.identifier.fnsku == "" || formData.identifier.ean == "" || formData.identifier.gtin == ""))
              }
            />
          }

        </div>
      </div>
    </>
  );
};

export default ItemMasrterForm;
