import React, { useState, useRef, useEffect } from "react";
import GridTabs from "../../../shared/GridTabs";
import PageHeader from "../../../shared/PageHeader";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import VivToast from "../../../shared/VivitechToast";
import { useDispatch, useSelector } from "react-redux";

const WarehouseDetail = () => {
  const toast_Ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [activeTab, setactiveTab] = useState(0);
  const [warehouseDetail, setWarehouseDetail] = useState(null);
  // const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const [headerItems, setheaderItems] = useState([{
    type: "editButton",
      icon: "",
      containerClass: "",
      classes: "",
    disabled: false
  }]);

  const gridTabs = [
    { label: "Warehouse" },
    { label: "Zone" },
    { label: "Locations" },
  ];

  useEffect(() => {
    LoadWareHouse();
  }, []);

  const LoadWareHouse = async () => {
    dispatch(showLoader());

    let apiResp = await httpClient.get("Warehouse/GetWarehouseByID?id=" + id).catch((error) => {
      dispatch(hideLoader()); 
      toast_Ref.current.showMessage(
        "error", error?.response.data.message, "", "i-notify");
    });

    if(apiResp?.status == 200){
      setWarehouseDetail(apiResp?.data.data);
      dispatch(hideLoader());
    }
  }
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if(module)
    {
      if(module.functions[1].actions[3].value)
      {
        currentHeaderItems[0].disabled = false;
      }
      else{ currentHeaderItems[0].disabled = true; }
      
      setheaderItems(currentHeaderItems);
    }
    
  }

  useEffect(() => {
    LoadRolebase_Actions();
    setWarehouseDetail();
  }, []);

  const handleTabChange = (data) => {
    if (data == 0) {
      let currentActions = pageActions;
      currentActions = [
        { type: "editButton", icon: "", containerClass: "", classes: "" },
      ];
      setPageActions([...currentActions]);
    } else if (data == 1) {
      let currentActions = pageActions;
      if (currentActions.length > 1) {
        currentActions[0].title = "Create Zone";
      } else {
        currentActions.unshift({
          type: "button",
          title: "Create Zone",
          containerClass: "",
          classes: "",
        });
      }

      setPageActions([...currentActions]);
    } else if (data == 2) {
      let currentActions = pageActions;

      if (currentActions.length > 1) {
        currentActions[0].title = "Create Location";
      } else {
        currentActions.unshift({
          type: "button",
          title: "Create Location",
          containerClass: "",
          classes: "",
        });
      }

      setPageActions([...currentActions]);
    }
    setactiveTab(data);
  };

  const handlePageHeaderAction = (data) => {
    //alert(JSON.stringify(data));

    if (data.title == "Create Zone") {
      navigate("/setup/warehouse/zone/form");
    }

    if (data.title == "Create Location") {
      navigate("/setup/warehouse/location/form");
    }

    if (data.actiontype == "editButton") {
      navigate(`/setup/warehouse/form/${id}`);
    }
  };

  return (
    <>
    <VivToast ref={toast_Ref} />
      <div className="page_content pb-0">
        <PageHeader
          PageTitle={"Warehouse"}
          onPageActoin={handlePageHeaderAction}
          showBackButton={true}
          backButtonRoute="/setup/warehouse/list"
          HeaderItems={headerItems}
        />

        <div className="user_tabs_content bg-white mt-3">
          {/* <div className="grid pl-4 pr-4 mt-2">
            <div className="col-5">
              <GridTabs
                tabs={gridTabs}
                onTabChange={handleTabChange}
                activeIndex={activeTab}
              />
            </div>
          </div> */}
          {activeTab == 0 && (
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-3">
                <p className="detail-lable">Warehouse Name</p>
                <p className="detail-text">{warehouseDetail?.warehouseName ? warehouseDetail.warehouseName : '-'}</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Contact Person Name</p>
                <p className="detail-text">{warehouseDetail?.contactPersonName ? warehouseDetail.contactPersonName : '-'}</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Contact Number</p>
                <p className="detail-text">{warehouseDetail?.contactNumber ? warehouseDetail.contactNumber : '-'}</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Email Address</p>
                <p className="detail-text">{warehouseDetail?.emailAddress ? warehouseDetail.emailAddress : '-'}</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Size of Warehouse</p>
                <p className="detail-text">{warehouseDetail?.sizeWarehouse ? warehouseDetail.sizeWarehouse : '-'}</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Time Zone</p>
                <p className="detail-text">{warehouseDetail?.timeZone ? warehouseDetail.timeZone : '-'}</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Country</p>
                <p className="detail-text">{warehouseDetail?.country ? warehouseDetail.country : '-'}</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Address 1</p>
                <p className="detail-text">{warehouseDetail?.address1 ? warehouseDetail.address1 : '-'}</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Address 2</p>
                <p className="detail-text">{warehouseDetail?.address2 ? warehouseDetail.address2 : '-'}</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">City</p>
                <p className="detail-text">{warehouseDetail?.city ? warehouseDetail.city : '-'}</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">State/Province</p>
                <p className="detail-text">{warehouseDetail?.state ? warehouseDetail.state : '-'}</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Postal Code</p>
                <p className="detail-text">{warehouseDetail?.postalCode ? warehouseDetail.postalCode : '-'}</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Status</p>
                <p className="detail-text">
                  <Badge severity={warehouseDetail?.status == "Active" ? "success" : "danger"}></Badge>&nbsp;
                  {warehouseDetail?.status ? warehouseDetail.status : '-'}
                </p>
              </div>
            </div>
          )}
          {activeTab == 1 && (
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-3">
                <p className="detail-lable">Zone Name</p>
                <p className="detail-text">Zone 1</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Zone Code</p>
                <p className="detail-text">z11</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Default Type</p>
                <p className="detail-text">-</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Description</p>
                <p className="detail-text">-</p>
              </div>
            </div>
          )}
          {activeTab == 2 && (
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-3">
                <p className="detail-lable">Location Name</p>
                <p className="detail-text">A101</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Aisle</p>
                <p className="detail-text">A</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Rack</p>
                <p className="detail-text">01</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Shelf</p>
                <p className="detail-text">01</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Zone/Sub area</p>
                <p className="detail-text">Zone 1</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Pickable</p>
                <p className="detail-text">Yes</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Length</p>
                <p className="detail-text">20 ft</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Width</p>
                <p className="detail-text">20 ft</p>
              </div>
              <div className="col-6">
                <p className="detail-lable">Height</p>
                <p className="detail-text">12 ft</p>
              </div>
              <div className="col-3">
                <p className="detail-lable">Status</p>
                <p className="detail-text align-items-center flex gap-1">
                  <Badge severity="success"></Badge> Active
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button
            label={"Close"}
            className="btn-secondary"
            onClick={() => navigate("/setup/warehouse/list")}
          />
          {/* <Button
            label={"Update"}
            onClick={() => navigate(`/setup/warehouse/form/${id}`)}
          /> */}
        </div>
      </div>
    </>
  );
};

export default WarehouseDetail;
