import React, { useState, useEffect, useLayoutEffect } from "react";

import PageHeader from "../../../../shared/PageHeader";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/mainSlice";
import httpClient from "../../../../_util/api";
import { useSearchParams, useParams } from "react-router-dom";

const LocationDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams()
  const {ZoneID} = useParams ()
  const [activeTab, setactiveTab] = useState(0);
  const [LocationDetail, setLocationDetail] = useState({});
  const [headerItems, setheaderItems] = useState([{
    type: "editButton",
      icon: "",
      containerClass: "",
      classes: "",
    disabled: false
  }]);
 

  const handlePageHeaderAction = (data) => {
    //alert(JSON.stringify(data));

    if (data.title == "Create Location") {
      navigate("/setup/warehouse/location/form");
    }

    if (data.actiontype == "editButton") {
      navigate(`/setup/warehouse/location/form/${LocationDetail.zoneID}/${LocationDetail.zoneType}/${id}`);
    }
  };



  useEffect(() => {
    loadlocation();
  }, []);

  const loadlocation = async () => {
    dispatch(showLoader());
    let apiResp = await httpClient
      .get(`Location/GetLocationByID?id=${id}`)
      .catch((error) => {
        dispatch(hideLoader());
      });
  
    if (apiResp.status == 200) {
      setLocationDetail(apiResp?.data.data);
      dispatch(hideLoader());
      console.log(LocationDetail);
    }
  }
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if(module)
    {
      if(module.functions[1].actions[3].value)
      {
        currentHeaderItems[0].disabled = false;
      }
      else{ currentHeaderItems[0].disabled = true; }
      
      setheaderItems(currentHeaderItems);
    }
    
  }

  useEffect(() => {
    LoadRolebase_Actions();
  }, []);



  //   if (apiResp?.data.isSuccess) {
  //     setLocationDetail(apiResp?.data.data);
  //     dispatch(hideLoader());
  //     console.log(`locationdata: ${LocationDetail}`);
  //   } else {
  //     dispatch(hideLoader());
  //   }
  // };



  return (
    <>
      <div className="page_content pb-0">
        <PageHeader
          PageTitle={"Location Detail"}
            onPageActoin={handlePageHeaderAction}
          showBackButton={true}
          backButtonRoute={`/setup/warehouse/location/list/${LocationDetail.zoneID}`}
          HeaderItems={headerItems}
        />
        <div className="user_tabs_content bg-white mt-3">
          <div className="grid pl-4 pr-4 mt-2"></div>

          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-3">
              <p className="detail-lable">Location Name</p>
              <p>{LocationDetail.locationName ? LocationDetail.locationName : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Aisle</p>
              <p>{LocationDetail.aisle ? LocationDetail.aisle : '-'}</p>
            </div>
            <div className="col-6">
              <p className="detail-lable">Rack</p>
              <p>{LocationDetail.rack ? LocationDetail.rack : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Shelf</p>
              <p>{LocationDetail.shelf ? LocationDetail.shelf : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Zone/Sub area</p>
              <p>{LocationDetail.zoneID ? LocationDetail.zoneID : '-'}</p>
            </div>
            <div className="col-6">
              <p className="detail-lable">Pickable</p>
              <p>true</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Length</p>
              <p>{LocationDetail.length ? LocationDetail.length : '-'} {LocationDetail.unitLength ? LocationDetail.unitLength : ''}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Width</p>
              <p>{LocationDetail.width ? LocationDetail.width : '-'} {LocationDetail.unitWidth ? LocationDetail.unitWidth : ''}</p>
            </div>
            <div className="col-6">
              <p className="detail-lable">Height</p>
              <p>{LocationDetail.height ? LocationDetail.height : '-'} {LocationDetail.unitHeight ? LocationDetail.unitHeight : ''}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Status</p>
              <p className="detail-text align-items-center flex gap-1">
                <Badge severity="success"></Badge> Active
              </p>
            </div>
          </div>
        </div>
        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button
            label={"Close"}
            className="btn-secondary"
            onClick={() => navigate(`/setup/warehouse/location/list/${LocationDetail.zoneID}`)}
          />
        </div>
      </div>
    </>
  );
};

export default LocationDetail;
