import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../shared/PageHeader";
import GridTabs from "../../../shared/GridTabs";
import { Button } from "primereact/button";
import ProductSetup from "./components/product_setup";
import Pricing from "./components/pricing";
import Identifiers from "./components/identifiers";
import PackingInfo from "./components/packing-info";
import httpClient from "../../../_util/api";
import VivToast from "../../../shared/VivitechToast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader, hideLoader } from "../../../redux/mainSlice";

import "./style.scss";

const ItemMasrterDetail = () => {
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const packagingInfo_Ref = useRef();
  const toast_Ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState(0);
  const { id } = useParams();
  const isMounted = useRef(false);

  const [form_DDs, setFormDDs] = useState({
    brands: [],
    categories: [],
    companies: [],
    conditions: [],
    uoms: []
  });

  const [headerItems, setheaderItems] = useState([{
    type: "editButton",
    icon: "",
    containerClass: "",
    classes: "",
    disabled: false
  }]);

  const [formData, setFormData] = useState({
    productSetUp: {
      "image": null,
      "upc": "",
      "modelNo": "",
      "itemRecall": "",
      "status": "Active",
      "hs_code": undefined,
      "description": "",
      "tags": null,
      "createdBy": "",
      "createdOn": new Date(),
      "brandID": 0,
      "categoryID": 0,
      "conditionID": 1,
      "companyID": null,
      "uomid": 0
    },
    pricing: {
      "sitePrice": 0.00,
      "wholeSalePrice": 0.00,
      "localStorePrice": 0.00,
      "standardPrice": 0.00,
      "mapPrice": 0.00,
      "averageCost": 0.00
    },
    identifier: {
      "master_SKU": "",
      "fnsku": "",
      "gtin": "",
      "manafacturer_SKU": "",
      "asin": "",
      "ean": ""
    },
    packingInfo: [
      {
        "id": 1,
        "title": "string",
        "uomid": 5,
        "quantity": 0,
        "width": 0,
        "height": 0,
        "length": 0,
        "weight": 0
      }
    ]
  });

  const gridTabs = [
    { label: "Product Setup" },
    { label: "Pricing" },
    { label: "Identifiers" },
    { label: "Packing Info" },
  ];

  const fetchItemMasterDetail = async () => {
    dispatch(showLoader());
    let resp = await httpClient.get(`ItemMaster/GetItemMasterDetailsById?Id=${id}`).catch((error) => { dispatch(hideLoader()); });

    if (resp?.status == 200) {
      const newArr = resp?.data.packingInfo.map(item => ({ ...item, uomid: form_DDs.uoms.find(i => i.id == item.uomid)?.unitofMeasure }));
      resp.data.packingInfo = newArr;
      setFormData(resp?.data);
      dispatch(hideLoader());
    }
  }

  const Load_DDs = async () => {
    let resp = await httpClient.get(`ItemMaster/GetAllProductSetupDropDownsData`).catch((error) => { console.log(error); });
    if (resp?.status == 200) {
      setFormDDs(resp.data.data);
    }
  }

  const onLoad = async () => {
    await Load_DDs();
  }

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if (module) {
      if (module.functions[0].actions[1].value) {
        currentHeaderItems[0].disabled = false;
      }
      else { currentHeaderItems[0].disabled = true; }

      setheaderItems(currentHeaderItems);
    }

  }

  useEffect(() => {
    if (isMounted.current) {
      if (id) {
        fetchItemMasterDetail();
      }
    } else {
      isMounted.current = true;
    }
  }, [form_DDs]);

  useEffect(() => {
    LoadRolebase_Actions();
    onLoad();
  }, []);

  const handlePageHeaderAction = (data) => {

    if (data.actiontype == "editButton") {
      navigate(`/setup/item/form/${id}`);
    }
  };

  const handleTabChange = (data) => {
    setactiveTab(data);
  };

  const handleNext = () => {
    let currentTab = activeTab;
    if ((currentTab + 1) < gridTabs.length) {
      setactiveTab(currentTab + 1);
    }
  }

  const handlePrev = () => {
    let currentTab = activeTab;
    if (currentTab !== 0) {
      setactiveTab(currentTab - 1);
    }
  }

  return (

    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content pb-0">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Item Master"}
          HeaderItems={headerItems}
        />

        <div className="grid mt-1">
          <div className="col-9">
            <GridTabs
              tabs={gridTabs}
              allowTabClick={true}
              onTabChange={handleTabChange}
              activeIndex={activeTab}
            />
          </div>
        </div>

        <div className={activeTab == 3 ? `tabs_content bg-white overflow-block` : `tabs_content bg-white`}>
          {activeTab == 0 && <ProductSetup viewmode={"detail"} formData={formData.productSetUp} dropdowns={form_DDs} />}
          {activeTab == 1 && <Pricing viewmode={"detail"} formData={formData.pricing} />}
          {activeTab == 2 && <Identifiers viewmode={"detail"} formData={formData.identifier} />}
          {activeTab == 3 && <PackingInfo viewmode={"detail"} ref={packagingInfo_Ref} formData={formData.packingInfo} dropdowns={form_DDs} />}
        </div>

        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button type="button" label={'Cancel'} className="btn-secondary" onClick={() => { navigate('/setup/item/list') }} />
          <Button type="button" label={'Previous'} onClick={handlePrev} />
          <Button type="button" label={'Next'} onClick={handleNext} />
        </div>
      </div>
    </>
  );
};

export default ItemMasrterDetail;
