import React, { useEffect, useRef } from "react";
import FormInput from "../../../../shared/Form-Input";
import FormInputGroup from "../../../../shared/Form-InputGroup";
import VIV_Dropdown from "../../../../shared/Form-Select";
import VIV_FileUpload from "../../../../shared/File-Upload";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import InsertNewItemDialog from "./insert_newItemDialog";
import httpClient from "../../../../_util/api";
import VivToast from "../../../../shared/VivitechToast";
import { showLoader, hideLoader } from "../../../../redux/mainSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";


const ProductSetup = (props) => {

  const InsertItem_PopUpRef = useRef();
  const toast_Ref = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.mainSlice.userdetail);
  const { id } = useParams();

  useEffect(() => {
  }, [props.dropdowns]);

  const handleInputChange = (e, field) => {
    props.onInputChange("productsetup", field, e);
  };

  const handleDDChange = (e, field) => {
    props.onInputChange("productsetup", field, e);
  };

  const handleStatusChange = (e) => {
    props.onInputChange("productsetup", "status", e.value ? "Active" : "Inactive");
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleFileUpload = (data) => {
    if (data?.files) {
      getBase64(data.files[0], (result) => {
        props.onInputChange("productsetup", "image", result);
      });
    }
    else {
      getBase64(data[0], (result) => {
        props.onInputChange("productsetup", "image", result);
      });
    }
  }

  const handleInsertItem = async (e) => {
    if (e?.type == "Category") {
      dispatch(showLoader());

      let resp = await httpClient.post(`ItemMaster/AddCategory?category=${e?.data}`, null).catch((error) => {
        dispatch(hideLoader());
        toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
      });
      if (resp?.status == 200) {
        props?.reloadDropdowns();
        dispatch(hideLoader());
        InsertItem_PopUpRef.current.hidePopUp();
        toast_Ref.current.showMessage("success", "Category added successfully", "", "i-chk-circle");
      }
    }
  }

  const handelFetchUPCDetail = () => {

  }

  if (props?.viewmode == undefined) {
    return (
      <>
        <VivToast ref={toast_Ref} />
        <InsertNewItemDialog ref={InsertItem_PopUpRef} onItemEnter={(e) => { handleInsertItem(e) }} />
        <div className="grid pl-4 pr-4 mt-2">
          <div className="col-4 flex flex-column gap-3">

            {id && <FormInput title={"UPC"} required={true} value={props.formData.upc} onchange={(e) => handleInputChange(e, "upc")} />}

            {!id &&
              <FormInputGroup inputtype={"text"}
                icon={"i-search"}
                title={"UPC"}
                required={true}
                value={props.formData.upc}
                onchange={(e) => handleInputChange(e, "upc")}
                onIconTap={() => { props?.fetchUPCDetail(); }}
                onEnterKeyPress={() => { props?.fetchUPCDetail(); }} />
            }

            <FormInput title={"Model #"} required={true} value={props.formData.modelNo} onchange={(e) => handleInputChange(e, "modelNo")} />

            <VIV_Dropdown
              title={"Brand"}
              type={"templateDD"}
              required={true}
              selectedOption={props.formData.brandID}
              options={props.dropdowns.brands}
              optionLabel={"brandName"}
              onSelect={(e) => handleDDChange(e, "brandID")}
            />
          </div>
          <div className={props.formData.image == null ? "col-4 pl-3" : "col-4 flex justify-content-end pl-3"}>
            {props.formData.image == null ? <VIV_FileUpload subHeading={"Supported formats: JPEG, PNG"} accept={"image/jpeg, image/png"} onUpload={handleFileUpload} /> :
              <>
                <Button icon="pi i-bin" className="btn_imgDelete mr-2 mt-2" onClick={() => { props.onInputChange("productsetup", "image", null); }} rounded />
                <img width={"100%"} height={"100%"} src={props.formData.image} />
              </>
            }

          </div>
        </div>

        <div className="grid pl-4 pr-4 mt-2">
          <div className="col-4">
            <VIV_Dropdown
              title={"Item Recall"}
              type={"regular"}
              options={[
                { name: "Yes", id: "yes" },
                { name: "No", id: "no" },
              ]}
              selectedOption={props.formData.itemRecall}
              onSelect={(e) => handleDDChange(e, "itemRecall")}
            />
          </div>
          <div className="col-4">
            <VIV_Dropdown
              title={"Category"}
              type={"templateDD"}
              required={true}
              selectedOption={props.formData.categoryID}
              options={props.dropdowns.categories}
              optionLabel={"categoryName"}
              onSelect={(e) => handleDDChange(e, "categoryID")}
              onAddButtonClick={() => { InsertItem_PopUpRef.current.showPopUp("Add New Category", "Enter Category Name"); }}
            />
          </div>
        </div>
        <div className="grid pl-4 pr-4 mt-2">
          <div className="col-4">
            <VIV_Dropdown
              title={"Condition"}
              type={"regular"}
              options={props.dropdowns.conditions}
              optionLabel={"conditionName"}
              selectedOption={props.formData.conditionID}
              onSelect={(e) => handleDDChange(e, "conditionID")}
            />
          </div>
          <div className="col-4">
            <FormInput
              title={"HS Code"}
              inputtype={"number"}
              required={true}
              value={props.formData.hs_code}
              maxLength={6}
              onchange={(e) => handleInputChange(e, "hs_code")}
            />
          </div>
          {/* <div className="col-4">
            <FormInput
              title={"Tags"}
              inputtype={"chip"}
              value={props.formData.tags ? props.formData.tags.split(',') : []}
              onchange={(e) => handleInputChange(e, "tags")}
            />
          </div> */}
        </div>
        <div className="grid pl-4 pr-4 mt-2">

          <div className="col-4">
            <div className="flex flex-column gap-2">
              <label>Created On</label>
              <Calendar
                placeholder="Created On"
                className="inputCalender"
                dateFormat="mm/dd/yy"
                icon="i-calender"
                value={props.formData.createdOn}
                disabled
                onChange={(e) => handleInputChange(e.value, "createdOn")}
              />
            </div>
          </div>

          <div className="col-4">
            <FormInput
              title={"Created By"}
              isDisabled={true}
              value={userInfo?.username}
              onchange={(e) => handleInputChange(e)}
            />
          </div>
          {/* <div className="col-4">
            <VIV_Dropdown
              title={"Company"}
              type={"templateDD"}
              options={props.dropdowns.companies}
              optionLabel={"companyName"}
              onSelect={(e) => handleDDChange(e)}
            />
          </div> */}
        </div>
        <div className="grid pl-4 pr-4 mt-2">

          <div className="col-4">
            <FormInput
              title={"Description"}
              inputtype={"textarea"}
              value={props.formData.description}
              onchange={(e) => handleInputChange(e, "description")}
            />
          </div>

          <div className="col-4">
            <VIV_Dropdown
              title={"Inner Pack"}
              type={"regular"}
              options={props.dropdowns.uoms}
              optionLabel={"unitofMeasure"}
              selectedOption={props.formData.uomid}
              onSelect={(e) => handleDDChange(e, "uomid")}
            />
          </div>

        </div>
        <div className="grid pl-4 pr-4 mt-2">
          <div className="col-4 align-self-end">
            <div className="flex flex-column gap-2">
              <label>Is active</label>
              <InputSwitch
                value={props.formData.status == "Active" ? true : false}
                checked={props.formData.status == "Active" ? true : false}
                onChange={handleStatusChange} />
            </div>
          </div>

        </div>
      </>
    );
  }
  else {
    return (
      <>
        <div className="grid pl-4 pr-4 mt-2">
          <div className="col-9">
            <div className="grid">
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>UPC</p>
                  <p>{props?.formData.upc ? props?.formData.upc : "-"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Model #</p>
                  <p>{props?.formData.modelNo ? props?.formData.modelNo : "-"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Brand</p>
                  <p>{props?.formData.brandID ? props?.dropdowns.brands.find(x => x.id == props?.formData.brandID)?.brandName : "-"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Item Recall</p>
                  <p>
                    {props?.formData.itemRecall
                      ? props?.formData.itemRecall == "yes"
                        ? "Yes"
                        : "No"
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Category</p>
                  <p>{props?.formData.categoryID ? props?.dropdowns.categories.find(x => x.id == props?.formData.categoryID)?.categoryName : "-"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Condition</p>
                  <p>{props?.formData.conditionID ? props?.dropdowns.conditions.find(x => x.id == props?.formData.conditionID)?.conditionName : "-"}</p>
                </div>
              </div>
              {/* <div className="col-4 ">
                <div className="flex flex-column gap-2">
                  <p>Tags</p>
                  <p>{props?.formData.tags ? props?.formData.tags : "-"}</p>
                </div>
              </div> */}
              <div className="col-4 ">
                <div className="flex flex-column gap-2">
                  <p>HS Code</p>
                  <p>{props?.formData.hs_code ? props?.formData.hs_code : "-"}</p>
                </div>
              </div>
              <div className="col-4 ">
                <div className="flex flex-column gap-2">
                  <p>Description</p>
                  <p>{props?.formData.description ? props?.formData.description : "-"}</p>
                </div>
              </div>
              <div className="col-4 ">
                <div className="flex flex-column gap-2">
                  <p>Inner Pack</p>
                  <p>{props?.formData.uomid ? props?.dropdowns.uoms.find(x => x.id == props?.formData.uomid)?.unitofMeasure : "-"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Status</p>
                  <p>{props?.formData.status ? props?.formData.status : "-"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <img width={"100%"} src={props.formData?.image} />
          </div>
        </div>
      </>
    )
  }

};
export default ProductSetup;
