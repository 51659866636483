import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";
import VIV_Dropdown from "../../../../shared/Form-Select";
import { Button } from "primereact/button";

const RecallItemDialog = forwardRef((props, ref) => {

    const [visible, setVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useImperativeHandle(ref, () => ({
        showPopUp() {
            setSelectedOption(null);
            setVisible(true);
        },
    }));

    const footerContent = (
        <div className="align-items-center flex justify-content-end">
            <Button label="Save" disabled={selectedOption == null ? true : false} onClick={() => { props?.onLocationSelect(selectedOption); setVisible(false); }} />
        </div>
    );

    const handleLocationSelect = (e) => {
        setSelectedOption(e);
    }

    return (
        <>
            <Dialog visible={visible} draggable={false} className="changeLocationRecall_popup"
                style={{ width: "35vw" }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className="grid mt-3 mb-3">
                    <div className="col-12 text-center">
                        <h5>Recall Item</h5>
                        <p>This Item is marked a as a recall item.</p>
                        <p>Please place it at location</p>
                    </div>
                    <div className="col-12 mt-1 flex justify-content-center">
                        <VIV_Dropdown styleclass={'w-13rem'} 
                        selectedOption={selectedOption}
                        options={props?.locations} 
                        optionLabel={'locationName'}
                        onSelect={handleLocationSelect} />
                    </div>
                </div>
            </Dialog>
        </>
    )
});

export default RecallItemDialog;