import React, { useEffect, useRef, useState } from 'react'
import VIVGrid from '../../../../shared/VIV-Grid';
import './style.scss'
import { Button } from 'primereact/button';
import VIV_EditableGrid from '../../../../shared/VIV-Grid/Editable-Grid';
import { useSearchParams } from 'react-router-dom';

const UploadRMAFormGrid = (props) => {
  const [gridData, setGridData] = useState([]);
  const VivGridContainerRef = useRef()
  const gridActionsList = [
    {
      label: "Delete",
      icon: "i-bin",
    },
  ];

  const grid_columns = [
    {
      field: "line_no",
      header: "Line#",
      feildtype: "label",
      widthClass: "w-3rem",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "upc",
      header: "UPC",
      feildtype: "text",
      editable: true,
      widthClass: "w-8rem",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "model",
      header: "Model",
      feildtype: "text",
      editable: true,
      widthClass: "w-8rem",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "description",
      header: "Description",
      feildtype: "text",
      editable: true,
      widthClass: "w-16rem",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "quantity",
      header: "Qty",
      feildtype: "text",
      isNumeric: true,
      editable: true,
      widthClass: "w-4rem",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "cost",
      header: "Cost",
      feildtype: "text",
      editable: true,
      widthClass: "w-4rem",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "action",
      header: "Actions",
      feildtype: "menu",
      islink: false,
      statusfeild: false,
      visible: true,
    },
  ];


  const handleInsertlevel = () => {
    let currentGridData = [...props?.gridData];
    currentGridData.push({
      line_no: 0,
      upc: "",
      model_no: 0,
      description: "",
      qty: 0,
      cost: 0,
    });
    console.log("Current grid data", currentGridData);
    setGridData(currentGridData);
    props.onChangeGridData(currentGridData);
  }

  const handlGridFieldChange = (data, ind) => {
    let currentData = [...gridData];
    currentData[ind] = data;
    setGridData(currentData);
    props.onChangeGridData(props.gridData);
  }

  const handleDeleteItem =(e) => {
    let currentData = [...props?.gridData]
    currentData.splice(e.recordId, 1);
    console.log("currentData",currentData);
    setGridData(currentData);
    props.onChangeGridData(currentData);
  }


  useEffect(() => {
    const container = VivGridContainerRef.current
    if (container) {
      container.scrollTop = container.scrollHeight;
    }

  }, [VivGridContainerRef.current?.scrollHeight])
  return (
    <>
      <div className="VIVGRidContainer " ref={VivGridContainerRef}>
        <VIV_EditableGrid
          tableId="uploadRMAFormGridTable"
          gridActions={gridActionsList}
          paginated={false}
          gridCol={grid_columns}
          selection={true}
          gridData={props?.gridData}
          onFieldChange={handlGridFieldChange}
          // onLineAction={(e) => {
          //   console.log("Grid Items Delete",e);
          // }}
          onLineAction={handleDeleteItem}
        />
      </div>
      <Button className='add-item mt-2' label='Add New Item' onClick={handleInsertlevel}></Button>
    </>
  )
}

export default UploadRMAFormGrid