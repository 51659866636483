import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import "./style.scss";

const VIV_Dropdown = (props) => {
  
  const handleSelection = (value) => {
    //setSelectedOption(value);

    props.onSelect(value);
  };

  const handleAddButton = () => {
    if(props?.onAddButtonClick){
      props.onAddButtonClick();
    }
  }

  const panelFooterTemplate = () => {
    return (
      <div className="flex align-items-center gap-3 pb-3 DDfooter_container">
        <Button
          icon="pi i-plus"
          className="ddFooterBtn"
          outlined
          aria-label="Filter"
          onClick={() => handleAddButton()}
        />
        <span>Add New</span>
      </div>
    );
  };

  return (
    <div className="flex flex-column gap-2">
      <label>{props.title} {props?.required && <span className="text-danger">*</span>}</label>
      {props.type && props.type == "templateDD" ? (
        <Dropdown
          value={props.selectedOption}
          className={`w-full ${props.styleclass}`}
          filter={props.filter ? props.filter : false}
          onChange={(e) => handleSelection(e.value)}
          options={props.options ? props.options : []}
          optionLabel={props.optionLabel ? props.optionLabel : "name"}
          optionValue={props.optionValue ? props.optionValue : "id"}
          placeholder={props.placeholder ? props.placeholder : "Select"}
          panelFooterTemplate={panelFooterTemplate}
        />
      ) : (
        <Dropdown
          value={props.selectedOption}
          className={`w-full ${props.styleclass}`}
          filter={props.filter ? props.filter : false}
          onChange={(e) => handleSelection(e.value)}
          options={props.options ? props.options : []}
          optionLabel={props.optionLabel ? props.optionLabel : "name"}
          optionValue={props.optionValue ? props.optionValue : "id"}
          placeholder={props.placeholder ? props.placeholder : "Select"}
        />
      )}
    </div>
  );
};

export default VIV_Dropdown;
