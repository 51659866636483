import React, { useEffect, useRef, useState } from "react";
import PageHeader from "../../../../shared/PageHeader";
// import ExportPopup from "../../../shared/ExportPopup";
import VIVGrid from "../../../../shared/VIV-Grid";
import { Route, useNavigate, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/mainSlice";
import httpClient from "../../../../_util/api";
import GridFilter from "../../../../shared/GridFilter";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../../shared/VivitechToast";

const ZoneSetupList = () => {
  const export_PopUpRef = useRef();
  const toast_Ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialMount = useRef(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [filterString, setFilterString] = useState("");
  const [gridData, setGridData] = useState([]);
  const [showFilter,setShowFilter] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsFrom, setRecordsFrom] = useState(0);
  const [recordsTo, setRecordsTo] = useState(0);
  const {warehouseId} =useParams()
  const [gridActionsList, setgridActionsList] = useState([{ label: "Edit", icon: "i-edit", disabled: false}, { label: "Delete", icon: "i-bin", disabled: false }]);
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const [headerItems, setheaderItems] = useState([{
    type: "button",
    icon: "",
    containerClass: "",
    classes: "",
    title: "Create Zone",
    disabled: false
  }]);
  

  const grid_columns = [
    {
      field: "zoneName",
      header: "Zone",
      islink: true,
      statusfeild: false,
      visible: true,
    },
    {
      field: "zoneCode",
      header: "Zone Code",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "zoneTypeName",
      header: "Type",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "noofLocations",
      header: "No. Of Location",
      islink: true,
      appendID: true,
      to: "/setup/warehouse/location/list/",
      statusfeild: false,
      visible: true,
    },
    {
      field: "status",
      header: "Status",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "action",
      header: "Actions",
      islink: false,
      statusfeild: false,
      visible: true,
    },
  ];

 
  const handlePageChnage = (evnt) => {
    setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
    setpageSize(evnt.rows);
  };

  const handleFilters = (data) => {
    setFilterString(data);
  };

  const filterData=[
    {
      label: "Zone",
      filter_key: "zoneName",
      feild: "input",
      value: "",
    },
    {
      label: "Zone Code",
      filter_key: "zoneCode",
      feild: "input",
      value: "",
    },
    {
      label: "Zone Type",
      filter_key: "zoneTypeName",
      feild: "input",
      value: "",
    },
    {
      label: "No. of Locations",
      filter_key: "noofLocations",
      feild: "input",
      value: "",
    },
    {
      label: "Status",
      filter_key: "status",
      feild: "input",
      value: "",
      islink: false,
      statusfeild: false,
      visible: true,
    },
  ];

  const handlePageHeaderAction = (data) => {
    // if (data.actiontype == "export") {
    //   export_PopUpRef.current.showPopUp();
    // }
    if (data.actiontype == "button") {
      navigate(`/setup/warehouse/zone/form/${warehouseId}`);
    }
  };

  const LoadGridData = async () => {
    dispatch(showLoader());

    let URL = `Zone/GetAllZones?pageNumber=${currentPage}&pageSize=${pageSize}&searchTerm=${searchString}&warehouseId=${warehouseId}`;

    if (filterString !== "") {
      URL = URL + "&" + filterString;
    }
    const resp = await httpClient.get(URL).catch((error) => {
      dispatch(hideLoader());
    });


    if (resp?.data.isSuccess) {
      setTotalRecords(resp?.data.page_TotalRecords);
      setRecordsFrom(resp?.data.page_From);
      setRecordsTo(resp?.data.page_To);
      setGridData(resp?.data.details);
    }
    dispatch(hideLoader());
  };

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let gridActionList = gridActionsList;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if(module)
    {
      if(module.functions[1].actions[3].value)
      {
        currentHeaderItems[0].disabled = false;
      }
      else{ currentHeaderItems[0].disabled = true; }

      //Edit Permission
      if(module.functions[1].actions[1].value){
        gridActionList[0].disabled = false;
      }
      else{
        gridActionList[0].disabled = true;
      }

      //Delete Permission
      if (module.functions[1].actions[2].value) {
        gridActionList[1].disabled = false;
      }
      else {
        gridActionList[1].disabled = true;
      }

      setgridActionsList(gridActionList);
      setheaderItems(currentHeaderItems);
    }
    
  }


  useEffect(()=>{
    LoadRolebase_Actions()
  },[])

  useEffect(() => {
    LoadGridData();
    //console.log(`warehouseId:${warehouseId}`);
  }, [currentPage, pageSize, filterString,warehouseId]);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      setTimeout(() => {
        LoadGridData();
      }, 1500);
    }
  }, [searchString]);


  const deleteZone = async (id) => {
    dispatch(showLoader());
    let resp = await httpClient.delete(`Zone/RemoveZone?id=${id}`).catch((error) => {
      //console.log(error); 
      dispatch(hideLoader());
      toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
    });

    if (resp?.status == 200) {
      dispatch(hideLoader());
      toast_Ref.current.showMessage("success", "Zone deleted successfully", "", "i-chk-circle");
      LoadGridData();
    }
  }

  const handleGridAction = (e) => {
    if (e?.action.toLowerCase() == "delete") {
      confirmDialog({
        message: 'Are you sure you want to delete this zone?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        draggable: false,
        accept: () => { deleteZone(e.recordId) },
        reject: () => { }
      });
    }
    else {
      navigate(`/setup/warehouse/zone/form/${warehouseId}/${e?.recordId}`);
    }
  }

  return (
    <>
      {/* <ExportPopup ref={export_PopUpRef} title={`Export`} /> */}
      <ConfirmDialog />
      <VivToast ref={toast_Ref} />
      <GridFilter
        data={filterData}
        visible={showFilter}
        visibleState={setShowFilter}
        onApplyFilter={handleFilters}
      />
     

      <div className="page_content pb-0" ref={initialMount}>
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Zones"}
          showBackButton={true}
          backButtonRoute="/setup/warehouse/list"
          HeaderItems={headerItems}
        />
        <div className="mt-4">
          <div className="flex gap-3 align-items-center justify-content-end">
            <span className="p-input-icon-left">
              <i className="i-search" />
              <InputText placeholder="Search"  value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}/>
            </span>

            <Button
              label="Filter"
              icon="i-filter"
              className="bg-white justify-content-center clr-b"
              text
              raised
              onClick={() => setShowFilter(true)}
            />
          </div>

           <VIVGrid
            gridActions={gridActionsList}
            paginated={true}
            gridCol={grid_columns}
            selection={true}
            gridData={gridData}
            TotalRecords={totalRecords}
            recordfrom={recordsFrom}
            recordto={recordsTo}
            centerAlign={false}
            onLineAction={handleGridAction}
            onPageChange={handlePageChnage}
            baseURL={"/setup/warehouse/zone/detail/"}
          />
        </div>
      </div>
    </>
  );
};

export default ZoneSetupList;
