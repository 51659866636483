import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Chips } from 'primereact/chips';
import { InputTextarea } from 'primereact/inputtextarea';

const FormInput = (props) => {
  const handleChange = (e) => {
    props.onchange(e);
  };
  const handleFocus = () => {
    if (props.onFocus) {
      props.onFocus();
    }
  };
  return (
    <div className="flex flex-column gap-2">
      {props.title && 
      <label>{props.title} {props?.required && <span className="text-danger">*</span>} </label>
      }
      {props.inputtype == "number" && <InputNumber
        useGrouping={false}
        min={0}
        mode={props.mode ? props.mode : "decimal"}
        currency={props.currency ? props.currency : "USD"}
        maxLength={props.maxLength ? props.maxLength : null}
        className={`w-full ` + props.styleclass}
        placeholder={props.placeholder ? props.placeholder : "Type here"}
        disabled={props.isDisabled ? true : false}
        onChange={(e) => {props?.maxLength ? handleChange(e.value?.toString().substring(0, props.maxLength)) : handleChange(e.value)}}
        onFocus={handleFocus}
        value={props.value && props.value} />}

      {props.inputtype == "phone" &&
        <InputMask
          className={`w-full ` + props.styleclass}
          mask="(999) 999-9999"
          placeholder={props.placeholder ? props.placeholder : '(999) 999-9999'}
          disabled={props.isDisabled ? true : false}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={handleFocus}
          value={props.value && props.value} />

      }

      {props.inputtype == "chip" &&
        <Chips
          value={props.value}
          placeholder={props.placeholder ? props.placeholder : "Type here"}
          onChange={(e) => handleChipsChange(e.value)} />
      }

      {props.inputtype == "textarea" &&
        <InputTextarea
          value={props.value}
          placeholder={props.placeholder ? props.placeholder : "Type here"}
          onChange={(e) => handleChange(e.target.value)} rows={1} />
      }

      {(!props.inputtype || props.inputtype == "email" || props.inputtype == "text") &&
        <InputText
        className={`w-full ` + props.styleclass}
        placeholder={props.placeholder ? props.placeholder : "Type here"}
        type={props.inputtype ? props.inputtype : "text"}
        disabled={props.isDisabled ? true : false}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        value={props.value && props.value}
      />
      }

    </div>
  );
};

export default FormInput;
