import React, { useState } from "react";
// import { LayoutRoutes } from "../router/layout/routes";
import { AppRoutes } from "../router/routes";
import { AppSideBr } from "../core/AppSidebar";
import { AppMobileSideBr } from "./MobileSidebar";
import AppTopBar from "./AppTopbar";
import PageLoader from "./FullPageLoader";
import { useSelector, useDispatch } from 'react-redux';

export const MainLayout = (props) => {
  //const [sidebarType, setsidebarType] = useState("full-bar");
  const sidebarType = useSelector((state) => state.sidebarSlice.value);

  // const onBarStyleChange = (classname) => {
  //   setsidebarType(classname);
  // };

  return (
    <>
      <div className={`viv_mainLayout`}>
        <AppSideBr />
        <AppMobileSideBr />
        <div className={`viv_contentWrapper ${sidebarType}`}>
          <AppTopBar />
          <AppRoutes />
        </div>
      </div>
    </>
  );
};
