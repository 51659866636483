import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { v4 as uuidv4 } from "uuid";
import "./style.scss";

const GridFilter = ({ data, visible, visibleState, onApplyFilter }) => {
  // const [visible, setVisible] = useState(false);
  const [saveFilter, setSaveFilter] = useState(null);
  const [filter_data, setFilterData] = useState(data);

  const handleResetFilter = () => {
    let data = filter_data;
    data.forEach((item) => {
      item.value = "";
    });
    setFilterData([...data]);
    onApplyFilter("");
    visibleState(false);
  }

  const handleChange = (e, index) => {
    let data = filter_data;
    data[index]["value"] = e.target.value;
    setFilterData([...data]);
  };

  const handleFilterApply = () => {
    let filterString = "&";
    filter_data.forEach((item) => {
      if(item.value !== ""){
        filterString += `${item?.filter_key.toLowerCase()}=${item.value}&`;
      }
    });
    onApplyFilter(filterString);
    visibleState(false);
  };

  const footerContent = (
    <div>
      <Button label="Reset" onClick={handleResetFilter} className="reset" />
      <Button
        label="Apply Filter"
        className="primary"
        onClick={handleFilterApply}
        autoFocus
      />
    </div>
  );

  return (
    <div className="card flex justify-content-center grid_filter">
      {/* <Button
        label="Show"
        icon="pi pi-external-link"
        onClick={() => setVisible(true)}
      /> */}
      <Dialog
        draggable={false}
        header="Filter"
        className="grid_dialog"
        visible={visible}
        style={{ width: "772px" }}
        onHide={() => visibleState(false)}
        footer={footerContent}
      >
        <form>
          <div className="filter-form">
            <div className="grid">
              <div className="col-3 ">
                <Dropdown
                  value={saveFilter}
                  onChange={(e) => setSaveFilter(e.value)}
                  options={[
                    { name: "Save Current Filter" },
                    { name: "Manage Filter" },
                  ]}
                  optionLabel="name"
                  placeholder="Save Filter"
                  className="w-full save_filter"
                />
              </div>
            </div>
            <div className="grid mt-1 ">
              {filter_data.map((e, index) => {
                //console.log(e.feild, "getting data in filter");
                if (e.feild == "input") {
                  return (
                    <div key={index} className="col-6 flex flex-column gap-2">
                      <label>{e.label}</label>
                      <InputText
                        className="w-full"
                        placeholder="Type here"
                        value={e.value}
                        onChange={(evnt) => handleChange(evnt, index)}
                      />
                    </div>
                  );
                } else if (e.feild == "dropdown") {
                  return (
                    <div
                      key={uuidv4()}
                      className="col-6 flex flex-column gap-2"
                    >
                      <label>{e.label}</label>
                      <Dropdown
                        value={e.value}
                        onChange={(e) => handleChange(e, index)}
                        options={e.dataforselect}
                        optionLabel="viewValue"
                        placeholder="Select"
                        className="w-full"
                        panelClassName="filter-dropdown"
                      />
                    </div>
                  );
                } else if (e.feild == "multiselect") {
                  return (
                    <div
                      key={uuidv4()}
                      className="col-6 flex flex-column gap-2"
                    >
                      <label>{e.label}</label>
                      <MultiSelect
                        value={e.value}
                        onChange={(e) => handleChange(e, index)}
                        options={e.dataforselect}
                        optionLabel="viewValue"
                        display="chip"
                        placeholder="Select "
                        maxSelectedLabels={4}
                        className="w-full filter-multi-select "
                      />
                    </div>
                  );
                } else if (e.feild == "date") {
                  return (
                    <div
                      key={uuidv4()}
                      className="col-6 flex flex-column gap-2"
                    >
                      <label>{e.label}</label>
                      <Calendar
                        className=" inputCalender"
                        dateFormat="mm/dd/yy"
                        placeholder="MM-DD-YYYY"
                        value={e.value}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default GridFilter;
