import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./style.scss";
import { Link } from "react-router-dom";
import { Badge } from "primereact/badge";
import { SplitButton } from "primereact/splitbutton";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { v4 as uuidv4 } from "uuid";
import { Paginator } from 'primereact/paginator';
import { useDispatch } from "react-redux";
import { Image } from 'primereact/image';
import { ProgressBar } from 'primereact/progressbar';

const VIVGrid = (props) => {
  //const [products, setProducts] = useState(props.gridData);
  //const [columns, setColumns] = useState(props.gridCol);
  const [baseUrl, setbaseUrl] = useState(props.baseUrl);
  const [allowSelection, setAllowSelection] = useState(
    props.selection ? true : false
  );
  const [selectedItems, setSelectedItems] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const dispatch = useDispatch()

  const onRowSelect = (event) => { };

  const onLineAction = (e) => {
    props.onLineAction(e);
  };

  const linkTemplate = (data, col) => {
    if (col.to) {
      return (
        <Link key={uuidv4()} to={col?.appendID ? col.to + data["id"] : col.to}>
          {data[col.field]}
        </Link>
      );
    }
    return (
      <Link key={uuidv4()} to={props.baseURL + data["id"]}>
        {data[col.field]}
      </Link>
    );
  };

  
  const productTemplate = (data, col) => {
    return (
      <>
        <div className="flex align-items-center gap-3">
          <Image key={uuidv4()} src={data["url"]} width="50px" height="50px" preview />
          {data[col?.field]}
        </div>
      </>
    )
  }

  const statusTemplate = (data, field) => {
    return (
      <>
        <Badge
          severity={(data[field] == "Active" || data[field] == "Completed") ? "success" : (data[field] == "New" || data[field] == "new") ? "info"
            : data[field] == "Draft" ? "default" : data[field] == "Imported" ? "imported" : data[field] == "In Progress" ? "warning" : "danger"}
        ></Badge>{" "}
        {data[field]}
      </>
    );
  };
  
  
  const actionMenuItems = (id) => {
    let arrGridActn = [];
    props.gridActions.forEach((item) => {
      arrGridActn.push({
        label: item.label,
        icon: item.icon,
        disabled: item?.disabled,
        command: (e) => onLineAction({ action: item.label, recordId: id }),
      });
    });

    return arrGridActn;
  };

  const actionTemplate = (data, field) => {
    return (
      <>
        <SplitButton
          label="Save"
          menuClassName="grid_actionsList"
          dropdownIcon="i-ellipse"
          model={actionMenuItems(data.id)}
        />
      </>
    );
  };

  const progressBarTemplate = (data, col) => {
    let randomNum = Math.floor(Math.random() * 100) + 1;
    return (
      <>
        <div className="flex align-items-center gap-2">
          <ProgressBar value={data.status == "New" ? 0 : data.status == "Completed" ? 100 : data.status == "In Progress" ? randomNum : 50} showValue={false}></ProgressBar> 
          {/* <span>{data[col?.field]}%</span> */}
          <span>{data.status == "New" ? 0 : data.status == "Completed" ? 100 : data.status == "In Progress" ? randomNum : 50}%</span>
        </div>
      </>
    );
  };
  
  const IconColumnTemplate = (data, col) => {
    return (
      <>
        <div>
          <i className={`cursor-pointer pi ${col.icon}`} onClick={() => onLineAction({ action: col.field, recordId: data.details })}></i>
        </div>
      </>
    );
  };

  const editableTemplate = (data, col) => {
    if (col.feildtype == "text") {
      return <InputText value={data[col.field]} />;
    } else if (col.feildtype == "dropdown") {
      return (
        <Dropdown
          value={data[col.field]}
          options={col.options}
          optionLabel="name"
          optionValue="name"
          placeholder="Select"
          className="w-full grid_DD"
        />
      );
    } else if (col.feildtype == "checkbox") {
      return <Checkbox value={data[col.field]} checked={data[col.field]} />;
    }
  };

  const cellEditor = (options, col, ind) => {
    if (col.feildtype === "text") return textEditor(options, ind);
    else if (col.feildtype === "dropdown")
      return dropdownEditor(options, col, ind);
    else if (col.feildtype === "checkbox")
      return checkboxEditor(options, col, ind);
  };

  const textEditor = (options, ind) => {
    return (
      <InputText
        key={uuidv4()}
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const dropdownEditor = (options, col, ind) => {
    return (
      <Dropdown
        key={uuidv4()}
        value={options.value}
        options={col.options}
        optionLabel="name"
        optionValue="name"
        placeholder="Select"
        className="w-full grid_DD"
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const checkboxEditor = (options, ind) => {
    return (
      <Checkbox
        key={uuidv4()}
        value={options.value}
        checked={options.value}
        onChange={(e) => options.editorCallback(e.target.checked)}
      />
    );
  };

  const onPageChange = (evnt) => {
    if (props.onPageChange) {
      props.onPageChange(evnt);
    }
    setFirst(evnt.first);
    setRows(evnt.rows);
  }

  return (
    <>
      <DataTable

        value={props.gridData}
        className={`viv_grid ${props?.styleClass} ${props?.centerAlign ? 'th-center' : ''}`}
        scrollable
        paginator={false}
        selectionMode={allowSelection ? "checkbox" : null}
        selection={selectedItems}
        onSelectionChange={(e) => setSelectedItems(e.value)}
        onRowSelect={onRowSelect}
        dataKey="id"
        tableStyle={{ width: "100%" }}
        cellClassName={props?.centerAlign ? "text-center" : ""}
      >
        {allowSelection && (
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
        )}
        {props.gridCol
          .filter((x) => x.visible)
          .map((col, i) =>
            col?.isProduct ? (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                className={col?.widthClass ? col.widthClass : null}
                body={(rowData) => productTemplate(rowData, col)}
              />
            ) : col.islink ? (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                className={col?.widthClass ? col.widthClass : null}
                body={(rowData) => linkTemplate(rowData, col)}
              />
            ) : col.field == "status" ? (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                className={col?.widthClass ? col.widthClass : null}
                body={(rowData) => statusTemplate(rowData, col.field)}
              />
            ) : col.editable ? (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                className={col?.widthClass ? col.widthClass : null}
                body={(rowData) => editableTemplate(rowData, col)}
                editor={(options) => cellEditor(options, col, i)}
              />
            ) : col?.isProgressbar ? (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                className={col?.widthClass ? col.widthClass : null}
                body={(rowData) => progressBarTemplate(rowData, col)}
              />
            ) : col?.isIconColumn ? (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                className={col?.widthClass ? col.widthClass : null}
                body={(rowData) => IconColumnTemplate(rowData, col)}
              />
            ) : col.field == "action" ? (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                className={col?.widthClass ? col.widthClass : null}
                body={(rowData) => actionTemplate(rowData, col.field)}
              />
            ) : (
              <Column key={col.field} field={col.field} header={col.header}
                body={(rowData) => rowData[col.field] ? rowData[col.field] : '-'}
                className={col?.widthClass ? col.widthClass : ""}
              />
            )
          )}
      </DataTable>

      {props.paginated && <>
        <div className="viv_paginator align-items-center flex justify-content-between">
          <div>
            <span className="p-paginator-current">{`Results: ${props.recordfrom ? props.recordfrom : 0}-${props.recordto ? props.recordto : 0} of ${props.TotalRecords ? props.TotalRecords : 0} entries`}</span>
          </div>
          <div className="">
            <Paginator first={first} rows={rows} totalRecords={props.TotalRecords ? props.TotalRecords : 0} rowsPerPageOptions={[5, 10, 25, 50]} onPageChange={onPageChange} />
          </div>
        </div></>}
    </>

  );
};

export default VIVGrid;
