import React, { useRef, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import VIVGrid from "../../../shared/VIV-Grid";
import { Route, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import GridFilter from "../../../shared/GridFilter";
import useDebounce from "../../../_util/UseDebounce";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../shared/VivitechToast";


const CarrierList = () => {
  const initialMount = useRef(true);
  const toast_Ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRoles = useSelector((state) => state.mainSlice.userRoles);

  const [headerItems, setheaderItems] = useState([{
    type: "button",
    icon: "",
    containerClass: "",
    classes: "",
    title: "Create Carrier",
    disabled: false
  }]);
  const [gridActionsList, setgridActionsList] = useState([{ label: "Edit", icon: "i-edit", disabled: false }, { label: "Delete", icon: "i-bin", disabled: false }]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [filterString, setFilterString] = useState("");
  const [gridData, setGridData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsFrom, setRecordsFrom] = useState(0);
  const [recordsTo, setRecordsTo] = useState(0);
  const [showFilter, setShowFilter] = useState(false);

  useDebounce(() => { initialMount.current ? initialMount.current = false : LoadGridData(); }, [searchString], 1500);

  const grid_columns = [
    {
      field: "carrierName",
      header: "Carrier Name",
      islink: true,
      statusfeild: false,
      visible: true,
    },
    {
      field: "carrierCode",
      header: "Carrier Code",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "carrierEmail",
      header: "Carrier Email",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "truckLoadType",
      header: "Truck Load",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "status",
      header: "Status",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "action",
      header: "Actions",
      islink: false,
      statusfeild: false,
      visible: true,
    },
  ];

  const filterData = [
    {
      label: "Carrier Code",
      filter_key: "CarrierCode",
      feild: "input",
      value: "",
    },
    {
      label: "Carrier Name",
      filter_key: "CarrierName",
      feild: "input",
      value: "",
    },
    {
      label: "Carrier Email",
      filter_key: "CarrierEmail",
      feild: "input",
      value: "",
    }
  ];

  const handlePageHeaderAction = (data) => {
    // if (data.actiontype == "export") {
    //   export_PopUpRef.current.showPopUp();
    // }
    if (data.actiontype == "button") {
      navigate("/setup/carrier/form");
    }
  };

  const LoadGridData = async () => {
    dispatch(showLoader());

    let URL = `Carrier/GetCarriers?pageNumber=${currentPage}&pageSize=${pageSize}&search=${searchString}`;

    if (filterString !== "") {
      URL = URL + "&" + filterString;
    }
    const resp = await httpClient.get(URL).catch((error) => {
      dispatch(hideLoader());
    });


    if (resp?.data.isSuccess) {
      setTotalRecords(resp?.data.page_TotalRecords);
      setRecordsFrom(resp?.data.page_From);
      setRecordsTo(resp?.data.page_To);
      setGridData(resp?.data.details);
    }
    dispatch(hideLoader());
  };

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let gridActionList = gridActionsList;
    let module = userRoles.find(r => r.moduleName == "Setup");
    //console.log(module);

    if (module) {
      if (module.functions[4].actions[3].value) {
        currentHeaderItems[0].disabled = false;
      }
      else { currentHeaderItems[0].disabled = true; }

      //Edit Permission
      if (module.functions[4].actions[1].value) {
        gridActionList[0].disabled = false;
      }
      else {
        gridActionList[0].disabled = true;
      }

      //Delete Permission
      if (module.functions[3].actions[2].value) {
        gridActionList[1].disabled = false;
      }
      else {
        gridActionList[1].disabled = true;
      }

      setgridActionsList(gridActionList);
      setheaderItems(currentHeaderItems);
    }

  }

  useEffect(() => {
    LoadRolebase_Actions();
    //console.log(userRoles);
  }, []);

  useEffect(() => {
    LoadGridData();
  }, [currentPage, pageSize, filterString]);

  const handlePageChnage = (evnt) => {
    setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
    setpageSize(evnt.rows);
  };

  const handleFilters = (data) => {
    setFilterString(data);
  };

  const deleteCarrier = async (id) => {
    dispatch(showLoader());
    let resp = await httpClient.delete(`Carrier/DeleteCarrier?Id=${id}`).catch((error) => {
      //console.log(error); 
      dispatch(hideLoader());
      toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
    });

    if (resp?.status == 200) {
      dispatch(hideLoader());
      toast_Ref.current.showMessage("success", resp?.data.message, "", "i-chk-circle");
      LoadGridData();
    }
  }

  const handleGridAction = (e) => {
    if (e?.action.toLowerCase() == "delete") {
      confirmDialog({
        message: 'Are you sure you want to delete this carrier?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        draggable: false,
        accept: () => { deleteCarrier(e.recordId) },
        reject: () => { }
      });
    }
    else {
      navigate(`/setup/carrier/form/${e?.recordId}`);
    }
  }

  return (
    <>
      <ConfirmDialog />
      <VivToast ref={toast_Ref} />
      <GridFilter
        data={filterData}
        visible={showFilter}
        visibleState={setShowFilter}
        onApplyFilter={handleFilters}
      />

      <div className="page_content pb-0" ref={initialMount}>
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Carrier"}
          HeaderItems={headerItems}
        />
        <div className="mt-4">
          <div className="flex gap-3 align-items-center justify-content-end">
            <span className="p-input-icon-left">
              <i className="i-search" />
              <InputText placeholder="Search" value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }} />
            </span>

            <Button
              label="Filter"
              icon="i-filter"
              onClick={() => setShowFilter(true)}
              className="bg-white justify-content-center clr-b"
              text
              raised
            />
          </div>

          <VIVGrid
            gridActions={gridActionsList}
            paginated={true}
            gridCol={grid_columns}
            selection={true}
            gridData={gridData}
            TotalRecords={totalRecords}
            recordfrom={recordsFrom}
            recordto={recordsTo}
            onLineAction={handleGridAction}
            onPageChange={handlePageChnage}
            baseURL={"/setup/carrier/detail/"}
          />
        </div>
      </div>
    </>
  );
};

export default CarrierList;
