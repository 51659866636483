import React from "react";
import FormInput from "../../../../shared/Form-Input";


const Pricing = (props) => {

    const handleInputChange = (e, field) => {
        props.onInputChange("pricing", field, e);
    };

    if (props?.viewmode == undefined || props?.viewmode == "form"){
        return (
            <>
                <div className="grid pl-4 pr-4 mt-2">
                    <div className="col-4">
                        <FormInput inputtype={"number"}
                            title={"Amazon Price"}
                            value={props.formData.amazonPrice}
                            placeholder={"$0.00"}
                            mode={"currency"}
                            currency={"USD"}
                            onchange={(e) => handleInputChange(e, "amazonPrice")} />
                    </div>
                    <div className="col-4">
                        <FormInput inputtype={"number"}
                            title={"Target Price"}
                            placeholder={"$0.00"}
                            value={props.formData.targetPrice}
                            mode={"currency"}
                            currency={"USD"}
                            onchange={(e) => handleInputChange(e, "targetPrice")} />
                    </div>
                </div>
                <div className="grid pl-4 pr-4 mt-2">
                    <div className="col-4">
                        <FormInput inputtype={"number"}
                            title={"Walmart Price "}
                            placeholder={"$0.00"}
                            value={props.formData.walmartPrice}
                            mode={"currency"}
                            currency={"USD"}
                            onchange={(e) => handleInputChange(e, "walmartPrice")} />
                    </div>
                    <div className="col-4">
                        <FormInput inputtype={"number"}
                            title={"Average Price"}
                            placeholder={"$0.00"}
                            value={props.formData.averageCost}
                            mode={"currency"}
                            currency={"USD"}
                            onchange={(e) => handleInputChange(e, "averageCost")} />
                    </div>
                </div>
            </>
        )
    }
    else{
        return(
            <>
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Amazon Price</p>
                  <p>{props?.formData.amazonPrice ? '$' + props?.formData.amazonPrice : "$0.00"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Target Price</p>
                  <p>{props?.formData.targetPrice ? '$' + props?.formData.targetPrice : "$0.00"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Walmart Price</p>
                  <p>{props?.formData.walmartPrice ? '$' + props?.formData.walmartPrice : "$0.00"}</p>
                </div>
              </div>
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <p>Average Price</p>
                  <p>{props?.formData.averageCost ? '$' + props?.formData.averageCost : "$0.00"}</p>
                </div>
              </div>
            </div>
            </>
        )
    }
    
}

export default Pricing;