import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import "./style.scss";

const InputDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(props.selectedOption);

  const handleSelection = (value) => {
    setSelectedOption(value);
    props.onSelect(value);
  };

  const handleInput = (e) => {
    props.onInputChange(e);
  }

  return (
    <div className="flex flex-column gap-2">
      <label>{props.title}</label>
      <div className="input-dropdown">
        <span className="p-input-icon-right w-13rem">
          {props.inputtype == "number" && <InputNumber
            useGrouping={false}
            min={0}
            mode={props?.mode ? props.mode : "decimal"}
            minFractionDigits={2}
            maxFractionDigits={2}
            currency={props?.currency ? props.currency : "USD"}
            maxLength={props?.maxLength ? props.maxLength : null}
            className={`w-full input-dropdown-text ` + props.styleclass}
            placeholder={props?.placeholder ? props.placeholder : "Type here"}
            disabled={props?.isDisabled ? true : false}
            onChange={(e) => { props?.maxLength ? handleInput(e.value?.toString().substring(0, props.maxLength)) : handleInput(e.value) }}
            value={props?.inputValue && props?.inputValue} />}
          
          {(!props.inputtype || props.inputtype == "text") &&
            <InputText
              className={`w-full input-dropdown-text ` + props.styleclass}
              placeholder={props.placeholder ? props.placeholder : "Type here"}
              type={props.inputtype ? props.inputtype : "text"}
              disabled={props.isDisabled ? true : false}
              onChange={(e) => handleInput(e.target.value)}
              value={props.inputValue && props.inputValue}
            />
          }
        </span>
        <Dropdown
          value={props?.selectedOption}
          className={`w-full` + props.styleclass}
          filter={props.filter ? props.filter : false}
          onChange={(e) => handleSelection(e.value)}
          options={props.options ? props.options : []}
          optionLabel={props.optionLabel ? props.optionLabel : "name"}
          optionValue={props.optionValue ? props.optionValue : "id"}
          placeholder={"Select"}
        />
      </div>
    </div>
  );
};

export default InputDropdown;
