import React from "react";
import "./style.scss";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const PageHeader = (props) => {
  let navigate = useNavigate();

  const onPageAction = (type, title = '') => {
    let data = { actiontype: type, title: title, data: "" };
    props.onPageActoin(data);
  };

  return (
    <div className="viv_pageHeader align-items-center flex justify-content-between mt-1">
      <div className="page_title flex align-items-center gap-3">
        {props.showBackButton && (
          <i
            className="pi i-arrow-icon back-icon"
            onClick={() => navigate(props.backButtonRoute)}
          ></i>
        )}
        <h3 className="m-0">{props.PageTitle}</h3>
      </div>
      <div className="page_items align-items-center flex gap-4">
        {props.HeaderItems.map((item, i) => {
          if (item.type == "import") {
            return (
              <div
                key={i}
                className={item?.containerClass ? item.containerClass : ""}
                onClick={() => onPageAction(item.type)}
              >
                <i className={item.icon}></i>
                <p>Import</p>
              </div>
            );
          } else if (item.type == "export") {
            return (
              <div
                key={i}
                className={item?.containerClass ? item.containerClass : ""}
                onClick={() => onPageAction(item.type)}
              >
                <i className={item.icon}></i>
                <p>Export</p>
              </div>
            );
          } else if (item.type == "button") {
            return (
              <div
                key={i}
                className={item?.containerClass ? item.containerClass : ""}
              >
                <Button
                  label={item.title}
                  className={item.classes}
                  disabled={item.disabled}
                  onClick={() => onPageAction("button", item.title)}
                />
              </div>
            );
          } else if (item.type == "editButton") {
            return (
              <Button key={i} icon="pi i-edit" className="btn_headeredit" disabled={item?.disabled} onClick={() => onPageAction("editButton")} />
            );
          } else {
            return <></>;
          }
        })}
      </div>
    </div>
  );
};

export default PageHeader;
