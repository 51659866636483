import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "./style.scss";
import { Calendar } from "primereact/calendar";

const ExportPopup = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    showPopUp() {
      setVisible(true);
    },
  }));

  const footerContent = (
    <div className="mt-3">
      <Button
        label="Close"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button label="Export" onClick={() => setVisible(false)} autoFocus />
    </div>
  );

  return (
    <Dialog
      header={props.title}
      visible={visible}
      draggable={false}
      className="export_popup"
      style={{ width: "33vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
    >
      <div className="grid">
        <div className="col-12 text-center">
          <p className="short_heading">All Records</p>
        </div>
        <div className="col-12 align-content-center flex flex-column">
          <span className="form-feild-title">From</span>
          <Calendar
            placeholder="MM-DD-YYYY"
            className="mt-2 inputCalender"
            dateFormat="mm/dd/yy"
          />
        </div>
        <div className="col-12 align-content-center flex flex-column mt-2">
          <span className="form-feild-title">To</span>
          <Calendar
            placeholder="MM-DD-YYYY"
            className="mt-2 inputCalender"
            dateFormat="mm/dd/yy"
          />
        </div>
      </div>
    </Dialog>
  );
});

export default ExportPopup;
