import React, { useState, useEffect, useRef } from 'react';
import '../scan.scss';
import ShipLogo from "../../../../assets/images/shiplogofinal.svg";
import scanned from "../../../../assets/images/totalscannedlogofinal.svg";
import Location from "../../../../assets/images/locationlogofinal.svg";
import FormInput from '../../../../shared/Form-Input';
import FormInputGroup from '../../../../shared/Form-InputGroup';
import { RadioButton } from 'primereact/radiobutton';
import timericon from '../../../../assets/images/timerlogofinal.svg';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import VIVGrid from '../../../../shared/VIV-Grid';
import { useDispatch } from 'react-redux';
import Countdown, { zeroPad } from "react-countdown";
import ChangeLocation_Dialog from './ChangeLocation_Dialog';
import RecallItemDialog from './RecallItem_Dialog';
import UnmanifestedItemDialog from './UnmanifestedItem_Dialog';
import httpClient from '../../../../_util/api';
import { showLoader, hideLoader } from '../../../../redux/mainSlice';
import VivToast from '../../../../shared/VivitechToast';
import VivitechConfirmDialog from '../../../../shared/VIV-ConfirmDialog';

const ScanRmaDetail = (props) => {
    const countdownRef = useRef(null);
    const confirmDialog_Ref = useRef(null);
    const itemRecall_PopUpRef = useRef(null);
    const itemUnmanifested_PopUpRef = useRef(null);
    const toast_Ref = useRef(null);
    const changeloc_PopUpRef = useRef();
    const [scannedItems, setScannedItems] = useState(props?.pakgDetail?.scannedItems);
    const [recallLocations, setRecallLocations] = useState([]);
    const [scannedLocation, setScannedLocation] = useState(props?.pakgDetail.location);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        upc: "",
        product: "",
        url: "",
        expectedQuantity: "",
        actualQuantity: 0,
        condition: "As Is Return",
        locationId: null
    });

    const handleScannedItem = () => {
        let currentItems = scannedItems;

        currentItems.push(formData);
        setScannedItems([...currentItems]);

        setFormData({
            upc: "",
            product: "",
            url: "",
            expectedQuantity: "",
            actualQuantity: 0,
            condition: "As Is Return",
            locationId: null
        });
    };

    useEffect(() => {
    }, [scannedItems]);

    const [timer, setTimer] = useState(Date.now() + 120000);

    const grid_columns = [
        { field: "product", header: "Product", isProduct: true, visible: true },
        { field: "expectedQuantity", header: "Expected Quantity", visible: true },
        { field: "actualQuantity", header: "Received Quantity", visible: true },
        { field: "condition", header: "Condition", visible: true },
    ];

    const handleTimer = () => {
        setResend(true);
        setTimer(Date.now() + 120000);
    };

    const timerRenderer = ({ minutes, seconds }) => {
        return (
            <span>
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
        );
    };

    const handleInputChange = (data, field) => {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: data }));
    };

    const handleRecallScenerio = async () => {
        dispatch(showLoader());
        let resp = await httpClient.get(`Location/GetLocationByType?type=Recall Area`).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
        });

        if (resp.status == 200) {
            dispatch(hideLoader());
            setRecallLocations(resp.data.locations);
            itemRecall_PopUpRef.current.showPopUp();
        }
    }

    const handleUnManifestedItemScenerio = async () => {
        dispatch(showLoader());
        let resp = await httpClient.get(`Location/GetLocationByType?type=Recall Area`).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
        });

        if (resp.status == 200) {
            dispatch(hideLoader());
            setRecallLocations(resp.data.locations);
            itemUnmanifested_PopUpRef.current.showPopUp();
        }
    }

    const handleScanUPC = async (e) => {
        dispatch(showLoader());
        let resp = await httpClient.post(`RMA/GetItemInfo?upc=${e}&packageNumber=${props?.pakgDetail.packageNumber}`, null).catch((error) => { dispatch(hideLoader()); });

        if (resp?.status == 200) {
            dispatch(hideLoader());

            // if(resp.data.isRecall && resp.data.isUnManifested){

            // }

            if (resp.data.isRecall) {
                handleRecallScenerio();
            }

            if (resp.data.isUnManifested) {
                handleUnManifestedItemScenerio();
            }

            if (resp.data.isNew) {
                toast_Ref.current.showTemplateMessage({
                    severity: 'success',
                    sticky: true,
                    content: (
                        <div className='flex gap-3 align-items-center'>
                            <div><i className='pi i-chk-circle' style={{ fontSize: '1.5rem' }}></i></div>
                            <div className='section_notifyContent'>
                                <h6 className='m-0'>New Item Added Successfully</h6>
                                <h6 className='mt-1'>A new item has been created for UPC <Link style={{ color: "#0097FE" }} to={''}>{resp.data.itemInfo.upc}</Link></h6>
                            </div>
                        </div>
                    ),
                });
            }
            else{

            }

            if (resp.data.itemInfo.condition == "" || resp.data.itemInfo.condition == null) {
                resp.data.itemInfo.condition = "Return";
            }
            setFormData(resp?.data.itemInfo);
        }
    }

    const handleCompleteScan = async () => {
        dispatch(showLoader());

        let post_data = {
            packageNumber: props?.pakgDetail.packageNumber,
            location: scannedLocation,
            scannedItems: scannedItems
        };

        let resp = await httpClient.post('RMA/ScanItem?isCompleted=true', post_data).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
        });

        if (resp?.status == 200) {

            dispatch(hideLoader());
            props?.changeMode(resp?.data.message);

        }
    }

    const handlePartialScan = async () => {
        dispatch(showLoader());
        let post_data = {
            packageNumber: props?.pakgDetail.packageNumber,
            location: scannedLocation,
            scannedItems: scannedItems
        };

        let resp = await httpClient.post('RMA/ScanItem?isCompleted=false', post_data).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
        });

        if (resp?.status == 200) {
            dispatch(hideLoader());
            props?.changeMode(resp?.data.message);
        }
    }

    
    return (
        <>
            <VivitechConfirmDialog ref={confirmDialog_Ref} 
            message={['Are you sure you want to cancel?','All items scanned to the package will be discarded.']}
            icon={'pi i-warning'}
            acceptLabel={'Confirm'} 
            rejectLabel={'Cancel'} accept={() => { props?.changeMode(""); }} />
            
            <VivToast ref={toast_Ref} onHide={() => { alert("now") }} />
            
            <ChangeLocation_Dialog ref={changeloc_PopUpRef} title={"Change Location"} onLoactionScan={(e) => setScannedLocation(e)} />
            
            <RecallItemDialog ref={itemRecall_PopUpRef} locations={recallLocations} onLocationSelect={(e) => handleInputChange(e, "locationId")} />
            
            <UnmanifestedItemDialog ref={itemUnmanifested_PopUpRef} locations={recallLocations} onLocationSelect={(e) => handleInputChange(e, "locationId")} />
            
            <div className="page_content pb-0">
                <div className='grid pl-2'>
                    <div className='col-12 scanrma p-0'>
                        <p style={{ fontSize: "22px" }}>Package#{props?.pakgDetail.packageNumber}</p>
                        <p>RMA# {props?.pakgDetail.rmaNumber} <span style={{ color: "#0097FE", fontSize: "14px", fontWeight: "400" }}> {props?.pakgDetail.packageCount}</span>
                        </p>
                    </div>
                </div>
                <div className='scanRMA_content'>
                    <div className='grid mt-2'>
                        <div className='col-6 pt-0'>
                            <div className='grid'>
                                <div className='col-4'>
                                    <div className="flex align-items-center justify-content-between pl-3 pr-3 gap-2 h-5rem bg-white border-round" style={{ border: "1px solid #D6e4f7" }}>
                                        <div className='scan'><img className="image  " src={ShipLogo} /></div>
                                        <div>
                                            <p className='scanfont'> Ship From </p>
                                            <p className='scanfont'>{props?.pakgDetail.shipFrom}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="flex align-items-center justify-content-between pl-3 pr-3 gap-2 h-5rem bg-white border-round" style={{ border: "1px solid #D6e4f7" }}>
                                        <div className='scan'><img className="image" src={Location} /></div>
                                        <div>
                                            <p className='scanfont'> Location </p>
                                            <p className='scanfont'>{scannedLocation}</p>
                                        </div>
                                        <div className='align-self-baseline flex mt-2 cursor-pointer'>
                                            <i className='pi i-swap' onClick={() => { changeloc_PopUpRef.current.showPopUp(); }}></i>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className="flex align-items-center justify-content-between pl-3 pr-3 gap-2 h-5rem bg-white border-round p-1" style={{ border: "1px solid #D6e4f7" }}>
                                        <div className='scan'><img className="image" src={scanned} /></div>
                                        <div> <p className='scanfont'> Total Scanned Items </p>
                                            <p className='scanfont'>{props?.pakgDetail.scannedItems?.length}</p></div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 scandoc'>
                                <div className='grid'>
                                    <div className='col-7 scanfont'>

                                        <FormInputGroup inputtype={"text"} icon={"i-scanner"}
                                            title={"Scan item UPC"}
                                            placeholder={"Scan Item UPC"}
                                            required={true}
                                            value={formData.upc}
                                            onchange={(e) => handleInputChange(e, "upc")}
                                            onBlur={(e) => handleScanUPC(e)} />

                                    </div>
                                    <div className='col-5 scanfont'>
                                        <FormInput
                                            title="Quantity"
                                            placeholder="0"
                                            inputtype="number"
                                            required={true}
                                            onchange={(e) => handleInputChange(e, "actualQuantity")}
                                            value={formData.actualQuantity}

                                        />
                                    </div>
                                </div>
                                <div className='scanfont'>Conditions</div>
                                <div className='flex justify-content-between mt-2 mb-2'>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="condition" value="As Is Return" onChange={(e) => handleInputChange(e.value, "condition")} checked={formData.condition === 'As Is Return'} />
                                        <label htmlFor="condition1" className="ml-2">As Is Return</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="condition" value="New" onChange={(e) => handleInputChange(e.value, "condition")} checked={formData.condition === 'New'} />
                                        <label htmlFor="condition2" className="ml-2">New</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="condition" value="Loose" onChange={(e) => handleInputChange(e.value, "condition")} checked={formData.condition === 'Loose'} />
                                        <label htmlFor="condition3" className="ml-2">Loose/No Package</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="condition" value="Refurbished" onChange={(e) => handleInputChange(e.value, "condition")} checked={formData.condition === 'Refurbished'} />
                                        <label htmlFor="condition4" className="ml-2">Refurbished</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <RadioButton inputId="condition" value="Defective" onChange={(e) => handleInputChange(e.value, "condition")} checked={formData.condition === 'Defective'} />
                                        <label htmlFor="condition5" className="ml-2">Defective</label>
                                    </div>
                                </div>

                                <div className='flex justify-content-end'>
                                    <Button onClick={handleScannedItem}
                                        icon={"i-arrow-right"}
                                        disabled={formData.upc == "" || formData.actualQuantity == undefined || formData.actualQuantity === 0}
                                        style={{ height: "38px", width: "38px" }} />
                                </div>
                            </div>

                            <div className='col-12 boundary mt-2'>
                                <div className='col-5 scanfont'>Expected Qty - {formData.expectedQuantity} </div>
                                <div className='col-5 scanfont'>Recieved Qty - {formData.actualQuantity}</div>
                            </div>


                            {/* </div> */}


                            <div className='col-12 mt-2 timer'>
                                <div className='align-items-center flex gap-2'>
                                    <img src={timericon} style={{ width: "30px" }} />
                                    <div className='flex flex-column'>
                                        <label>Timer</label>
                                        <Countdown
                                            ref={countdownRef}
                                            autoStart={false}
                                            date={timer}
                                            renderer={timerRenderer}
                                            onComplete={handleTimer}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='col-6 scant '>

                            <p className="scanfont2" style={{ fontWeight: "400px", color: "#293752" }}>Scanned Items</p>
                            <div className='scanrmagrid' >
                                <VIVGrid
                                    gridCol={grid_columns}
                                    gridData={scannedItems}
                                />
                            </div>

                        </div>
                    </div>
                </div>

                <div className="page_footer flex justify-content-between gap-3 pt-3">
                    <div>
                        <Button style={{ backgroundColor: "transparent", color: "#0097FE", border:"0px" }} disabled={scannedItems.length == 0} label="Exit" onClick={handlePartialScan} />
                    </div>
                    <div>
                        <Button style={{ color: "black", backgroundColor: "#F1F6FC", borderColor: "#D6E4F7" }} label="Cancel" onClick={() => confirmDialog_Ref.current.showPopUp()} />
                        <Button style={{ marginLeft: "10px" }} label="Complete" disabled={scannedItems.length == 0} onClick={handleCompleteScan} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ScanRmaDetail