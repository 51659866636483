import React, { useState } from "react";
import { AppMenu } from "./AppMenus";
import { Button } from "primereact/button";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from 'primereact/dialog';
import { showMobileBar, hideMobileBar } from '../redux/sidebarSlice';

export const AppMobileSideBr = () => {
    const showSideBar = useSelector((state) => state.sidebarSlice.showMobileSideBar);
    const dispatch = useDispatch();

    const headerContent = (
        <div className="sidebr_header justify-content-between">
            <div className="flex align-items-center gap-2">
                <img src="/images/logo_sm.svg" />
                <p>Scan2Receive</p>
            </div>
            <Button className="mobile_sidebarCloseBtn" icon={'pi pi-times'} onClick={() => {dispatch(hideMobileBar())}} />

        </div>
    );

    return (
        <Dialog header={''} className="sidebar_dialog" visible={showSideBar} position={"left"} style={{ width: '90vw', height: '100vh' }}
            draggable={false}
            closeOnEscape={true}
            resizable={false}>
            {headerContent}
            <AppMenu />
        </Dialog>

    );
};
