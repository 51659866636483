import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import VIVGrid from "../../../../shared/VIV-Grid";
import { Calendar } from "primereact/calendar";
import './style.scss';

const ImportRMA = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        showPopUp() {
            setVisible(true);
        },
    }));

    const grid_columns = [
        {
            field: "rma_date",
            header: "Date",
            islink: false,
            statusfeild: false,
            widthClass: 'w-7rem',
            visible: true,
        },
        {
            field: "rma_no",
            header: "RMA",
            islink: false,
            widthClass: 'w-7rem',
            statusfeild: false,
            visible: true,
        },
        {
            field: "rma_from",
            header: "From",
            islink: false,
            widthClass: 'w-7rem',
            statusfeild: false,
            visible: true,
        },
        {
            field: "sender_email",
            header: "Sender’s Email",
            islink: false,
            statusfeild: false,
            visible: true,
        }];

    const grid_data = [
        {
            id: 1,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 2,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 3,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 4,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 5,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 6,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 7,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 8,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
        {
            id: 9,
            rma_date: "02-May-23",
            rma_no: "80134938",
            rma_from: "Walmart",
            sender_email: "abc@gmail.com",
        },
    ];

    const footerContent = (
        <div className="align-items-center flex justify-content-between">
            <h6 className="primary-txt-color cursor-pointer">Refresh Records</h6>
            <Button label="Import RMA's" onClick={() => setVisible(false)} />
        </div>

    );

    return (
        <>
            <Dialog visible={visible} draggable={false} className="importRMA_popup"
                style={{ width: "65vw" }} onHide={() => setVisible(false)} footer={footerContent}
            >
                <div className="grid">
                    <div className="col-12 text-center pb-0">
                        <h4>{props.title}</h4>
                    </div>

                    <div className="col-12 flex gap-3 justify-content-end pt-0 pb-0">
                        <Calendar
                            placeholder="From"
                            className="mt-2 inputCalender"
                            dateFormat="mm/dd/yy"
                            icon="i-calender"
                        />
                        <Calendar
                            placeholder="To"
                            className="mt-2 inputCalender"
                            dateFormat="mm/dd/yy"
                            icon="i-calender"
                        />
                    </div>

                    <div className="col-12 pb-0 pt-0 import_RMAGrid">
                        <VIVGrid
                            paginated={false}
                            gridCol={grid_columns}
                            selection={true}
                            gridData={grid_data}
                        />
                        <h6>Total Records: 5</h6>
                    </div>
                </div>
            </Dialog>
        </>
    )
});

export default ImportRMA;