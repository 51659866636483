import React, { useRef, useState, useEffect } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import "../assets/layout/_notification.scss";
import { Badge } from "primereact/badge";
import GridTabs from "../shared/GridTabs";
import { v4 as uuidv4 } from 'uuid';

const Notification = () => {
  const [read, setRead] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const parentOp = useRef(null);

  const confirm1 = () => {
    confirmDialog({
      message: `Sales Request 23702 has been created by User XYZ.`,
      header: "Sales Request Created",
      acceptLabel: "Ok",
      rejectLabel: "Cancel",
      className: "notification-dialog",
    });
  };
  const gridTabs = [{ label: "All" }, { label: "Unread" }];
  const dataNew = [
    {
      name: "John Doe",
      image: "/images/notification-fonts/dd.png",
      para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. In delectus, veritatis,nesciunt veniam odio voluptates modi ullam deserunt",
    },
    {
      name: "Luck",
      image: "/images/notification-fonts/lu.png",
      para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. In delectus, veritatis,nesciunt veniam odio voluptates modi ullam deserunt",
    },
    {
      name: "John Doe",
      image: "/images/notification-fonts/dd.png",
      para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. In delectus, veritatis,nesciunt veniam odio voluptates modi ullam deserunt",
    },
  ];
  const dataEarly = [
    {
      name: "John Doe",
      image: "/images/notification-fonts/dd.png",
      para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. In delectus, veritatis,nesciunt veniam odio voluptates modi ullam deserunt",
    },
    {
      name: "Luck",
      image: "/images/notification-fonts/lu.png",
      para: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. In delectus, veritatis,nesciunt veniam odio voluptates modi ullam deserunt",
    },
  ];

  const handleMarkRead = () => {
    setRead(true);
    //console.log("working");
  };

  const showOverlay = (e) => {
    parentOp.current.show(e);
  };

  const handleTabChange = (data) => {
    if (data == 1) {
      setShowUnread(true);
    } else if (data == 0 || null) {
      setShowUnread(false);
    }
  };

  return (
    <>
      <div className="card flex justify-content-center ">
        <div
          className="flex align-items-center"
          style={{ minWidth: "35px" }}
          onClick={showOverlay}
        >
          <i className="i-bell p-overlay-badge" style={{ fontSize: "2rem" }}>
            <Badge value="2"></Badge>
          </i>
        </div>
        <OverlayPanel
          className="notification-panel"
          ref={parentOp}
          showCloseIcon
        >
          <ConfirmDialog className="dialog-box" />
          <div>
            <h6>Notifications</h6>
            <div className="flex justify-content-between align-items-center">
              <GridTabs
                tabs={gridTabs}
                onTabChange={handleTabChange}
                activeIndex={0}
              />
              <div className="read-msgs w-full text-right">
                <i className="i-double-chk mr-2 font" />
                <a href="#" onClick={handleMarkRead}>
                  Mark all as read
                </a>
              </div>
            </div>
            <div className="mt-2 ">
              <p className="mb-2 noti-sub-heading">New</p>
              {dataNew.map((item) => {
                return (
                  <div
                    onClick={confirm1}
                    key={uuidv4()}
                    className={
                      !read
                        ? "mt-2 flex justify-content-between  align-items-center noti-new"
                        : "mt-2 flex justify-content-between  align-items-center noti-early"
                    }
                  >
                    <img className="noti-icon" src={item.image} />
                    <div className="noti-text">
                      <p>{item.name}</p>
                      <div>
                        <span>
                          <small>5h ago -</small> {item.para}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {!showUnread && (
              <div className="mt-2 mb-2 ">
                <p className="mb-2 noti-sub-heading">Earlier</p>
                {dataEarly.map((item) => {
                  return (
                    <div className="mt-2 flex justify-content-between  align-items-center noti-early" key={uuidv4()}>
                      <img className="noti-icon" src={item.image} />
                      <div className="noti-text">
                        <p>{item.name}</p>
                        <div>
                          <span>
                            <small>5h ago -</small> {item.para}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className=" text-center footer">
              <a href="#">View All</a>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </>
  );
};

export default Notification;
