import React, { useRef, useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import PageHeader from "../../../shared/PageHeader";
import ExportPopup from "../../../shared/ExportPopup";
import VIVGrid from "../../../shared/VIV-Grid";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import GridFilter from "../../../shared/GridFilter";
import useDebounce from "../../../_util/UseDebounce";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../shared/VivitechToast";
import LocationDialog from "./components/LocationDialog";

const InventorybyItem = () => {
  const isMounted = useRef(false);
  const toast_Ref = useRef(null);
  const initialMount = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [headerItems, setHeaderItems] = useState([
    {
      type: 'export',
      icon: 'i-export',
      containerClass: 'cursor-pointer flex align-items-center gap-2',
    },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [filterString, setFilterString] = useState("");
  const [gridData, setGridData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsFrom, setRecordsFrom] = useState(0);
  const [recordsTo, setRecordsTo] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogGridData, setDialogGridData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  useDebounce(() => { initialMount.current ? initialMount.current = false : LoadGridData(); }, [searchString], 1500);

  const handleLocationClick = (rowData) => {
    console.log('Location clicked', rowData.location);
    setSelectedLocation(rowData.location);
    setDialogVisible(true);
  };

  const LoadGridData = async () => {
    dispatch(showLoader());

    let URL = `Stock/GetInventoryByItems?pageNumber=${currentPage}&pageSize=${pageSize}&search=${searchString}`;

    if (filterString !== "") {
      URL = URL + "&" + filterString;
    }
    const resp = await httpClient.get(URL).catch((error) => {
      dispatch(hideLoader());
    });

    if (resp?.data.isSuccess) {
      setTotalRecords(resp?.data.page_TotalRecords);
      setRecordsFrom(resp?.data.page_From);
      setRecordsTo(resp?.data.page_To);
      setGridData(resp?.data.details);
    }
    dispatch(hideLoader());
  };

  const hideDialog = () => {
    setDialogVisible(false);
  };

  const grid_columns = [
    { field: 'itemName', header: 'Item Name', islink: true, statusfeild: false, visible: true },
    { field: 'upc', header: 'UPC', islink: false, statusfeild: false, visible: true },
    { field: 'modelNo', header: 'Model No', islink: false, statusfeild: false, visible: true },
    { field: 'incomingQuantity', header: 'Incoming Qty', islink: false, statusfeild: false, visible: true },
    { field: 'totalQuantity', header: 'Total Qty', islink: false, statusfeild: false, visible: true },
    { field: 'location', header: 'Location', islink: false, isIconColumn: true, icon: 'i-location', statusfeild: false, visible: true },
  ];

  const filterData = [
    {
      label: "UPC",
      filter_key: "upcSearch",
      feild: "input",
      value: "",
    },
    {
      label: "Model #",
      filter_key: "modelNoSearch",
      feild: "input",
      value: "",
    },
    {
      label: "Status",
      filter_key: "statusSearch",
      feild: "multiselect",
      value: "",
      dataforselect: [
        { value: "active", viewValue: "Active" },
        { value: "inactive", viewValue: "In-Active" },
      ],
    },
  ];

  const handlePageChnage = (evnt) => {
    setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
    setpageSize(evnt.rows);
  };

  const handleGridAction = (e) => {
    console.log(e);
    setDialogVisible(true);
    setDialogGridData(e.recordId);
  }

  useEffect(() => {
    LoadGridData();
  },[]);

  useEffect(() => {
    if (isMounted.current) {
      LoadGridData();
    } else {
      isMounted.current = true;
    }
}, [currentPage, pageSize, filterString]);


  return (
    <div className="page_content pb-0">

      <LocationDialog visible={dialogVisible} onHide={hideDialog} gridData={dialogGridData} />
      <PageHeader PageTitle={'Inventory By Item'} HeaderItems={headerItems} />

      <div className="mt-4 bg_content">
        <div className="flex gap-3 align-items-center justify-content-end">

          <span className="p-input-icon-left">
            <i className="i-search" />
            <InputText placeholder="Search" value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
              }} />
          </span>

          <Button label="Filter" icon="i-filter"
            onClick={() => setShowFilter(true)}
            className="bg-white justify-content-center clr-b" text raised />
        </div>

        <VIVGrid
          gridActions={[]}
          paginated={true}
          gridCol={grid_columns}
          selection={true}
          gridData={gridData}
          TotalRecords={totalRecords}
          recordfrom={recordsFrom}
          recordto={recordsTo}
          centerAlign={true}
          onLineAction={handleGridAction}
          onPageChange={handlePageChnage}
          baseURL={"/setup/item/detail/"}
        />
      </div>
    </div>
  );
}

export default InventorybyItem;
