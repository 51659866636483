import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../../../../shared/PageHeader";
import { Button } from "primereact/button";
import FormInput from "../../../../shared/Form-Input";
import VIV_Dropdown from "../../../../shared/Form-Select";
import { InputSwitch } from "primereact/inputswitch";
import InputDropdown from "../../../../shared/Input-Dropdown";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/mainSlice";
import httpClient from "../../../../_util/api";
import VivToast from "../../../../shared/VivitechToast";
import { useNavigate, useParams } from "react-router-dom";

const LocationSetupForm = () => {
  const isMounted = useRef(false);
  const { ZoneID } = useParams();
  const { id } = useParams();
  const { zoneTypeID } = useParams();
  const toast_Ref = useRef(null);
  const [units, setUnits] = useState([]);
  const [formData, setFormData] = useState({
    locationName: "",
    locationCode: "",
    aisle: "",
    rack: "",
    shelf: "",
    zone: "",
    zoneID: ZoneID,
    zoneType: zoneTypeID,
    length: null,
    height: null,
    width: null,
    pickable: true,
    unitHeightID: null,
    unitLengthID: null,
    unitWidthID: null,
    status: "Active"
  });

  const isFormComplete = () => {

    return (
      formData.locationName &&
      formData.locationCode &&
      formData.aisle &&
      formData.rack &&
      formData.shelf &&
      formData.zoneType &&
      formData.pickable &&
      formData.length &&
      formData.height &&
      formData.width
    );
  };


  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePageHeaderAction = (data) => { };

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleDDChange = (field, data) => {
    setFormData({ ...formData, [field]: data });
  }

  const loadLoactionDetail = async () => {
    dispatch(showLoader());
    let apiResp = await httpClient.get(`Location/GetLocationByID?id=${id}`).catch((error) => { dispatch(hideLoader()); });

    if (apiResp?.status == 200) {
      setFormData(apiResp?.data.data);
      dispatch(hideLoader());
    }
  }

  const LoadUnits = async () => {
    let resp = await httpClient.get(`Unit/GetAllUnits`).catch((error) => {});

    if(resp?.status == 200){
      setUnits(resp?.data.data);
    }
  }

  useEffect(() => {
    if (isMounted.current) {
      if (id) {
        loadLoactionDetail();
      }
    } else {
      isMounted.current = true;
    }
  }, [units]);

  useEffect(() => {
    LoadUnits();
  }, []);

  const handleSubmit = async (e) => {

    e.preventDefault();
    dispatch(showLoader());

    if (id) {
      let apiResp = await httpClient.put(`Location/UpdateLocation`, formData).catch((error) => {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "error", error?.response.data.message, "location not updated", "i-notify");
      });

      if (apiResp?.status == 200) {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "success", "Location updated successfully", "", "i-chk-circle");

        setTimeout(() => {
          navigate(`/setup/warehouse/Location/list/${ZoneID}`);
        }, 1000);
      }
    } else {

      let apiResp = await httpClient.post('Location/CreateLocation', formData).catch((error) => {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "error", error?.response.data.message, "location not created", "i-notify");
      });;

      if (apiResp?.status == 200) {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "success", "Location Created successfully", "", "i-chk-circle");

        setTimeout(() => {
          navigate(`/setup/warehouse/Location/list/${ZoneID}`);
        }, 1000);
      }
    }
  };

  return (
    <>
      <VivToast ref={toast_Ref} />
      <form onSubmit={handleSubmit} className="page_content pb-0">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={id ? "Update Location" : "Create Location"}
          showBackButton={true}
          backButtonRoute={`/setup/warehouse/location/list/${ZoneID}`}
          HeaderItems={[]}
        />

        <div className="default-content" style={{ overflow: "auto" }}>
          <div className="location_tabs_content bg-white mt-3">
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-4">
                <FormInput
                  title={"Location Name"}
                  value={formData.locationName}
                  onchange={(e) => handleInputChange("locationName", e)}
                />
              </div>
              <div className="col-4">
                <FormInput
                  title={"Location Code"}
                  value={formData.locationCode}
                  onchange={(e) => handleInputChange("locationCode", e)}
                />
              </div>
              <div className="col-4">
                <FormInput
                  title={"Aisle"}
                  value={formData.aisle}
                  onchange={(e) => handleInputChange("aisle", e)}
                />
              </div>
            </div>
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-4">
                <FormInput
                  title={"Rack"}
                  value={formData.rack}
                  onchange={(e) => handleInputChange("rack", e)}
                />
              </div>
              <div className="col-4">
                <FormInput
                  title={"Shelf"}
                  value={formData.shelf}
                  onchange={(e) => handleInputChange("shelf", e)}
                />
              </div>
            </div>
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-4">
                <FormInput
                  title={"Default Type"}
                  value={formData.zoneType}
                  onchange={(e) => handleInputChange("zoneType", e)}
                  isDisabled={true}
                />
              </div>
              <div className="col-4">
                <VIV_Dropdown
                  title={"Pickable"}
                  selectedOption={formData.pickable}
                  options={[
                    { name: "Yes", id: true },
                    { name: "No", id: false },

                  ]}

                  onSelect={(e) => handleDDChange("pickable", e)}
                />
              </div>
            </div>
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-4">
                <div className="flex flex-column gap-2">
                  <label>Is Active</label>
                  <InputSwitch
                    value={formData.status}
                    checked={formData.status == "Active" ? true : false}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        ["status"]: e.value ? "Active" : "Inactive",
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="location_tabs_content bg-white mt-3">
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-5">
                <InputDropdown
                  title={"Length"}
                  value={formData.length}
                  placeholder={"0.00"}
                  inputtype={"number"}
                  inputValue={formData.length}
                  options={units}
                  optionLabel={"unitType"}
                  selectedOption={formData.unitLengthID}
                  onSelect={(e) => { handleInputChange("unitLengthID", e);  }}
                  onInputChange={(e) => { handleInputChange("length", e) }}

                />
              </div>
              <div className="col-5">
                <InputDropdown
                  title={"Height"}
                  value={formData.height}
                  inputtype={"number"}
                  placeholder={"0.00"}
                  inputValue={formData.height}
                  options={units}
                  optionLabel={"unitType"}
                  selectedOption={formData.unitHeightID}
                  onSelect={(e) => { handleInputChange("unitHeightID", e) }}
                  onInputChange={(e) => { handleInputChange("height", e) }}
                />
              </div>
            </div>
            <div className="grid pl-4 pr-4 mt-2">
              <div className="col-5">
                <InputDropdown
                  title={"Width"}
                  value={formData.width}
                  inputtype={"number"}
                  placeholder={"0.00"}
                  inputValue={formData.width}
                  options={units}
                  optionLabel={"unitType"}
                  selectedOption={formData.unitWidthID}
                  onSelect={(e) => { handleInputChange("unitWidthID", e)  }}
                  onInputChange={(e) => { handleInputChange("width", e) }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button
            label={"Cancel"}
            className="btn-secondary"
            onClick={() => navigate(`/setup/warehouse/location/list/${ZoneID}`)}
          />
          <Button
            label={id ? "Update" : "Create"}
            disabled={!isFormComplete()}
          />
        </div>
      </form>
    </>
  );
};

export default LocationSetupForm;
