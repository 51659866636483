import React, { useState, useEffect } from "react";
import { PanelMenu } from "primereact/panelmenu";
import { useLocation } from "react-router-dom";
import MenuLink from "../router/menuLink";
import { useSelector, useDispatch } from "react-redux";

export const AppMenu = () => {
  const location = useLocation();

  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  let menu = [];

  // let menu = [
  //   {
  //     label: "Receiving",
  //     icon: "i-inbound",
  //     className: location.pathname.includes("receiving") ? "activepanel" : "",
  //     expanded: location.pathname.includes("receiving") ? true : false,
  //     items: [
  //       {
  //         template: (
  //           <MenuLink
  //             to="/receiving/upload-rma/list"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Upload RMA"
  //           />
  //         ),
  //       },
  //       {
  //         template: (
  //           <MenuLink
  //             to="/receiving/scan-rma"
  //             classes="p-menuitem-link"
  //             icon="i-scanner"
  //             text="Scan RMA"
  //           />
  //         ),
  //       },
  //       {
  //         template: (
  //           <MenuLink
  //             to="/receiving/received-rma"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Received RMA"
  //           />
  //         ),
  //       },
  //       {
  //         template: (
  //           <MenuLink
  //             to="/receiving/pack-list"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Pack List"
  //           />
  //         ),
  //       },
  //       {
  //         template: (
  //           <MenuLink
  //             to="/receiving/create-manifest"
  //             classes="p-menuitem-link"
  //             icon="i-scanner"
  //             text="Create Manifest"
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     label: "Inventory",
  //     icon: "i-inventory",
  //     template: (
  //       <MenuLink
  //         to="inventory"
  //         classes="p-panelmenu-header-link"
  //         icon="i-inventory"
  //         text="Inventory"
  //       />
  //     ),
  //   },
  //   {
  //     label: "Orders",
  //     icon: "i-basket",
  //     template: (
  //       <MenuLink
  //         to="orders"
  //         classes="p-panelmenu-header-link"
  //         icon="i-basket"
  //         text="Orders"
  //       />
  //     ),
  //   },
  //   {
  //     label: "Outbound",
  //     icon: "i-outbound",
  //     template: (
  //       <MenuLink
  //         to="outbound"
  //         classes="p-panelmenu-header-link"
  //         icon="i-outbound"
  //         text="Outbound"
  //       />
  //     ),
  //   },
  //   {
  //     label: "Setup",
  //     icon: "i-setup",
  //     className: location.pathname.includes("setup") ? "activepanel" : "",
  //     expanded: location.pathname.includes("setup") ? true : false,
  //     items: [
  //       {
  //         label: "Item Master",
  //         template: (
  //           <MenuLink
  //             to="/setup/item/list"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Item Master"
  //           />
  //         ),
  //       },
  //       {
  //         label: "Warehouse",
  //         template: (
  //           <MenuLink
  //             to="/setup/warehouse/list"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Warehouse"
  //           />
  //         ),
  //       },
  //       {
  //         label: "Schedulers",
  //         template: (
  //           <MenuLink
  //             to="/setup/sites"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Schedulers"
  //           />
  //         ),
  //       },
  //       {
  //         label: "Roles",
  //         template: (
  //           <MenuLink
  //             to="/setup/role/list"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Roles"
  //           />
  //         ),
  //       },
  //       {
  //         label: "Users",
  //         template: (
  //           <MenuLink
  //             to="/setup/user/list"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Users"
  //           />
  //         ),
  //       },
  //       {
  //         label: "Carriers",
  //         template: (
  //           <MenuLink
  //             to="/setup/carrier/list"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Carriers"
  //           />
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     label: "Reports",
  //     icon: "i-barchart",
  //     className: location.pathname.includes("settings") ? "activepanel" : "",
  //     expanded: location.pathname.includes("settings") ? true : false,
  //     items: [
  //       {
  //         label: "Sites",
  //         template: (
  //           <MenuLink
  //             to="/settings/sites"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Sites"
  //           />
  //         ),
  //       },
  //       {
  //         label: "Schedulers",
  //         template: (
  //           <MenuLink
  //             to="/settings/sites"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Schedulers"
  //           />
  //         ),
  //       },
  //       {
  //         label: "Users",
  //         template: (
  //           <MenuLink
  //             to="/settings/sites"
  //             classes="p-menuitem-link"
  //             icon=""
  //             text="Users"
  //           />
  //         ),
  //       },
  //     ],
  //   },
  // ];

  const handleLinkUrl = (funcName) => {
    let url = "";
    switch (funcName) {
      case "Upload RMA":
        url = "/receiving/upload-rma/list";
        break;
      case "Scan RMA":
        url = "/receiving/scan-rma";
        break;
      case "Received RMA":
        url = "/receiving/received-rma/list";
        break;
      case "Pack List":
        url = "/receiving/pack-list";
        break;
      case "Create Manifest":
        url = "/receiving/create-manifest";
        break;
      case "Inventory By Item":
        url = "/inventory/inventory-by-item";
        break;
      case "Inventory By Pallet":
        url = "/receiving/scan-rma";
        break;
      case "Stock Transfer":
        url = "/receiving/scan-rma";
        break;
      case "Sales Request":
        url = "/order/sale-request/list";
        break;
      case "Sales Order":
        url = "/order/sale-order/list";
        break;
      case "Fulfillment":
        url = "/receiving/upload-rma/list";
        break;
      case "Pick":
        url = "/receiving/scan-rma";
        break;
      case "Pack":
        url = "/receiving/upload-rma/list";
        break;
      case "Ship":
        url = "/receiving/scan-rma";
        break;
      case "Item Master":
        url = "/setup/item/list";
        break;
      case "Warehouse":
        url = "/setup/warehouse/list";
        break;
      case "Roles":
        url = "/setup/role/list";
        break;
      case "Users":
        url = "/setup/user/list";
        break;
      case "Carriers":
        url = "/setup/carrier/list";
        break;
      default:
        url = "";
        break;
    }

    return url;
  }

  userRoles.forEach(m => {
    if (m.moduleName == "Dashboard" && m.isViewable) {
      if (m.functions.length && m.functions[0].actions[0].value) {
        menu.push({
          label: "Dashboard",
          icon: "i-dashboard",
          template: (
            <MenuLink
              to="/"
              classes="p-panelmenu-header-link"
              icon="i-dashboard"
              text="Dashboard"
            />
          ),
        })
      }
    }
    else if (m.moduleName == "Receiving" && m.isViewable) {

      let actMenu = [{
        label: "Receiving",
        icon: "i-inbound",
        className: location.pathname.includes("receiving") ? "activepanel" : "",
        expanded: location.pathname.includes("receiving") ? true : false,
        items: []
      }];

      m.functions.forEach((f, ind) => {
        if (f.actions[0].value) {
          actMenu[0].items.push({
            template: (
              <MenuLink
                key={ind}
                to={handleLinkUrl(f.functionName)}
                classes="p-menuitem-link"
                icon={(f.functionName == "Scan RMA" || f.funcName == "Create Manifest") ? "i-scanner" : ""}
                text={f.functionName}
              />
            ),
          })
        }
      });

      menu.push(actMenu[0]);
    }
    else if (m.moduleName == "Inventory" && m.isViewable) {
      if (m.functions.length && m.functions[0].actions[0].value) {
        
        let actMenu = [{
          label: "Inventory",
          icon: "i-inventory",
          className: location.pathname.includes("inventory") ? "activepanel" : "",
          expanded: location.pathname.includes("inventory") ? true : false,
          items: []
        }];
  
        m.functions.forEach((f, ind) => {
          if (f.actions[0].value) {
            actMenu[0].items.push({
              template: (
                <MenuLink
                  key={ind}
                  to={handleLinkUrl(f.functionName)}
                  classes="p-menuitem-link"
                  icon={(f.functionName == "Scan RMA" || f.funcName == "Create Manifest") ? "i-scanner" : ""}
                  text={f.functionName}
                />
              ),
            })
          }
        });
  
        menu.push(actMenu[0]);
        
      }
    }
    else if (m.moduleName == "Orders" && m.isViewable) {

      let actMenu = [{
        label: "Orders",
        icon: "i-basket",
        className: location.pathname.includes("order") ? "activepanel" : "",
        expanded: location.pathname.includes("order") ? true : false,
        items: []
      }];

      m.functions.forEach((f, ind) => {
        if (f.actions[0].value) {
          actMenu[0].items.push({
            template: (
              <MenuLink
                key={ind}
                to={handleLinkUrl(f.functionName)}
                classes="p-menuitem-link"
                icon={(f.functionName == "Scan RMA" || f.funcName == "Create Manifest") ? "i-scanner" : ""}
                text={f.functionName}
              />
            ),
          })
        }
      });

      menu.push(actMenu[0]);
    }
    else if (m.moduleName == "Outbound" && m.isViewable) {

      let actMenu = [{
        label: "Outbound",
        icon: "i-outbound",
        className: location.pathname.includes("outbound") ? "activepanel" : "",
        expanded: location.pathname.includes("outbound") ? true : false,
        items: []
      }];

      m.functions.forEach((f, ind) => {
        if (f.actions[0].value) {
          actMenu[0].items.push({
            template: (
              <MenuLink
                key={ind}
                to={handleLinkUrl(f.functionName)}
                classes="p-menuitem-link"
                icon={(f.functionName == "Scan RMA" || f.funcName == "Create Manifest") ? "i-scanner" : ""}
                text={f.functionName}
              />
            ),
          })
        }
      });

      menu.push(actMenu[0]);
    }
    else if (m.moduleName == "Setup" && m.isViewable) {

      let actMenu = [{
        label: "Setup",
        icon: "i-setup",
        className: location.pathname.includes("setup") ? "activepanel" : "",
        expanded: location.pathname.includes("setup") ? true : false,
        items: []
      }];

      m.functions.forEach((f, ind) => {
        if (f.actions[0].value) {
          actMenu[0].items.push({
            template: (
              <MenuLink
                key={ind}
                to={handleLinkUrl(f.functionName)}
                classes="p-menuitem-link"
                icon={(f.functionName == "Scan RMA" || f.funcName == "Create Manifest") ? "i-scanner" : ""}
                text={f.functionName}
              />
            ),
          })
        }
      });

      menu.push(actMenu[0]);
    }
    else if (m.moduleName == "Reports" && m.isViewable) {

      let actMenu = [{
        label: "Reports",
        icon: "i-barchart",
        className: location.pathname.includes("report") ? "activepanel" : "",
        expanded: location.pathname.includes("report") ? true : false,
        items: []
      }];

      m.functions.forEach((f, ind) => {
        if (f.actions[0].value) {
          actMenu[0].items.push({
            template: (
              <MenuLink
                key={ind}
                to={handleLinkUrl(f.functionName)}
                classes="p-menuitem-link"
                icon={(f.functionName == "Scan RMA" || f.funcName == "Create Manifest") ? "i-scanner" : ""}
                text={f.functionName}
              />
            ),
          })
        }
      });

      menu.push(actMenu[0]);
    }
  });


  return <PanelMenu model={menu} className="w-full viv_sideMenus" />;
};
