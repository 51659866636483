import React, { useState } from "react";
import { useNavigate } from "react-router";
import PageHeader from "../../../shared/PageHeader";
import { Button } from "primereact/button";
import FormInput from "../../../shared/Form-Input";
import GridTabs from "../../../shared/GridTabs";
import { Checkbox } from "primereact/checkbox";
import './style.scss';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from "react-redux";

const UserRoleDetail = () => {

    const userRoles = useSelector((state) => state.mainSlice.userRoles);
    const navigate = useNavigate();
    const [activeTab, setactiveTab] = useState(0);
    const [showButton, setShowButton] = useState(false);
    const [gridData, setGridData] = useState([
        {
            function: "RMA Dashboard",
            view: false,
            edit: false,
            delete: false,
            create: false,
            execution: false,
        }
    ]);

    const handleTabChange = (data) => {
        setactiveTab(data);
        if (data == 0) {
            let tabData = [
                {
                    function: "RMA Dashboard",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }
            ];
            setGridData([...tabData]);
        }
        else if (data == 1) {
            let tabData = [
                {
                    function: "Upload RMA",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Scan RMA",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Received RMA",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }, {
                    function: "Pack List",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Create Manifest",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }
            ];
            setGridData([...tabData]);
        }
        else if (data == 2) {
            let tabData = [
                {
                    function: "Inventory By Item",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Inventory By Pallet",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Stock Transfer a Pallet",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }, {
                    function: "Stock Transfer an Item",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }
            ];

            setGridData([...tabData]);
        }
        else if (data == 3) {
            let tabData = [
                {
                    function: "Sales Request",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Sales Order",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }
            ];

            setGridData([...tabData]);
        }
        else if (data == 4) {
            let tabData = [
                {
                    function: "Fulfillment",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Pick",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Pack",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }, {
                    function: "Ship",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }
            ];

            setGridData([...tabData]);
        }
        else if (data == 4) {
            let tabData = [
                {
                    function: "Item Master",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Warehouse",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Roles",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }, {
                    function: "Users",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }, {
                    function: "Carriers",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }
            ];

            setGridData([...tabData]);
        }
        else if (data == 5) {
            let tabData = [
                {
                    function: "RMA Reports",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                },
                {
                    function: "Item Reports",
                    view: false,
                    edit: false,
                    delete: false,
                    create: false,
                    execution: false,
                }
            ];

            setGridData([...tabData]);
        }

    };

    const handleInputChange = (e) => {
        //console.log(e);
    };

    const gridTabs = [
        { label: "Dashboard" },
        { label: "Receiving" },
        { label: "Inventory" },
        { label: "Orders" },
        { label: "Outbound" },
        { label: "Setup" },
        { label: "Orders" },
    ];


    const grid_columns = [
        { field: 'function', header: 'Function', islink: false, editable: false, feildtype: 'text', visible: true },
        { field: 'view', header: 'View', islink: false, editable: true, feildtype: 'checkbox', visible: true },
        { field: 'edit', header: 'Edit', islink: false, editable: true, feildtype: 'checkbox', visible: true },
        { field: 'delete', header: 'Delete', islink: false, editable: true, feildtype: 'checkbox', visible: true },
        { field: 'create', header: 'Create', islink: false, editable: true, feildtype: 'checkbox', visible: true },
        { field: 'execution', header: 'Execution', islink: false, editable: true, feildtype: 'checkbox', visible: true }
    ];

    const headerItems = [
        {
            type: "editButton",
            icon: "",
            containerClass: "",
            classes: "",
        },
    ];


    const handlecheckboxSelection = (value, ind, feild) => {
        let currentData = gridData;
        currentData[ind][feild] = value;
        setGridData([...currentData]);
    }

    const handleSelectAll = () => {
        let currentData = gridData;
        currentData.forEach(item => {
            item.view = true;
            item.edit = true;
            item.delete = true;
            item.create = true;
            item.execution = true;
        });
        setGridData([...currentData]);
    }

    const handleUnSelectAll = () => {
        let currentData = gridData;
        currentData.forEach(item => {
            item.view = false;
            item.edit = false;
            item.delete = false;
            item.create = false;
            item.execution = false;
        });
        setGridData([...currentData]);
    }

    const handlePageHeaderAction = (data) => {
        if (data.actiontype == "editButton") {
            setShowButton(true);
        }
    };


    return (
        <>
            <div className="page_content">
                <PageHeader showBackButton={true}
                    onPageActoin={handlePageHeaderAction}
                    PageTitle={"Create Roles"}
                    HeaderItems={headerItems}
                />

                <div className="roles_tabs_content bg-white mt-3">
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <FormInput
                                title={"Role Name"}
                                isDisabled={!showButton}
                                onchange={(e) => handleInputChange(e)
                                }
                            />
                        </div>
                    </div>

                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-11">
                            <h6>Modules</h6>
                            <GridTabs tabs={gridTabs} onTabChange={handleTabChange} activeIndex={activeTab} />
                        </div>
                    </div>
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-12">

                            <div className="p-datatable p-component p-datatable-responsive-scroll roles_grid">
                                <div className="p-datatable-wrapper">
                                    <table className="p-datatable-table p-datatable-scrollable-table">
                                        <thead className="p-datatable-thead">
                                            <tr>
                                                {
                                                    grid_columns.map((col, ind) => {
                                                        return (
                                                            <th key={uuidv4()}>
                                                                <div className="p-column-header-content">
                                                                    <span className="p-column-title">{col.header}</span>
                                                                </div>
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="p-datatable-tbody">
                                            {
                                                gridData.map((item, ind) => {
                                                    return (
                                                        <tr key={uuidv4()}>
                                                            <td>{item.function}</td>
                                                            <td><Checkbox checked={item.view} disabled={!showButton} onChange={(e) => handlecheckboxSelection(e.target.checked, ind, 'view')} /></td>
                                                            <td><Checkbox checked={item.edit} disabled={!showButton} onChange={(e) => handlecheckboxSelection(e.target.checked, ind, 'edit')} /></td>
                                                            <td><Checkbox checked={item.delete} disabled={!showButton} onChange={(e) => handlecheckboxSelection(e.target.checked, ind, 'delete')} /></td>
                                                            <td><Checkbox checked={item.create} disabled={!showButton} onChange={(e) => handlecheckboxSelection(e.target.checked, ind, 'create')} /></td>
                                                            <td><Checkbox checked={item.execution} disabled={!showButton} onChange={(e) => handlecheckboxSelection(e.target.checked, ind, 'execution')} /></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex gap-3 justify-content-end mt-4">
                                <Button label={"Select All"} disabled={!showButton} className="btn-secondary" onClick={handleSelectAll} />
                                <Button label={"UnSelect All"} disabled={!showButton} className="btn-secondary" onClick={handleUnSelectAll} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page_footer flex gap-3 justify-content-end pt-3">
                    {showButton && <><Button label={"Cancel"} className="btn-secondary" onClick={() => navigate('/setup/role/list')} />
                        <Button label={"Update"} onClick={() => navigate('/setup/role/list')} /></>}
                </div>
            </div>
        </>
    );
};

export default UserRoleDetail;
