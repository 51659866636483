import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebarSlice',
  initialState: {
    value: 'full-bar',
    showMobileSideBar: false
  },
  reducers: {
    toggleSideBar: (state) => {
      state.value = state.value == "full-bar" ? "half-bar" : "full-bar";
      //state.value = action.payload;
    },
    shrinkSideBar: (state) => {
      state.value = "half-bar";
      //state.value = action.payload;
    },
    showMobileBar: (state) => {
      state.showMobileSideBar = true;
    },
    hideMobileBar: (state) => {
      state.showMobileSideBar = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleSideBar, shrinkSideBar, showMobileBar, hideMobileBar } = sidebarSlice.actions;

export default sidebarSlice.reducer;