import React, { useEffect, useState, useRef } from "react";
import PageHeader from "../../../shared/PageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import httpClient from "../../../_util/api";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import { useSearchParams, useParams } from "react-router-dom";
import VivToast from "../../../shared/VivitechToast";

const UserDetailForm = () => {
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const [headerItems, setheaderItems] = useState([{
    type: "editButton",
    icon: "",
    containerClass: "",
    classes: "",
    disabled: false
  }]);
  const [userDetail, setUserDetail] = useState([]);
  const dispatch = useDispatch();
  const toast_Ref = useRef(null);
  let navigate = useNavigate();
  const { id } = useParams();

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if (module) {
      if (module.functions[3].actions[1].value) {
        currentHeaderItems[0].disabled = false;
      }
      else { currentHeaderItems[0].disabled = true; }

      setheaderItems(currentHeaderItems);

    }

  }

  useEffect(() => {
    LoadRolebase_Actions();
    LoadUser();
  }, []);

  const LoadUser = async () => {
    dispatch(showLoader());
    let apiResp = await httpClient
      .get(`UserManager/GetUser?userId=${id}`)
      .catch((error) => {
        dispatch(hideLoader());
      });
    if (apiResp?.data.isSuccess) {
      setUserDetail(apiResp?.data.data);
      dispatch(hideLoader());
    } else {
      dispatch(hideLoader());
    }
  };

  const handlePageHeaderAction = (data) => {
    if (data.actiontype == "editButton") {
      navigate(`/setup/user/form/${id}`);
    }
  }


  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"User Detail"}
          HeaderItems={headerItems}
          showBackButton={true}
          backButtonRoute="/setup/user/list"
        />

        <div className="user_tabs_content bg-white mt-3">
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4">
              <div className="flex flex-column gap-2">
                <p>User Name</p>
                <p>{userDetail ? userDetail.username : "-"}</p>
              </div>
            </div>
            <div className="col-4 ">
              <div className="flex flex-column gap-2">
                <p>Email</p>
                <p>{userDetail ? userDetail.email : "-"}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="flex flex-column gap-2">
                <p>Roles</p>

                {userDetail.roles && userDetail.roles.length > 1 ? (
                  userDetail.roles.split(",").map((role, i) => {
                    return <p key={i}>{role}</p>;
                  })
                ) : (
                  <p>{userDetail.roles ? userDetail.roles : "-"}</p>
                )}
              </div>
            </div>
            <div className="col-4 mt-5">
              <div className="flex flex-column gap-2">
                <p>Send Invitation Link</p>

                <p>
                  {userDetail.isInvitationLinkSent
                    ? userDetail.isInvitationLinkSent
                      ? "Yes"
                      : "No"
                    : "-"}
                </p>
              </div>
            </div>
            <div className="col-4 mt-5">
              <div className="flex flex-column gap-2">
                <p>Status</p>
                <p>{userDetail.status ? userDetail.status : "-"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailForm;
