import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../../../shared/PageHeader";
import { Button } from "primereact/button";
import FormInput from "../../../shared/Form-Input";
import { useNavigate, useParams } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import VivToast from "../../../shared/VivitechToast";
import './style.scss';

const CarrierForm = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const toast_Ref = useRef(null);
  const { id } = useParams();

  const [multiSelect, setMultiSelect] = useState([]);
  const [truckTypes, setTruckTypes] = useState([]);
  const [status, setStatus] = useState(true);
  const [formData, setFormData] = useState({
    carrierCode: "",
    carrierName: "",
    contactName: "",
    carrierEmail: "",
    phoneNumber: "",
    shippingAccNo: "",
    truckLoadId: "",
    status: "Active",
  });

  const handlePageHeaderAction = (data) => { };

  const LoadTruckTypes = async () => {
    let apiResp = await httpClient.get("Carrier/GetTruckloadType").catch((error) => {
      toast_Ref.current.showMessage(
        "error", error?.response.data.message, "", "i-notify");
      setTruckTypes([]);
    });

    if (apiResp.status == 200) {
      setTruckTypes(apiResp?.data.truckloads);
    }
  }

  const handleInputChange = (data, field) => {
    setFormData((prevFormData) => ({ ...prevFormData, [field]: data }));
  };

  const handleTruckLoadChange = (e) => {
    setMultiSelect(e.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(showLoader());

    if (id) {
      formData.status = status ? "Active" : "Inactive";
      formData.truckLoadId = multiSelect.join(',');
      let apiResp = await httpClient.put("Carrier/EditCarrier", formData).catch((error) => {
        dispatch(hideLoader()); toast_Ref.current.showMessage(
          "error", error?.response.data.message, "", "i-notify");
      });

      if (apiResp?.status == 200) {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "success", "Carrier updated successfully", "", "i-chk-circle");

        setTimeout(() => {
          navigate("/setup/carrier/list");
        }, 1000);
      }
    }
    else {
      formData.status = status ? "Active" : "Inactive";
      formData.truckLoadId = multiSelect.join(',');
      let apiResp = await httpClient.post("Carrier/AddCarrier", formData).catch((error) => {
        dispatch(hideLoader()); toast_Ref.current.showMessage(
          "error", error?.response.data.message, "", "i-notify");
      });
      if (apiResp?.status == 200) {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "success", "Carrier created successfully", "", "i-chk-circle");

        setTimeout(() => {
          navigate("/setup/carrier/list");
        }, 1000);
      }
    }
  }

  const fetchCarrierDetail = async () => {
    dispatch(showLoader());
    let apiResp = await httpClient.get(`Carrier/GetCarrierById?Id=${id}`).catch((error) => {
        dispatch(hideLoader()); 
        toast_Ref.current.showMessage(
          "error", error?.response.data.message, "", "i-notify");
      });
  
      if(apiResp?.status == 200){

        setMultiSelect(apiResp?.data?.data?.truckLoadId.split(',').map(Number));
        setFormData(apiResp?.data.data);
        setStatus(apiResp?.data.data.status == "Active" ? true : false);
        dispatch(hideLoader());
      }
}

  const onLoad = async () => {
    await LoadTruckTypes();
    fetchCarrierDetail();
  }

  useEffect(() => {
    
    if (id) {
      onLoad();
      //fetchwarehouseDetail();
    }
    else{
      LoadTruckTypes();
    }
  }, []);

  return (
    <>
      <VivToast ref={toast_Ref} />
      <form onSubmit={handleSubmit} className="page_content pb-0">
        <PageHeader onPageActoin={handlePageHeaderAction} PageTitle={"Create Carrier"} showBackButton={true}
          backButtonRoute="/setup/carrier/list" HeaderItems={[]} />

        <div className="user_tabs_content bg-white mt-3">
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4">
              <FormInput
                title={"Carrier Name"}
                value={formData.carrierName}
                onchange={(e) => handleInputChange(e, "carrierName")}
              />
            </div>
            <div className="col-4">
              <FormInput
                title={"Carrier Code"}
                value={formData.carrierCode}
                onchange={(e) => handleInputChange(e, "carrierCode")}
              />
            </div>
          </div>
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4">
              <FormInput
                title={"Contact Name"}
                value={formData.contactName}
                onchange={(e) => handleInputChange(e, "contactName")}
              />
            </div>
            <div className="col-4">
              <FormInput
                title={"Carrier Email"}
                inputtype={"email"}
                value={formData.carrierEmail}
                onchange={(e) => handleInputChange(e, "carrierEmail")}
              />
            </div>
          </div>
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4">
              <FormInput
                title={"Phone Number"}
                inputtype={"phone"}
                value={formData.phoneNumber}
                onchange={(e) => handleInputChange(e, "phoneNumber")}
              />
            </div>
            <div className="col-4">
              <FormInput
                title={"Shipping Account Number"}
                value={formData.shippingAccNo}
                onchange={(e) => handleInputChange(e, "shippingAccNo")}
              />
            </div>
          </div>
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4 flex flex-column gap-2">
              <label htmlFor="username">Truck Load</label>
              <MultiSelect
                selectAll={true}
                showSelectAll={true}
                selectAllLabel="All"
                value={multiSelect}
                onChange={handleTruckLoadChange}
                options={truckTypes}
                panelClassName="DD_TruckLoad"
                optionLabel="truckloadType"
                optionValue="truckloadId"
                display="chip"
                placeholder="Select"
                maxSelectedLabels={3}
                className="w-full "
              />
            </div>
            <div className="col-4">
              <div className="flex flex-column gap-3">
                <label>Is Active</label>
                <InputSwitch value={status} checked={status} onChange={(e) => setStatus(e.value)} />
              </div>
            </div>
          </div>
        </div>
        <div className="page_footer flex gap-3 justify-content-end pt-3">

          <Button type="button" label={"Cancel"} className="btn-secondary"
            onClick={() => navigate("/setup/carrier/list")} />

          <Button label={id ? "Update" : "Create"}
            disabled={formData.carrierName == "" || formData.contactName == "" || formData.carrierEmail == "" || (multiSelect.length == 0 || multiSelect == null)} />

        </div>
      </form>
    </>
  );
};

export default CarrierForm;
