import React, { useState, useRef } from "react";
import scan from "../../../../assets/images/Barcode-img.svg"
import { Button } from "primereact/button";
import PageHeader from "../../../../shared/PageHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import FormInputGroup from "../../../../shared/Form-InputGroup";
import httpClient from "../../../../_util/api";
import { showLoader, hideLoader } from "../../../../redux/mainSlice";
import VivToast from "../../../../shared/VivitechToast";

const PalletScan = (props) => {
    const toast_Ref = useRef(null);
    const [packageID, setPackageID] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const handleSearchClick = async () => {
        dispatch(showLoader());
        let resp = await httpClient.get(`RMA/GetPackageInfo?PackageID=${packageID}`).catch((error) => { 
            dispatch(hideLoader()); 
            toast_Ref.current.showMessage(
                "error", error?.response?.data.message, "", "i-notify");
        });

        if(resp?.status == 200){
            dispatch(hideLoader());
            props?.changeMode(resp?.data);
            console.log(resp?.data);
        }

        //navigate('/receiving/ScanRmaDetail');
        //dispatch(toggleSideBar());
    }
    
    return (
        <>
        <VivToast ref={toast_Ref} />
        <div className="page_content pb-0">
            <PageHeader
                onPageActoin={() => { }}
                PageTitle={"Create Manifest"}
                HeaderItems={[]}
            />
            <div className="Scancontainer mt-4" >
                <img src={scan} />
                <div className="flex gap-4 align-items-center">
                    <Button label="Create New Carton" onClick={() => props.changeMode("CreateCarton")} />
                    <span>OR</span>
                    <Button label="Create New Pallet" onClick={() => props.changeMode("CreatePallet")} />
                </div>
                <FormInputGroup inputtype={"text"} icon={"i-scanner"} containerClass={"w-21rem"}
                placeholder={"Scan or Enter Existing  Pallet ID"} 
                value={packageID}
                onchange={(e) => { setPackageID(e) }}/>
                
            </div>
        </div>
        </>
    )
}

export default PalletScan;