import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import './style.scss';

const VivitechConfirmDialog = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        showPopUp() {
            setVisible(true);
        },
    }));

    const handleConfirm = () => {
        if(props?.accept){
            props?.accept();
        }
        setVisible(false);
    }

    const handleReject = () => {
        if(props?.reject){
            props?.reject();
        }
        setVisible(false);
    }

    const footerContent = (
        <div>
            <Button label={props?.rejectLabel ? props.rejectLabel : 'No'} onClick={handleReject} className="p-button-text" />
            <Button label={props?.acceptLabel ? props.acceptLabel : 'Yes'} onClick={handleConfirm} autoFocus />
        </div>
    );

    const dialogContent = (
        <div className="align-items-center col-12 flex flex-column justify-content-center">
            {Array.isArray(props?.message) ?
                props?.message.map((msg) => {
                    return(
                        <p className="p-confirm-dialog-message">{msg}</p>
                    )
                })
                : <p className="p-confirm-dialog-message">props?.message</p>
            }
        </div>
        
    )

    return (
        <Dialog
            header={props.title}
            visible={visible}
            draggable={false}
            className="vivconfirm_popup"
            style={{ width: "35vw" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
        >
            <div className="grid">
                <div className="col-12 text-center p-confirm-dialog-icon">
                    <i className={props?.icon ? props?.icon : 'pi pi-exclamation-triangle'}></i>
                </div>

                {dialogContent}

            </div>
        </Dialog>
    );
});

export default VivitechConfirmDialog;