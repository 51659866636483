import React, { useRef, useState, useEffect } from "react";
import { Route, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../../shared/PageHeader";
import VIVGrid from "../../../../shared/VIV-Grid";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import GridFilter from "../../../../shared/GridFilter";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/mainSlice";
import httpClient from "../../../../_util/api";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../../shared/VivitechToast";
import useDebounce from "../../../../_util/UseDebounce";

const LocationList = () => {
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const initialMount = useRef(true);
  const toast_Ref = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [filterString, setFilterString] = useState("");
  const [gridData, setGridData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsFrom, setRecordsFrom] = useState(0);
  const [recordsTo, setRecordsTo] = useState(0);
  const [warehouseID, setWarehouseID] = useState(0);
  const { ZoneID } = useParams();
  const [zonetypename, SetZoneTypeName] = useState("a");
  const [zoneTypeID, setZoneTypeID] = useState();

  useDebounce(() => { initialMount.current ? initialMount.current = false : LoadGridData(); }, [searchString], 1500);

  const [headerItems, setheaderItems] = useState([{
    type: "button",
    icon: "",
    containerClass: "",
    classes: "",
    title: "Create Location",
    disabled: false
  }]);
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const [gridActionsList, setgridActionsList] = useState([{ label: "Edit", icon: "i-edit", disabled: false }, { label: "Delete", icon: "i-bin", disabled: false }]);

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let gridActionList = gridActionsList;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if (module) {
      if (module.functions[1].actions[3].value) {
        currentHeaderItems[0].disabled = false;
      }
      else { currentHeaderItems[0].disabled = true; }

      //Edit Permission
      if (module.functions[1].actions[1].value) {
        gridActionList[0].disabled = false;
      }
      else {
        gridActionList[0].disabled = true;
      }

      //Delete Permission
      if (module.functions[1].actions[2].value) {
        gridActionList[1].disabled = false;
      }
      else {
        gridActionList[1].disabled = true;
      }

      setgridActionsList(gridActionList);
      setheaderItems(currentHeaderItems);
    }

  }

  useEffect(() => {
    LoadRolebase_Actions();
  }, []);

  const grid_columns = [
    {
      field: "locationName",
      header: "Location ",
      islink: true,
      statusfeild: false,
      visible: true,
    },
    {
      field: "locationCode",
      header: "location Code",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "zoneType",
      header: "Type",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "zoneID",
      header: "Zone/Sub Area",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "pickable",
      header: "Pickable",
      islink: false,
      statusfeild: false,
      visible: true,
    },

    {
      field: "status",
      header: "Status",
      islink: false,
      statusfeild: false,
      visible: true,
    },
    {
      field: "action",
      header: "Actions",
      islink: false,
      statusfeild: false,
      visible: true,
    },
  ];


  const filterData = [
    {
      label: "Location",
      filter_key: "locationName",
      feild: "input",
      value: ""
    },
    {
      label: "Location Code",
      filter_key: "locationCode",
      feild: "input",
      value: ""
    },
    {
      label: "Type",
      filter_key: "pickable",
      feild: "input",
      value: ""
    },
    {
      label: "Sub Area",
      feild: "input",
      value: ""
    },
    {
      label: "Pickable",
      filter_key: "pickable",
      feild: "input",
      value: ""
    },
    {
      label: "Status",
      filter_key: "status",
      feild: "statusID",
      value: ""
    },


  ];

  const LoadGridData = async () => {
    dispatch(showLoader());

    let URL = `Location/GetAllLocations?&pageNumber=${currentPage}&pageSize=${pageSize}&searchTerm=${searchString}&ZoneID=${ZoneID}`;

    if (filterString !== "") {
      URL = URL + "&" + filterString;
    }
    const resp = await httpClient.get(URL).catch((error) => {
      dispatch(hideLoader());
    });

    if (resp?.data.isSuccess) {
      setTotalRecords(resp?.data.page_TotalRecords);
      setRecordsFrom(resp?.data.page_From);
      setRecordsTo(resp?.data.page_To);
      setGridData(resp?.data.details);
      // const zoneTypes = resp?.data.details.map(item => item.zoneType);

      //   console.log("ZoneType Data:", zoneTypes);
      //   const zoneData = zoneTypes.map(obj => JSON.stringify(obj));

      //   console.log(zoneData);
      // setZoneType(zoneTypes);
    }

    dispatch(hideLoader());

  };

  const LoadZoneDetail = async () => {
    dispatch(showLoader());
    let resp = await httpClient.get(`Zone/GetZoneByID?id=${ZoneID}`).catch((error) => { });
    const zoneTypesResp = await httpClient.get('Zone/GetAllZonesTypes').catch((error) => { });

    if (resp && resp?.status === 200) {
      setWarehouseID(resp?.data.data.warehouseID);

      if (zoneTypesResp && zoneTypesResp.status === 200) {
        const foundZoneType = zoneTypesResp.data.zoneTypes.find(zoneType => zoneType.id === resp?.data.data.zoneTypeID);

        if (foundZoneType) {
          SetZoneTypeName(foundZoneType.zoneTypeName);
        }
      }
    }
  };

  const onload = async () => {
    await LoadZoneDetail();
    LoadGridData();
  }

  useEffect(() => {
    onload();
  }, [currentPage, pageSize, filterString]);

  const handlePageHeaderAction = (data) => {
    if (data.actiontype == "button") {
      navigate(`/setup/warehouse/location/form/${ZoneID}/${zonetypename}`);
    }
  };

  const handleFilters = (data) => {
    setFilterString(data);
  };

  const handlePageChnage = (evnt) => {
    setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
    setpageSize(evnt.rows);
  };

  const deleteLocation = async (id) => {
    dispatch(showLoader());
    let resp = await httpClient.delete(`Location/RemoveLocation?id=${id}`).catch((error) => {
      //console.log(error); 
      dispatch(hideLoader());
      toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
    });

    if (resp?.status == 200) {
      dispatch(hideLoader());
      toast_Ref.current.showMessage("success", "Location deleted successfully", "", "i-chk-circle");
      LoadGridData();
    }
  }

  const handleGridAction = (e) => {
    if (e?.action.toLowerCase() == "delete") {
      confirmDialog({
        message: 'Are you sure you want to delete this location?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        draggable: false,
        accept: () => { deleteLocation(e.recordId) },
        reject: () => { }
      });
    }
    else {
      navigate(`/setup/warehouse/location/form/${ZoneID}/${zonetypename}/${e?.recordId}`);
    }
  }

  return (
    <>
      <ConfirmDialog />
      <VivToast ref={toast_Ref} />
      <GridFilter
        data={filterData}
        visible={showFilter}
        visibleState={setShowFilter}
        onApplyFilter={handleFilters}
      />
      <div className="page_content pb-0">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Locations"}
          showBackButton={true}
          backButtonRoute={`/setup/warehouse/zone/list/${warehouseID}`}
          HeaderItems={headerItems}
        />

        <div className="mt-4">
          <div className="flex gap-3 align-items-center justify-content-end">
            <span className="p-input-icon-left">
              <i className="i-search" />
              <InputText placeholder="Search"
                value={searchString} onChange={(e) => {
                  setSearchString(e.target.value);
                }} />
            </span>

            <Button
              label="Filter"
              icon="i-filter"
              className="bg-white justify-content-center clr-b"
              onClick={() => setShowFilter(true)}
              text
              raised
            />
          </div>

          <VIVGrid
            gridActions={gridActionsList}
            paginated={true}
            gridCol={grid_columns}
            selection={true}
            gridData={gridData}
            TotalRecords={totalRecords}
            recordfrom={recordsFrom}
            recordto={recordsTo}
            centerAlign={false}
            onPageChange={handlePageChnage}
            onLineAction={handleGridAction}
            baseURL={"/setup/warehouse/location/detail/"}
          />
        </div>
      </div>
    </>
  );
};

export default LocationList;
