import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "../assets/layout/layout.scss";
import { AppRoutes } from "../router/routes";
import { MainLayout } from "../core/MainLayout";
import PageLoader from "./FullPageLoader";

const App = () => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("user-token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  useEffect(() => {
    checkUserToken();
  }, [location]);

  return (
    <React.Fragment>
      <PageLoader />
      {!isLoggedIn && <AppRoutes />}
      {isLoggedIn && <MainLayout />}
    </React.Fragment>
  );
};

export default App;
