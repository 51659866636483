import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../../../shared/PageHeader";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import VivToast from "../../../shared/VivitechToast";
import { useDispatch, useSelector } from "react-redux";

const CarrierDetailForm = () => {
  const [carrieDetail, setCarrieDetail] = useState(null);
  const [headerItems, setheaderItems] = useState([{
    type: "editButton",
      icon: "",
      containerClass: "",
      classes: "",
    disabled: false
  }]);
  const toast_Ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const userRoles = useSelector((state) => state.mainSlice.userRoles);

  


  const handlePageHeaderAction = (data) => {
    if (data.actiontype == "editButton") {
      navigate(`/setup/carrier/form/${id}`);
    }
  };

  const fetchCarrierDetail = async () => {
    dispatch(showLoader());
    let apiResp = await httpClient.get(`Carrier/GetCarrierById?Id=${id}`).catch((error) => {
      dispatch(hideLoader());
      toast_Ref.current.showMessage(
        "error", error?.response.data.message, "", "i-notify");
    });

    if (apiResp?.status == 200) {
      setCarrieDetail(apiResp?.data.data);
      dispatch(hideLoader());
    }
  }

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if(module)
    {
      if(module.functions[4].actions[1].value)
      {
        currentHeaderItems[0].disabled = false;
      }
      else{ currentHeaderItems[0].disabled = true; }
      
      setheaderItems(currentHeaderItems);
    }
    
  }

  useEffect(() => {
    LoadRolebase_Actions();
    fetchCarrierDetail();
  }, []);

  

  return (
    <>
    <VivToast ref={toast_Ref} />
      <div className="page_content pb-0">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Carrier Detail"}
          HeaderItems={headerItems}
          showBackButton={true}
          backButtonRoute="/setup/carrier/list"
        />
        <div className="user_tabs_content bg-white mt-3">
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-3">
              <p className="detail-lable">Carrier Name</p>
              <p className="detail-text">{carrieDetail?.carrierName ? carrieDetail.carrierName : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Carrier Code</p>
              <p className="detail-text">{carrieDetail?.carrierCode ? carrieDetail.carrierCode : '-'}</p>
            </div>
            <div className="col-6">
              <p className="detail-lable">Contact Name</p>
              <p className="detail-text">{carrieDetail?.contactName ? carrieDetail.contactName : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Carrier Email</p>
              <p className="detail-text">{carrieDetail?.carrierEmail ? carrieDetail.carrierEmail : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Phone Number</p>
              <p className="detail-text">{carrieDetail?.phoneNumber ? carrieDetail.phoneNumber : '-'}</p>
            </div>
            <div className="col-6">
              <p className="detail-lable">Shipping Account Number</p>
              <p className="detail-text">{carrieDetail?.shippingAccNo ? carrieDetail.shippingAccNo : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Truck Load</p>
              <p className="detail-text">{carrieDetail?.truckLoadType ? carrieDetail.truckLoadType : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Address 1</p>
              <p className="detail-text">{carrieDetail?.address1 ? carrieDetail.address1 : '-'}</p>
            </div>
            <div className="col-6">
              <p className="detail-lable">Status</p>
              <p className="detail-text">{carrieDetail?.status ? carrieDetail.status : '-'}</p>
            </div>
          </div>
        </div>

        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button
            label={"Close"}
            className="btn-secondary"
            onClick={() => navigate("/setup/carrier/list")}
          />
          {/* <Button
            label={"Update"}
            onClick={() => navigate(`/setup/carrier/form/${id}`)}
          /> */}
        </div>
      </div>
    </>
  );
};

export default CarrierDetailForm;
