import React, { useState, useEffect, useRef } from "react";
import "./login.scss"
import LoginSection from "../../shared/loginSection";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { useParams, useNavigate } from 'react-router-dom';
import httpClient from "../../_util/api";
import VivToast from "../../shared/VivitechToast";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../redux/mainSlice";

const Signup = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [userEmail, setUserEmail] = useState("");
    const toast_Ref = useRef(null);
    const [formData, setFormData] = useState({ fullName: "", email: "", newPassword: "", cnfrmPassword: "" });
    let { id } = useParams();

    useEffect(() => {
        LoadUser();
    }, []);

    const LoadUser = async () => {
        let apiResp = await httpClient.get(`Account/GetUserEmail?id=${id}`).catch((error) => {
            toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
        });

        if (apiResp.status == 200) {
            setUserEmail(apiResp.data.email);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        dispatch(showLoader());
        e.preventDefault();
        formData.email = userEmail;

        let apiResp = await httpClient.post(`Account/CreatePassword`, formData).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error.response.data.message, "", "i-notify");
        });

        if (apiResp?.status == 200) {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("success", "Account created successfully", "", "i-chk-circle");
            setTimeout(() => { navigate("/login"); }, 1000);
        }

    }

    const password_footer = (
        <>
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>At least one special character</li>
                <li>Minimum 8 characters</li>
            </ul>
        </>
    );

    return (
        <>
            <VivToast ref={toast_Ref} />
            <div className="grid mr-0">
                <div className="hidden md:block md:col-6 pb-0 ">
                    <LoginSection />
                </div>
                <form onSubmit={handleSubmit} className="col-12 md:col-6 pb-0 flex flex-column align-items-center justify-content-around login_form">
                    <div className="col-10 md:col-10 lg:col-8 mx-auto">
                        <div className="sm:hidden mt-4 text-center">
                            <img src='/images/mobile-logo.svg'></img>
                        </div>
                        <h1>Create an Account</h1>
                        <p>Hi {userEmail}, you’re one step away...</p>
                        <div className="flex flex-column gap-2 mt-4">
                            <label>Full Name <span className="text-danger">*</span></label>
                            <InputText placeholder="Enter Full Name" name="fullName"
                                value={formData.fullName} onChange={handleChange} />
                        </div>
                        <div className="flex flex-column gap-2 mt-4">
                            <label>Password <span className="text-danger">*</span></label>
                            <Password placeholder="Enter Password"
                                feedback={true}
                                value={formData.newPassword}
                                name="newPassword"
                                onChange={handleChange}
                                toggleMask
                                showIcon={<i className="i-eye-hide"></i>}
                                hideIcon={<i className="i-eye-show"></i>}
                                className="password-icon" promptLabel="Enter Password" footer={password_footer} />
                        </div>
                        <div className="flex flex-column gap-2 mt-4">
                            <label>Confirm Password <span className="text-danger">*</span></label>
                            <Password
                                placeholder="Enter Confirm Password"
                                feedback={false}
                                value={formData.cnfrmPassword}
                                name="cnfrmPassword"
                                onChange={handleChange}
                                toggleMask
                                showIcon={<i className="i-eye-hide"></i>}
                                hideIcon={<i className="i-eye-show"></i>}
                                className="password-icon" />
                            {(formData.cnfrmPassword !== "" && (formData.newPassword !== formData.cnfrmPassword)) && <span className="feild_errormsg">Password Not Matched</span>}
                        </div>
                        <Button
                            disabled={formData.fullName == "" || formData.newPassword == "" || (formData.newPassword !== formData.cnfrmPassword) || !formData.newPassword.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g)}
                            className="mt-4 w-full" label="Create Account" />
                    </div>
                    <div className="resp_footer">
                        <p>
                            © 2022 Vivitech Solutions, Inc.
                        </p>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Signup;