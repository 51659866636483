import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../shared/PageHeader";
import { Button } from "primereact/button";
import FormInput from "../../../shared/Form-Input";
import GridTabs from "../../../shared/GridTabs";
import { Checkbox } from "primereact/checkbox";
import "./style.scss";
import { v4 as uuidv4 } from "uuid";
import httpClient from "../../../_util/api";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import { Route, useNavigate, useParams } from "react-router-dom";
import VivToast from "../../../shared/VivitechToast";

let rolesData = {
  RoleName: "",
  Modules: [
    {
      "ModuleName": "Dashboard",
      "Functions": [
        {
          "FunctionName": "RMA Dashboard",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            }
          ]
        }
      ]
    },
    {
      "ModuleName": "Receiving",
      "Functions": [
        {
          "FunctionName": "Upload RMA",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Upload",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Scan RMA",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Upload",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Received RMA",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Upload",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Pack List",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Upload",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Create Manifest",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Upload",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        }
      ]
    },
    {
      "ModuleName": "Inventory",
      "Functions": [
        {
          "FunctionName": "Inventory By Item",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Inventory By Pallet",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Stock Transfer a Pallet",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Stock Transfer an Item",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        }
      ]
    },
    {
      "ModuleName": "Orders",
      "Functions": [
        {
          "FunctionName": "Sales Request",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Approve",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Sales Order",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Approve",
              "value": false
            }
          ]
        }
      ]
    },
    {
      "ModuleName": "Outbound",
      "Functions": [
        {
          "FunctionName": "Fulfillment",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Pick",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Pack",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Ship",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            },
            {
              "key": "Execute",
              "value": false
            }
          ]
        }
      ]
    },
    {
      "ModuleName": "Setup",
      "Functions": [
        {
          "FunctionName": "Item Master",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Warehouse",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Roles",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Users",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Carriers",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Edit",
              "value": false
            },
            {
              "key": "Delete",
              "value": false
            },
            {
              "key": "Create",
              "value": false
            }
          ]
        }
      ]
    },
    {
      "ModuleName": "Reports",
      "Functions": [
        {
          "FunctionName": "RMA Reports",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Generate",
              "value": false
            }
          ]
        },
        {
          "FunctionName": "Item Reports",
          "ActionString": null,
          "Actions": [
            {
              "key": "View",
              "value": false
            },
            {
              "key": "Generate",
              "value": false
            }
          ]
        }
      ]
    }
  ]
};

const UserRoleForm = () => {

  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast_Ref = useRef(null);
  const { id } = useParams();

  const [activeTab, setactiveTab] = useState(0);
  const [roleName, setRoleName] = useState();
  const [allowEditing, setAllowEditing] = useState(true);
  const [isEmptyPermissions, setIsEmptyPermissions] = useState(true);
  const [gridData, setGridData] = useState([
    {
      function: "RMA Dashboard",
      actions: [{ key: 'View', value: false }]
    },
  ]);

  const [gridColumns, setGridColumns] = useState([{
    field: "function",
    header: "Function",
    islink: false,
    editable: false,
    feildtype: "text",
    visible: true,
  },
  {
    field: "view",
    header: "View",
    islink: false,
    editable: true,
    feildtype: "checkbox",
    visible: true,
  }]);

  useEffect(() => {
    LoadRolebase_Actions();
    onLoad();
  }, []);


  const LoadRolebase_Actions = () => {
    let module = userRoles.find(r => r.moduleName == "Setup");
    if (module) {
      //Edit Permission
      if (module.functions[2].actions[1].value) {
        setAllowEditing(true);
      }
      else {
        setAllowEditing(false);
      }
      
    }

  }

  const ResetRolesCheckBoxes = () => {
    rolesData = {
      RoleName: "",
      Modules: [
        {
          "ModuleName": "Dashboard",
          "Functions": [
            {
              "FunctionName": "RMA Dashboard",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                }
              ]
            }
          ]
        },
        {
          "ModuleName": "Receiving",
          "Functions": [
            {
              "FunctionName": "Upload RMA",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Upload",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Scan RMA",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Upload",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Received RMA",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Upload",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Pack List",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Upload",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Create Manifest",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Upload",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            }
          ]
        },
        {
          "ModuleName": "Inventory",
          "Functions": [
            {
              "FunctionName": "Inventory By Item",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Inventory By Pallet",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Stock Transfer a Pallet",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Stock Transfer an Item",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            }
          ]
        },
        {
          "ModuleName": "Orders",
          "Functions": [
            {
              "FunctionName": "Sales Request",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Approve",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Sales Order",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Approve",
                  "value": false
                }
              ]
            }
          ]
        },
        {
          "ModuleName": "Outbound",
          "Functions": [
            {
              "FunctionName": "Fulfillment",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Pick",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Pack",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Ship",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                },
                {
                  "key": "Execute",
                  "value": false
                }
              ]
            }
          ]
        },
        {
          "ModuleName": "Setup",
          "Functions": [
            {
              "FunctionName": "Item Master",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Warehouse",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Roles",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Users",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Carriers",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Edit",
                  "value": false
                },
                {
                  "key": "Delete",
                  "value": false
                },
                {
                  "key": "Create",
                  "value": false
                }
              ]
            }
          ]
        },
        {
          "ModuleName": "Reports",
          "Functions": [
            {
              "FunctionName": "RMA Reports",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Generate",
                  "value": false
                }
              ]
            },
            {
              "FunctionName": "Item Reports",
              "ActionString": null,
              "Actions": [
                {
                  "key": "View",
                  "value": false
                },
                {
                  "key": "Generate",
                  "value": false
                }
              ]
            }
          ]
        }
      ]
    }
  }

  const onLoad = async () => {
    if (id) {
      dispatch(showLoader());
      let apiResp = await httpClient.get(`Role/GetRolePermissions?roleId=${id}`).catch((error) => {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "error", error?.response.data.message, "", "i-notify");
      });

      if (apiResp?.status == 200) {
        setRoleName(apiResp?.data.RoleName);

        let userRoleInfo = {
          RoleName: apiResp?.data.RoleName,
          Modules: apiResp?.data.Modules
        };
        rolesData = userRoleInfo;
        handleTabChange(0);
        dispatch(hideLoader());
      }
      IsEmptySubmit();
    }
    else {
      ResetRolesCheckBoxes();
    }
  }

  const handleTabChange = (data) => {
    setactiveTab(data);
    setColumns(data);
    set_GridData(data);
  };

  const handleInputChange = (e) => {
    //console.log(e);
  };

  const setColumns = (activeTabInd) => {
    if (activeTabInd == 0) {
      columnContent("Dashboard");
    }
    else if (activeTabInd == 1) {
      columnContent("Receiving");
    }
    else if (activeTabInd == 2) {
      columnContent("Inventory");
    }
    else if (activeTabInd == 3) {
      columnContent("Orders");
    }
    else if (activeTabInd == 4) {
      columnContent("Outbound");
    }
    else if (activeTabInd == 5) {
      columnContent("Setup");
    }
    else if (activeTabInd == 6) {
      columnContent("Reports");
    }
  }

  const columnContent = (moduleName) => {
    let moduleData = rolesData.Modules.find(x => x.ModuleName == moduleName);
    if (moduleData) {

      let columns = [{
        field: "function",
        header: "Function",
        islink: false,
        editable: false,
        feildtype: "text",
        visible: true,
      }];

      moduleData.Functions[0].Actions.forEach((act) => {
        columns.push({
          field: act.key,
          header: act.key,
          islink: false,
          editable: true,
          feildtype: "checkbox",
          visible: true,
        });
      });

      setGridColumns([...columns]);
    }
    else {
      setGridColumns([...[]]);
    }
  }

  const set_GridData = (activeTabInd) => {
    if (activeTabInd == 0) {
      let moduleData = rolesData.Modules.find(x => x.ModuleName == "Dashboard");
      if (moduleData) {
        let grid_data = [];

        moduleData.Functions.forEach((func) => {
          grid_data.push({
            function: func.FunctionName,
            actions: [
              { key: 'View', value: func.Actions.find(a => a.key == "View").value }
            ]
          });
        });
        setGridData([...grid_data]);
      }
    }
    else if (activeTabInd == 1) {
      let moduleData = rolesData.Modules.find(x => x.ModuleName == "Receiving");

      if (moduleData) {
        let grid_data = [];

        moduleData.Functions.forEach((func) => {
          grid_data.push({
            function: func.FunctionName,
            actions: [
              { key: 'View', value: func.Actions.find(a => a.key == "View").value },
              { key: 'Upload', value: func.Actions.find(a => a.key == "Upload").value },
              { key: 'Create', value: func.Actions.find(a => a.key == "Create").value },
              { key: 'Execute', value: func.Actions.find(a => a.key == "Execute").value },
            ]
          });
        });
        setGridData([...grid_data]);
      }
    }
    else if (activeTabInd == 2) {
      let moduleData = rolesData.Modules.find(x => x.ModuleName == "Inventory");

      if (moduleData) {
        let grid_data = [];

        moduleData.Functions.forEach((func) => {
          grid_data.push({
            function: func.FunctionName,
            actions: [
              { key: 'View', value: func.Actions.find(a => a.key == "View").value },
              { key: 'Create', value: func.Actions.find(a => a.key == "Create").value },
              { key: 'Execute', value: func.Actions.find(a => a.key == "Execute").value },
            ]
          });
        });
        setGridData([...grid_data]);
      }
    }
    else if (activeTabInd == 3) {
      let moduleData = rolesData.Modules.find(x => x.ModuleName == "Orders");

      if (moduleData) {
        let grid_data = [];

        moduleData.Functions.forEach((func) => {
          grid_data.push({
            function: func.FunctionName,
            actions: [
              { key: 'View', value: func.Actions.find(a => a.key == "View").value },
              { key: 'Create', value: func.Actions.find(a => a.key == "Create").value },
              { key: 'Approve', value: func.Actions.find(a => a.key == "Approve").value },
            ]
          });
        });
        setGridData([...grid_data]);
      }
    }
    else if (activeTabInd == 4) {
      let moduleData = rolesData.Modules.find(x => x.ModuleName == "Outbound");

      if (moduleData) {
        let grid_data = [];

        moduleData.Functions.forEach((func) => {
          grid_data.push({
            function: func.FunctionName,
            actions: [
              { key: 'View', value: func.Actions.find(a => a.key == "View").value },
              { key: 'Edit', value: func.Actions.find(a => a.key == "Edit").value },
              { key: 'Delete', value: func.Actions.find(a => a.key == "Delete").value },
              { key: 'Create', value: func.Actions.find(a => a.key == "Create").value },
              { key: 'Execute', value: func.Actions.find(a => a.key == "Execute").value },
            ]
          });
        });
        setGridData([...grid_data]);
      }
    }
    else if (activeTabInd == 5) {
      let moduleData = rolesData.Modules.find(x => x.ModuleName == "Setup");

      if (moduleData) {
        let grid_data = [];

        moduleData.Functions.forEach((func) => {
          grid_data.push({
            function: func.FunctionName,
            actions: [
              { key: 'View', value: func.Actions.find(a => a.key == "View").value },
              { key: 'Edit', value: func.Actions.find(a => a.key == "Edit").value },
              { key: 'Delete', value: func.Actions.find(a => a.key == "Delete").value },
              { key: 'Create', value: func.Actions.find(a => a.key == "Create").value },
            ]
          });
        });
        setGridData([...grid_data]);
      }
    }
    else if (activeTabInd == 6) {
      let moduleData = rolesData.Modules.find(x => x.ModuleName == "Reports");

      if (moduleData) {
        let grid_data = [];

        moduleData.Functions.forEach((func) => {
          grid_data.push({
            function: func.FunctionName,
            actions: [
              { key: 'View', value: func.Actions.find(a => a.key == "View").value },
              { key: 'Generate', value: func.Actions.find(a => a.key == "Generate").value },
            ]
          });
        });
        setGridData([...grid_data]);
      }
    }
  }

  const gridTabs = [
    { label: "Dashboard" },
    { label: "Receiving" },
    { label: "Inventory" },
    { label: "Orders" },
    { label: "Outbound" },
    { label: "Setup" },
    { label: "Reports" },
  ];

  const handlecheckboxSelection = (value, ind, functionName) => {
    rolesData.Modules.forEach((role) => {
      if (role.Functions.find(f => f.FunctionName == functionName)) {
        let functionIndex = role.Functions.findIndex(f => f.FunctionName == functionName);

        if (functionIndex !== -1) {
          role.Functions[functionIndex].Actions[ind].value = value;
        }
      }
    });

    let currentData = gridData;
    currentData[currentData.findIndex(x => x.function == functionName)].actions[ind].value = value;
    setGridData([...currentData]);
    IsEmptySubmit();
  };

  const handleSelectAll = () => {

    let module_name = (activeTab == 0) ? "Dashboard" : (activeTab == 1) ? "Receiving" : (activeTab == 2) ? "Inventory"
      : (activeTab == 3) ? "Orders" : (activeTab == 4) ? "Outbound" : (activeTab == 5) ? "Setup" : "Reports";

    let moduleData = rolesData.Modules.find(x => x.ModuleName == module_name);
    moduleData.Functions.forEach((func) => {
      func.Actions.forEach((act) => {
        act.value = true;
      });
    });

    let currentData = gridData;
    currentData.forEach((item) => {
      item.actions.forEach((a) => {
        a.value = true;
      });
    });
    setGridData([...currentData]);
    IsEmptySubmit();
  };

  const handleUnSelectAll = () => {

    let module_name = (activeTab == 0) ? "Dashboard" : (activeTab == 1) ? "Receiving" : (activeTab == 2) ? "Inventory"
      : (activeTab == 3) ? "Orders" : (activeTab == 4) ? "Outbound" : (activeTab == 5) ? "Setup" : "Reports";

    let moduleData = rolesData.Modules.find(x => x.ModuleName == module_name);
    moduleData.Functions.forEach((func) => {
      func.Actions.forEach((act) => {
        act.value = false;
      });
    });

    let currentData = gridData;
    currentData.forEach((item) => {
      item.actions.forEach((a) => {
        a.value = false;
      });
    });
    setGridData([...currentData]);
    IsEmptySubmit();
  };

  const IsEmptySubmit = () => {
    let result = true;

    for (let index = 0; index < rolesData.Modules.length; index++) {
      if (result) {
        for (let funcInd = 0; funcInd < rolesData.Modules[index].Functions.length; funcInd++) {
          let anyTrue = rolesData.Modules[index].Functions[funcInd].Actions.filter(x => x.value);
          if (anyTrue.length) {
            result = false;
            break;
          }
          else {
            result = true
          }

        }
      }
      else {
        break;
      }
    }

    setIsEmptyPermissions(result);

    return result;

  }

  const handleRoleSubmit = async () => {

    let emptySubmit = await IsEmptySubmit();

    if (!emptySubmit) {
      dispatch(showLoader());
      rolesData.RoleName = roleName;

      let resp = await httpClient.post(id ? "Role/UpdateRole" : "Role/AddRole", rolesData).catch((error) => { toast_Ref.current.showMessage("error", error.response.data.message, "", "i-notify"); dispatch(hideLoader()); });

      if (resp?.status == 200) {
        toast_Ref.current.showMessage("success", id ? "Role updated successfully" : "Role added successfully", "", "i-chk-circle");
        dispatch(hideLoader());
        setTimeout(() => {
          navigate("/setup/role/list");
        }, 1500);
      }
    }
    else {
      toast_Ref.current.showMessage("error", "Role can not be created without any permission", "", "i-notify");
    }
  }

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content roles_view pb-0">
        <PageHeader
          showBackButton={true}
          onPageActoin={(e) => { }}
          PageTitle={id ? "Update Role" : "Create Role"}
          backButtonRoute="/setup/role/list"
          HeaderItems={[]}
        />

        <div className="roles_tabs_content bg-white mt-3">
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-4">
              <FormInput
                title={"Role Name"}
                value={roleName}
                onchange={(e) => setRoleName(e)}
              />
            </div>
          </div>

          <div className="grid pl-4 pr-4">
            <div className="ml-2">
              <h6>Modules</h6>
              <GridTabs
                tabs={gridTabs}
                onTabChange={handleTabChange}
                activeIndex={activeTab}
              />
            </div>
          </div>
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-12">
              <div className="p-datatable p-component p-datatable-responsive-scroll roles_grid">
                <div className="p-datatable-wrapper">
                  <table className="p-datatable-table p-datatable-scrollable-table">
                    <thead className="p-datatable-thead">
                      <tr>
                        {gridColumns.map((col, ind) => {
                          return (
                            <th key={uuidv4()} className={ind == 0 ? "w-20rem" : ""}>
                              <div className={ind == 0 ? "p-column-header-content" : "p-column-header-content"}>
                                <span className="p-column-title">
                                  {col.header}
                                </span>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="p-datatable-tbody">
                      {gridData.map((item, ind) => {
                        return (
                          <tr key={uuidv4()}>
                            <td>{item.function}</td>
                            {
                              item.actions.map((action, ind) => {
                                return (
                                  <td className="pl-4">
                                    <Checkbox
                                      checked={action.value}
                                      onChange={(e) =>
                                        handlecheckboxSelection(
                                          e.target.checked,
                                          ind,
                                          item.function
                                        )
                                      }
                                    />
                                  </td>
                                )
                              })
                            }
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex gap-3 justify-content-end mt-4">
                <Button
                  label={"Select All"}
                  className="btn-secondary"
                  onClick={handleSelectAll}
                />
                <Button
                  label={"Unselect All"}
                  className="btn-secondary"
                  onClick={handleUnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button type="button" label={`Cancel`} className="btn-secondary" onClick={() => navigate("/setup/role/list")} />
          <Button type="button" disabled={((allowEditing == true) ? false : true)  || isEmptyPermissions ? true : false || roleName ? false : true} onClick={handleRoleSubmit} label={id ? "Update" : "Create"} />
        </div>
      </div>
    </>
  );
};

export default UserRoleForm;
