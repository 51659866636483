import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Dialog } from "primereact/dialog";
import FormInputGroup from "../../../../shared/Form-InputGroup";

const ChangeLocationDialog = forwardRef((props, ref) => {

    const [visible, setVisible] = useState(false);
    const [inputvalue, setInputvalue] = useState(null);

    useImperativeHandle(ref, () => ({
        showPopUp() {
            setInputvalue("");
            setVisible(true);
        },
    }));

    const footerContent = (
        <div className="align-items-center flex justify-content-between">
            
        </div>

    );

    return (
        <>
            <Dialog visible={visible} header={props.title} draggable={false} className="changeLocation_popup"
                style={{ width: "35vw" }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className="grid mt-3 mb-3">
                    <div className="col-12">
                        <FormInputGroup inputtype={"text"} icon={"i-scanner"}
                            title={"Scan Bin Location"}
                            placeholder={"Scan Bin Location"}
                            value={inputvalue}
                            onchange={(e) => { setInputvalue(e);  }} 
                            onEnterKeyPress={() => { props?.onLoactionScan(inputvalue); setVisible(false) }}/>
                    </div>
                </div>
            </Dialog>
        </>
    )
});

export default ChangeLocationDialog;