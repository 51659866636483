import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../../shared/PageHeader";
import { Button } from "primereact/button";
import FormInput from "../../shared/Form-Input";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Password } from "primereact/password";
import { useNavigate } from "react-router-dom";
import { showLoader, hideLoader, setUserDetail } from "../../redux/mainSlice";
import httpClient from "../../_util/api";
import VivToast from "../../shared/VivitechToast";
import { useSelector, useDispatch } from "react-redux";
import { setUserImage } from "../../redux/mainSlice";
import "./style.scss";

const UserProfile = () => {
  let navigate = useNavigate();
  let fileInput = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.mainSlice.userdetail);
  const toast_Ref = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [userImg, setUserImg] = useState(null);
  const [dispalyUser, setDisplayUser] = useState(null);
  // const [userDetail, setUserDetail] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [passData, setPassData] = useState({
    Id: userInfo?.id,
    CurrentPassword: "",
    Password: "",
    NewPassword: "",
  });

  const fetchMyAPI = async () => {
    dispatch(showLoader());
    const resp = await httpClient.get(`UserManager/GetUserProfile/${userInfo.email}`).catch((error) => {
        dispatch(hideLoader());
      });
    dispatch(hideLoader());
    
    if (resp?.data.isSuccess) {
      dispatch(setUserDetail(resp?.data?.userProfile));
      setDisplayUser(resp?.data?.userProfile.profileAddress ? resp?.data?.userProfile.profileAddress: null);
      dispatch(hideLoader());
    } else {
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    if (userInfo.email) {
      fetchMyAPI();
    }
  }, []);

  const handleDeleteImg = () => {
    confirm1();
  };

  const handleCancelPassword = () => {
    setHidePassword(true);
    setPassData({ CurrentPassword: "", Password: "", NewPassword: "" });
  };
  const accept = () => {
    setDisplayUser(null);
    setUserImg(null);
    setIsDelete(true);
    setShowInput(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPassData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const reject = () => {
    //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };
  const confirm1 = () => {
    confirmDialog({
      message: "Are you sure you want to delete your picture?",
      header: "",
      icon: "pi i-warning",
      acceptLabel: "Confirm",
      rejectLabel: "Cancel",
      className: "notification-dialog",
      tagKey: "loin_confrm",
      draggable: false,
      accept,
      reject,
    });
  };

  const handleChangePassword = async () => {
    if (
      !passData.CurrentPassword ||
      !passData.Password ||
      !passData.NewPassword
    ) {
      return;
    } else {
      dispatch(showLoader());
      const resp = await httpClient
        .put("UserManager/ChangePassword", {
          CurrentPassword: passData.CurrentPassword,
          NewPassword: passData.NewPassword,
          Id: passData.Id,
        })
        .then((resp) => {
          if (resp?.data.isSuccess) {
            setHidePassword(true);
            toast_Ref.current.showMessage(
              "success",
              resp.data.message,
              "",
              "i-chk-circle"
            );
            dispatch(hideLoader());
          }
        })
        .catch((error) => {
          toast_Ref.current.showMessage(
            "error",
            error.response.data.message,
            "",
            "i-notify"
          );
          dispatch(hideLoader());
        });
    }
  };

  const handleProfileUpdate = async () => {
    dispatch(showLoader());
    var bodyFormData = new FormData();
    bodyFormData.append("Email", userInfo.email);
    bodyFormData.append("ImageFile", userImg);
    bodyFormData.append("isDelete", isDelete);
    const resp = await httpClient.put("UserManager/UpdateUserProfile", bodyFormData, {
      headers: "multipart/form-data",
    })
      .catch((error) => {
        toast_Ref.current.showMessage("error", error.response.data.message, "", "i-notify");
        dispatch(hideLoader());
      });

    dispatch(hideLoader());
    if (resp?.data.isSuccess) {
      toast_Ref.current.showMessage("success",resp.data.message,"","i-chk-circle");
      dispatch(hideLoader());
      dispatch(setUserDetail(resp?.data.userProfile));
      dispatch(setUserImage(resp?.data.userProfile.profileAddress));
      setIsDelete(false);
      setShowInput(false);

      // setTimeout(() => {
      //   navigate("/receiving/upload-rma/list");
      // }, 1500);
    }
  };

  const handleChangePasswordView = () => {
    passData.CurrentPassword = "";
    passData.NewPassword = "";
    passData.Password = "";
    setHidePassword(false);
  }

  const handlePageHeaderAction = (data) => { };

  const handleImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      setIsDelete(false);
      const maxAllowedSize = 2 * 1024 * 1024;
      if (e.target.files[0].size > maxAllowedSize) {
        setUserImg(null);
        e.target.value = null;
      } else {
        setUserImg(e.target.files[0]);
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
          setDisplayUser(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content">
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Profile"}
          HeaderItems={[]}
        />

        <div className="user_tabs_content bg-white mt-3">
          {hidePassword && (
            <>
              <div className="grid pl-4 pr-4 mt-2 col-6  align-items-center">
                <div className="mr-6">
                  <img
                    src={dispalyUser ? dispalyUser : "/images/profile-pic.png"}
                    className="border-circle"
                    alt="profile-pic"
                    width="100"
                    height="100"
                  />
                </div>
                <div className="mr-4">
                  <Button
                    label={"Upload New Picture"}
                    onClick={() => setShowInput(true)}
                  />
                </div>
                <div>
                  <Button
                    label={"Delete"}
                    className="btn-secondary"
                    onClick={handleDeleteImg}
                    disabled={!dispalyUser}
                  />
                </div>
              </div>
              {/* {showInput && ( */}
              <div className={showInput ? "ml-8 pl-6" : "ml-8 pl-6 hidden"}>
                <input
                  type="file"
                  id="docpicker"
                  onChange={handleImage}
                  accept=".png,.jpeg"
                  ref={fileInput}
                />
                <p className="file-input-text">
                  JPG, GIF or PNG. Max size of 2MB
                </p>
              </div>
              {/* ) */}
              {/* } */}
              <div className="grid pl-4 pr-4 mt-2">
                <div className="col-4">
                  <FormInput
                    title={"User Name"}
                    isDisabled={true}
                    value={userInfo.username}
                  // onchange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="col-4">
                  <FormInput
                    title={"Email"}
                    inputtype={"email"}
                    value={userInfo.email}
                    isDisabled={true}
                  // onchange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="grid pl-4 pr-4 align-items-center">
                <div className="flex flex-column gap-2 col-4">
                  <label>
                    Password <span className="text-danger">*</span>
                  </label>
                  <Password
                    placeholder="Enter password"
                    feedback={false}
                    showIcon={<i className="i-eye-hide"></i>}
                    hideIcon={<i className="i-eye-show"></i>}
                    disabled
                    value="9999999"
                  />
                </div>
                <div className="col-3 mt-4 text-center change-password">
                  <a
                    className="cursor-pointer"
                    onClick={handleChangePasswordView}
                  >
                    Change Password
                  </a>
                </div>
              </div>
            </>
          )}
          {!hidePassword && (
            <>
              <div className="grid pl-4 pr-4 mt-2 flex-column">
                <div className="flex flex-column gap-2 col-4">
                  <label>
                    Current Password <span className="text-danger">*</span>
                  </label>
                  <Password
                    placeholder="Enter current password"
                    feedback={false}
                    name="CurrentPassword"
                    onChange={handleChange}
                    value={passData.CurrentPassword}
                    showIcon={<i className="i-eye-hide"></i>}
                    hideIcon={<i className="i-eye-show"></i>}
                    toggleMask
                  />
                </div>
                <div className="flex flex-column gap-2 col-4">
                  <label>
                    New Password <span className="text-danger">*</span>
                  </label>
                  <Password
                    placeholder="Enter new password"
                    feedback={false}
                    name="Password"
                    value={passData.Password}
                    toggleMask
                    onChange={handleChange}
                    showIcon={<i className="i-eye-hide"></i>}
                    hideIcon={<i className="i-eye-show"></i>}
                  />
                </div>
                <div className="flex flex-column gap-2 col-4">
                  <label>
                    Confirm New Password <span className="text-danger">*</span>
                  </label>
                  <Password
                    placeholder="Confirm new password"
                    feedback={false}
                    name="NewPassword"
                    className={
                      passData.NewPassword !== passData.Password &&
                        passData.NewPassword !== ""
                        ? "p-invalid"
                        : ""
                    }
                    toggleMask
                    showIcon={<i className="i-eye-hide"></i>}
                    hideIcon={<i className="i-eye-show"></i>}
                    value={passData.NewPassword}
                    onChange={handleChange}
                  />
                </div>
                <div className="page_footer ml-2 flex gap-3 justify-content-start pt-3">
                  <Button
                    label={"Cancel"}
                    className="btn-secondary"
                    onClick={handleCancelPassword}
                  />
                  <Button
                    label={"Update"}
                    disabled={passData.NewPassword !== passData.Password}
                    onClick={handleChangePassword}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="page_footer flex gap-3 justify-content-end pt-3">
          {hidePassword && (
            <>
              <Button
                label={"Cancel"}
                className="btn-secondary"
                onClick={() => navigate("/receiving/upload-rma/list")}
              />
              <Button
                disabled={!isDelete && !userImg}
                label={"Update"}
                onClick={handleProfileUpdate}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile;
