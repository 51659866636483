import React, { useEffect, useMemo, useRef, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import VIV_FileUpload from "../../../shared/File-Upload";
import GridTabs from "../../../shared/GridTabs";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import { Calendar } from "primereact/calendar";
import { Dropdown } from 'primereact/dropdown';
import FormInput from "../../../shared/Form-Input";
import "./style.scss";
import UploadRMAFormGrid from "./components/upload-rma-form-grid";
import VIV_FileUploaded from "./components/VIV_FileUploaded";
import PrintBarcode from "../../../shared/PrintBarcodePopup";
import httpClient from "../../../_util/api";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from '../../../redux/mainSlice';
import VivToast from "../../../shared/VivitechToast";
import VIV_DocViewer from "../../../shared/Doc-Viewer";
import UploadFileProgressBar from "./components/upload-progress-bar";

const UploadRMAForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0)
  const toast_Ref = useRef(null);
  const [RMA_FileURL, setRMA_FileURL] = useState(null);
  const packageBarcode_PopUpRef = useRef();
  const formData = new FormData();
  const [barcodeData, setBarcodeData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const { id } = useParams();
  const [printDialogConfig, setprintDialogConfig] = useState({
    title: "Package Barcode",
    btnLabel: "Print Package Barcode"
  });
  const [RMAInfo, setRMAInfo] = useState(
    {
      id: 0,
      entryDate: "",
      rmaDate: "",
      url: "",
      rmaNumber: "",
      packageCount: 0,
      proNumber: "",
      invoiceNumber: "",
      billOfLadingNumber: "",
      carrierName: "",
      trailerName: "",
      sealNumber: "",

      addressBookFrom: "",
      shipFromName: "",
      shipFromAddress: "",
      shipFromCity: "",
      shipFromState: "",
      shipFromZip: "",

      addressBookTo: "",
      shipToName: "",
      shipToAddress: "",
      shipToCity: "",
      shipToState: "",
      shipToZip: "",

      description1: "",
      description2: "",
      description3: "",
      description4: "",

      itemDetails: [
        {
          id: 0,
          upc: "",
          model: "",
          description: "",
          quantity: 0,
          cost: "",
          extCost: ""
        }
      ]


    }
  )
  const headerAction = [
    {
      type: "button",
      icon: "",
      containerClass: "",
      classes: "btn-secondary",
      title: "Print Package Barcodes",
      disabled: isSubmitted ? false : true,
    },
    {
      type: "button",
      icon: "",
      containerClass: "",
      classes: "btn-secondary",
      title: "Print Document Barcode",
      disabled: isSubmitted ? false : true
    },
  ];

  const grid_Tabs = [{ label: "RMA Info" }, { label: "Item Details" }];

  // const handlePageHeaderAction = (e) => {
  //   if (e.title == 'Print Package Barcodes') {
  //     if (barcodeData) {
  //       packageBarcode_PopUpRef.current.showPopUp()
  //       handlePrintPackageBarcode()
  //     }
  //   }
  //   if (e.title == 'Print Document Barcode') {
  //     documentBarcode_PopUpRef.current.showPopUp()
  //      handlePrintdocumentBarcode()
  //   }
  // };

  const handlePageHeaderAction = (e) => {
    if (e.title == 'Print Package Barcodes') {
      if (barcodeData) {
              packageBarcode_PopUpRef.current.showPopUp()
              handlePrintPackageBarcode()
            }
    }
    if (e.title == 'Print Document Barcode') {
      handlePrintdocumentBarcode()
    }
  };

  const handleTabChange = (activeTab) => {
    setIndex(activeTab);
  };


  const cities = [
    'New York', 'Rome', 'London', 'Istanbul', 'Paris'
  ];

  const template = (options, label) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} justify-content-between`;
    const titleClassName = `${options.titleClassName} ml-2 text-secondary`;
    const style = { fontSize: "1.25rem", font_weight: "500" };


    return (
      <div className={className}>
        <span
          className={titleClassName}
          style={{ fontSize: "1.25rem", fontWeight: 500 }}
        >
          {label}
        </span>

        <button
          className={`panel-toggleBtn ${options.togglerClassName}`}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
      </div>
    );
  };

  const saveRMAData = (res) => {
    setRMAInfo({
      ...RMAInfo,
      entryDate: res.rmaInfo.entryDate ? new Date(res.rmaInfo.entryDate) : new Date(),
      rmaDate: res.rmaInfo.rmaDate ? new Date(res.rmaInfo.rmaDate) : new Date(),
      url: res.rmaInfo.url,
      rmaNumber: res.rmaInfo.rmaNumber,
      packageCount: res.rmaInfo.packageCount,
      proNumber: res.rmaInfo.proNumber,
      invoiceNumber: res.rmaInfo.invoiceNumber,
      billOfLadingNumber: res.rmaInfo.billOfLadingNumber,
      carrierName: res.rmaInfo.carrierName,
      trailerName: res.rmaInfo.trailerName,
      sealNumber: res.rmaInfo.sealNumber,

      addressBookFrom: res.rmaInfo.addressBookFrom,
      shipFromName: res.rmaInfo.shipFromName,
      shipFromAddress: res.rmaInfo.shipFromAddress,
      shipFromCity: res.rmaInfo.shipFromCity,
      shipFromState: res.rmaInfo.shipFromState,
      shipFromZip: res.rmaInfo.shipFromZip,

      addressBookTo: res.rmaInfo.addressBookTo,
      shipToName: res.rmaInfo.shipToName,
      shipToAddress: res.rmaInfo.shipToAddress,
      shipToCity: res.rmaInfo.shipToCity,
      shipToState: res.rmaInfo.shipToState,
      shipToZip: res.rmaInfo.shipToZip,

      description1: res.rmaInfo.description1,
      description2: res.rmaInfo.description2,
      description3: res.rmaInfo.description3,
      description4: res.rmaInfo.description4,

      itemDetails: res.rmaInfo.itemDetails.map(item => ({
        id: item.id,
        upc: item.upc || "",
        model: item.model || "",
        description: item.description || "",
        quantity: item.quantity || "",
        cost: item.cost || "",
        extCost: item.extCost || "",
      })),
    });
  }

  const handleFileUpload = async (file) => {

    if (file[0]) {
      if (file[0].type !== 'application/pdf') {
        toast_Ref.current.showMessage("error", "Please upload PDF file", "", "i-notify");
        return;
      }
      setUploading(true)
      formData.append('file', file[0])
      setFileName(file[0].name)
      const res = await httpClient.post('/RMA/UploadPDF', formData).catch((error) => {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "error", error?.response?.data.message, "", "i-notify");
      });

      if (res?.status == 200) {
        //console.log("REs", res);
        setUploading(false);
        if (res?.data) {
          saveRMAData(res.data)
        }

        setRMA_FileURL([...[{ uri: res?.data.rmaInfo.url }]]);
        dispatch(hideLoader());
      }
    }

    else if (file.files[0]) {
      if (file.files[0].type !== 'application/pdf') {
        toast_Ref.current.showMessage("error", "Please upload PDF file", "", "i-notify");
        return;
      }
      setUploading(true)
      formData.append('file', file.files[0]);
      setFileName(file.files[0].name)
      const res = await httpClient.post('/RMA/UploadPDF', formData).catch((error) => {
        dispatch(hideLoader());
        //console.log(error);
        toast_Ref.current.showMessage(
          "error", error?.response?.data.message, "", "i-notify");
      });

      if (res?.status == 200) {
        setUploading(false)

        if (res?.data) {
          saveRMAData(res.data)
        }

        setRMA_FileURL([...[{ uri: res?.data.rmaInfo.url }]]);
        dispatch(hideLoader());
      }
    }


  }

  const handleSubmitRMA = async () => {
    dispatch(showLoader())
    setIsSubmitted(true)
    const res = await httpClient.post(`/RMA/SaveAndUpdatePDF?isSubmitted=true`, RMAInfo).catch((error) => {
      dispatch(hideLoader());
      toast_Ref.current.showMessage("error", error?.response.data.message, "", "i-notify");
    });

    if (res?.status == 200) {
      dispatch(hideLoader());
      setRMAInfo({ ...RMAInfo, id: res.data.rmaInfo.id });
      toast_Ref.current.showMessage("success",res?.data.message,"","i-chk-circle");

      setTimeout(() => {
        navigate('/receiving/upload-rma/list');
      }, 1500);
      
    }
  }

  const handleSubmitRMAAsDraft = async () => {
    dispatch(showLoader())
    const res = await httpClient.post(`/RMA/SaveAndUpdatePDF?isSubmitted=false`,
      RMAInfo
    ).catch((error) => {
      dispatch(hideLoader());
      //console.log(error);
      toast_Ref.current.showMessage(
        "error", error?.response.data.message, "", "i-notify");
    })

    if (res?.status == 200) {
      dispatch(hideLoader())

      setRMAInfo({ ...RMAInfo, id: res.data.rmaInfo.id })

      toast_Ref.current.showMessage("success", res?.data.message, "", "i-chk-circle");
      
      setTimeout(() => {
        navigate('/receiving/upload-rma/list');
      }, 1500);

    }
  }

  const handlePrintPackageBarcode = async () => {
    dispatch(showLoader());
    const res = await httpClient.get('/RMA/GetPackageBarcode', {
      params: {
        RMAID: RMAInfo.id
      }
    })
      .catch((error) => {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "error", error?.response.data.message, "", "i-notify");
      })
    if (res?.status == 200) {

      setprintDialogConfig({
        title: "Package Barcode",
        btnLabel: "Print Package Barcode"
      })
      setBarcodeData(res?.data.packageBarcodes);
      if (res?.data.packageBarcodes.length) {
        packageBarcode_PopUpRef.current.showPopUp();
      }
      dispatch(hideLoader())
      toast_Ref.current.showMessage(
        "success",
        res?.data.message,
        "",
        "i-chk-circle"
      );

    }


  }

  const handlePrintdocumentBarcode = async () => {
    dispatch(showLoader());
    const res = await httpClient.get('/RMA/GetDocumentBarcode', {
      params: {
        id: RMAInfo.id
      }
    })
      .catch((error) => {
        dispatch(hideLoader());
        toast_Ref.current.showMessage(
          "error", error?.response.data.message, "", "i-notify");
      })

    if (res?.status == 200) {

      setprintDialogConfig({
        title: "Document Barcode",
        btnLabel: "Print Document Barcode"
      })
      setBarcodeData([res?.data]);
      if (res?.data) {
        packageBarcode_PopUpRef.current.showPopUp();
      }
      dispatch(hideLoader())
      toast_Ref.current.showMessage(
        "success",
        res?.data.message,
        "",
        "i-chk-circle"
      );
    }

  }

  const onChangeGridData = (data) => {
    setRMAInfo({
      ...RMAInfo, itemDetails:
        data.map
          (item => ({
            id: item.id,
            upc: item.upc || "",
            model: item.model || "",
            description: item.description || "",
            quantity: item.quantity || "",
            cost: item.cost || "",
            extCost: item.extCost || "",
          })
          )
    })



  }

  const fetchRMA_Detail = async () => {
    dispatch(showLoader());

    let resp = await httpClient.get(`RMA/GetById?id=${id}`).catch((error) => { dispatch(hideLoader()); });

    if (resp?.status == 200) {
      setRMA_FileURL([...[{ uri: resp?.data.rmaInfo.url }]]);
      setRMAInfo(resp?.data.rmaInfo)
      dispatch(hideLoader());
    }
  }

  const document_viewerTemplate = useMemo(() => <div className="VIV_DocViewer_Form"><VIV_DocViewer documents={RMA_FileURL} /></div>, [RMA_FileURL]);

  useEffect(() => {
    if (id) {
      fetchRMA_Detail()
    }
  }, []);

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="page_content pb-0">
        <PrintBarcode ref={packageBarcode_PopUpRef} title={printDialogConfig.title} btnLabel={printDialogConfig.btnLabel} barcodeData={barcodeData} />
        <PageHeader
          onPageActoin={handlePageHeaderAction}
          PageTitle={"Upload RMA"}
          showBackButton={true}
          backButtonRoute="/receiving/upload-rma/list"
          HeaderItems={headerAction}
        />

        <div className="upload-rma-content mt-3">
          <div className="grid">
            <div className="col-5 upload-rma">
              {
                RMA_FileURL ? (
                  <div>
                    {document_viewerTemplate}
                  </div>
                ) : (
                  <div>
                    {
                      uploading ? (<UploadFileProgressBar fileName={fileName} />)
                        : (<VIV_FileUpload onUpload={handleFileUpload} />)
                    }
                  </div>
                )
              }


            </div>
            <div className="col-7">
              <GridTabs
                tabs={grid_Tabs}
                onTabChange={handleTabChange}
                activeIndex={index}
              />

              {
                index === 0 && (
                  <div className="panel-container">
                    <div className="panel-1-container">
                      <Panel
                        collapsed={false}
                        className="upload-rma-panel mt-2"
                        headerTemplate={(e) => template(e, "RMA Info")}
                        toggleable
                      >
                        <div className="grid">
                          <div className="col-6 flex flex-column gap-2">
                            <label>Entry Date</label>
                            <Calendar
                              className=" inputCalender"
                              dateFormat="mm/dd/yy"
                              placeholder="MM-DD-YYYY"
                              value={new Date(RMAInfo.entryDate)}
                              onChange={(e) => setRMAInfo({ ...RMAInfo, entryDate: new Date(e.value) })}
                            // onChange={entryDateChangeHandler}
                            />
                          </div>
                          <div className="col-6 flex flex-column gap-2">
                            <label>RMA Date</label>
                            <Calendar
                              className=" inputCalender"
                              dateFormat="mm/dd/yy"
                              placeholder="MM-DD-YYYY"
                              value={RMAInfo.rmaDate}
                              //onChange={(e) => console.log(e)}
                              onChange={(e) => setRMAInfo({ ...RMAInfo, rmaDate: new Date(e.value) })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"RMA Number"}
                              value={RMAInfo.rmaNumber}
                              inputtype={"text"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, rmaNumber: e })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"Package Count"}
                              value={RMAInfo.packageCount}
                              inputtype={"number"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, packageCount: e })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"PRO Number"}
                              value={RMAInfo.proNumber}
                              inputtype={"text"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, proNumber: e })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"Invoice Number"}
                              value={RMAInfo.invoiceNumber}
                              inputtype={"text"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, invoiceNumber: e })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"Bill of Lading Number"}
                              value={RMAInfo.billOfLadingNumber}
                              inputtype={"text"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, billOfLadingNumber: e })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"Carrier Name"}
                              value={RMAInfo.carrierName}
                              inputtype={"text"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, carrierName: e })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"Trailer Name"}
                              value={RMAInfo.trailerName}
                              inputtype={"text"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, trailerName: e })}
                            />
                          </div>
                          <div className="col-6">
                            <FormInput
                              title={"Seal Number"}
                              value={RMAInfo.sealNumber}
                              inputtype={"text"}
                              onchange={(e) => setRMAInfo({ ...RMAInfo, sealNumber: e })}
                            />
                          </div>
                        </div>
                      </Panel>
                    </div>

                    <Panel
                      collapsed={true}
                      className="upload-rma-panel mt-2"
                      headerTemplate={(e) => template(e, "Ship From Address")}
                      toggleable
                    >
                      <div className="grid">
                        <div className="col-6 flex flex-column gap-2">
                          <label>Addressbook (From)</label>
                          <Dropdown
                            value={RMAInfo.addressBookFrom} onChange={(e) => setRMAInfo({ ...RMAInfo, addressBookFrom: (e.value) })} options={cities}
                            editable placeholder="Select a City" className="w-full" />

                        </div>
                        <div className="col-6 flex flex-column gap-2">
                          <FormInput
                            title={"Ship From Name"}
                            value={RMAInfo.shipFromName}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipFromName: e })}
                          />
                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Ship From Address"}
                            value={RMAInfo.shipFromAddress}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipFromAddress: e })}
                          />
                        </div>
                        <div className="col-6 flex flex-column gap-2">
                          <label>Ship from City</label>
                          <Dropdown
                            value={RMAInfo.shipFromCity} onChange={(e) => setRMAInfo({ ...RMAInfo, shipFromCity: (e.value) })} options={cities}
                            editable placeholder="Select a City" className="w-full" />

                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Ship From State"}
                            value={RMAInfo.shipFromState}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipFromState: e })}
                          />
                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Ship From Zip"}
                            value={RMAInfo.shipFromZip}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipFromZip: e })}
                          />
                        </div>
                      </div>
                    </Panel>

                    <Panel
                      collapsed={true}
                      className="upload-rma-panel mt-2"
                      headerTemplate={(e) => template(e, "Ship To Address")}
                      toggleable
                    >
                      <div className="grid">
                        <div className="col-6 flex flex-column gap-2">
                          <label>Addressbook (To)</label>
                          <Dropdown
                            value={RMAInfo.addressBookTo} onChange={(e) => setRMAInfo({ ...RMAInfo, addressBookTo: (e.value) })} options={cities}
                            editable placeholder="Select a City" className="w-full" />
                        </div>
                        <div className="col-6 flex flex-column gap-2">
                          <FormInput
                            title={"Ship To Name"}
                            value={RMAInfo.shipToName}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipToName: e })}
                          />
                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Ship To Address"}
                            value={RMAInfo.shipToAddress}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipToAddress: e })}
                          />
                        </div>
                        <div className="col-6 flex flex-column gap-2">
                          <label>Ship To City</label>
                          <Dropdown
                            value={RMAInfo.shipToCity} onChange={(e) => setRMAInfo({ ...RMAInfo, shipToCity: (e.value) })} options={cities}
                            editable placeholder="Select a City" className="w-full" />

                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Ship To State"}
                            inputtype={"text"}
                            value={RMAInfo.shipToState}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipToState: e })}
                          />
                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Ship To Zip"}
                            value={RMAInfo.shipToZip}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, shipToZip: e })}
                          />
                        </div>
                      </div>
                    </Panel>

                    <Panel
                      collapsed={true}
                      className="upload-rma-panel mt-2"
                      headerTemplate={(e) => template(e, "Description Fields")}
                      toggleable
                    >
                      <div className="grid">
                        <div className="col-6 flex flex-column gap-2">
                          <FormInput
                            title={"Description 1"}
                            value={RMAInfo.description1}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, description1: e })}
                          />
                        </div>
                        <div className="col-6 flex flex-column gap-2">
                          <FormInput
                            title={"Description 2"}
                            value={RMAInfo.description2}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, description2: e })}
                          />
                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Description 3"}
                            value={RMAInfo.description3}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, description3: e })}
                          />
                        </div>
                        <div className="col-6">
                          <FormInput
                            title={"Description 4"}
                            value={RMAInfo.description4}
                            inputtype={"text"}
                            onchange={(e) => setRMAInfo({ ...RMAInfo, description4: e })}
                          />

                        </div>
                      </div>
                    </Panel>
                  </div>
                )
              }
              {
                index === 1 && (
                  <UploadRMAFormGrid onChangeGridData={(e) => onChangeGridData(e)} gridData={RMAInfo.itemDetails} />
                  // <UploadRMAFormGrid onChangeGridData={(e) => setRMAInfo({ ...RMAInfo, itemDetails: e })} gridData={RMAInfo.itemDetails} />
                )
              }

            </div>
          </div>
        </div>



        <div className="page_footer flex justify-space-between pt-3"
        >
          <Button
          disabled={RMA_FileURL ? false : true}
            label={"Save as Draft"}
            severity="info" text
            onClick={handleSubmitRMAAsDraft}
          />

          <div className="flex gap-3 justify-content-end ml-auto" >
            <Button
              label={"Cancel"}
              className="btn-secondary"
              onClick={() => navigate("/receiving/upload-rma/list")}
            />
            <Button
              disabled={RMA_FileURL ? false : true}
              label={"Submit"}
              onClick={handleSubmitRMA}
            />
          </div>

        </div>



      </div>
    </>
  );
};

export default UploadRMAForm;
