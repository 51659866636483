import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ProtectedRoute from "../_util/ProtectedRoute";
import ProtectedLogin from "../_util/ProtectedLogin";
import ProtectedSignUp from "../_util/ProtectedSignUp";
import Login from "../pages/auth/login";
import CreateManifest from "../pages/receiving/create-manifest/index";
import PackList from "../pages/receiving/pack-list/index";
import ReceivedRMA from "../pages/receiving/received-rma/index";
import ScanRMA from "../pages/receiving/scan-rma/index";
import UploadRMA from "../pages/receiving/upload-rma/index";
import Signup from "../pages/auth/signup";
import ForgotPassword from "../pages/auth/ForgotPass";
import ItemMasterList from "../pages/setup/item-master/list";
import ItemMasrterForm from "../pages/setup/item-master/form";
import UserSetupList from "../pages/setup/user-setup/list";
import UserSetupForm from "../pages/setup/user-setup/form";
import UserDetailForm from "../pages/setup/user-setup/detail";
import UserRoleList from "../pages/setup/roles/list";
import UserRoleForm from "../pages/setup/roles/form";
import UserProfile from "../pages/user-profile/userProfile";
import WarehouseList from "../pages/setup/warehouse/list";
import WarehouseForm from "../pages/setup/warehouse/form";
import CarrierList from "../pages/setup/carrier/list";
import CarrierForm from "../pages/setup/carrier/form";
import CarrierDetailForm from "../pages/setup/carrier/detail";
import ZoneSetupList from "../pages/setup/warehouse/zones/list";
import ZoneSetupForm from "../pages/setup/warehouse/zones/form";
import LocationList from "../pages/setup/warehouse/location/list";
import LocationSetupForm from "../pages/setup/warehouse/location/form";
import UserRoleDetail from "../pages/setup/roles/detail";
import ZoneDetail from "../pages/setup/warehouse/zones/detail";
import LocationDetail from "../pages/setup/warehouse/location/detail";
import WarehouseDetail from "../pages/setup/warehouse/detail";
import UploadRMAForm from "../pages/receiving/upload-rma/form";
import ScanRmaDetail from "../pages/receiving/scan-rma/components/ScanRmaDetail";
import ItemMasrterDetail from "../pages/setup/item-master/detail";
import UploadRMADetail from "../pages/receiving/upload-rma/detail";
import InventorybyItem from "../pages/inventory/inventory-by-item";
import ReceivedRMADetail from "../pages/receiving/received-rma/detail";

export const AppRoutes = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedLogin>
            <Login />
          </ProtectedLogin>
        }
      ></Route>
      <Route
        path="login"
        element={
          <ProtectedLogin>
            <Login />
          </ProtectedLogin>
        }
      ></Route>
      <Route path="signup/:id" element={<ProtectedSignUp><Signup /></ProtectedSignUp>}></Route>
      <Route path="forgotpassword" element={<ForgotPassword />}></Route>
      <Route path="profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>}></Route>
      <Route path="receiving">
        <Route
          path="create-manifest"
          element={
            <ProtectedRoute>
              <CreateManifest />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="pack-list"
          element={
            <ProtectedRoute>
              <PackList />
            </ProtectedRoute>
          }
        ></Route>

        <Route path="received-rma">
          <Route
            path="list"
            element={
              <ProtectedRoute>
                <ReceivedRMA />
              </ProtectedRoute>
            }
          />
          <Route
            path="detail/:id"
            element={
              <ProtectedRoute>
                <ReceivedRMADetail />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="scan-rma"
          element={
            <ProtectedRoute>
              <ScanRMA />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="ScanRmaDetail"
          element={
            <ProtectedRoute>
              <ScanRmaDetail />
            </ProtectedRoute>
          }
        ></Route>


        <Route path="upload-rma">
          <Route
            path="list"
            element={
              <ProtectedRoute>
                <UploadRMA />
              </ProtectedRoute>
            }
          />
          <Route
            path="detail/:id"
            element={
              <ProtectedRoute>
                <UploadRMADetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="form/:id"
            element={
              <ProtectedRoute>
                <UploadRMAForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="form"
            element={
              <ProtectedRoute>
                <UploadRMAForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="form/:id"
            element={
              <ProtectedRoute>
                <UploadRMAForm />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
      <Route path="inventory">
        <Route path="inventory-by-item"
         element={
          <ProtectedRoute>
            <InventorybyItem />
          </ProtectedRoute>
        }></Route>
        
      </Route>
      <Route path="setup">
        <Route path="item">
          <Route
            path="list"
            element={
              <ProtectedRoute>
                <ItemMasterList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form"
            element={
              <ProtectedRoute>
                <ItemMasrterForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form/:id"
            element={
              <ProtectedRoute>
                <ItemMasrterForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="detail/:id"
            element={
              <ProtectedRoute>
                <ItemMasrterDetail />
              </ProtectedRoute>
            }
          ></Route>
        </Route>
        <Route path="user">
          <Route
            path="list"
            element={
              <ProtectedRoute>
                <UserSetupList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form"
            element={
              <ProtectedRoute>
                <UserSetupForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form/:id"
            element={
              <ProtectedRoute>
                <UserSetupForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="detail/:id"
            element={
              <ProtectedRoute>
                <UserDetailForm />
              </ProtectedRoute>
            }
          ></Route>
          {/* <Route path="detail" element={<ProtectedRoute><CreateManifest /></ProtectedRoute>}></Route>  */}
        </Route>
        <Route path="role">
          <Route
            path="list"
            element={
              <ProtectedRoute>
                <UserRoleList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form"
            element={
              <ProtectedRoute>
                <UserRoleForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form/:id"
            element={
              <ProtectedRoute>
                <UserRoleForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="detail/:id"
            element={
              <ProtectedRoute>
                <UserRoleDetail />
              </ProtectedRoute>
            }
          ></Route>
        </Route>
        <Route path="warehouse">
          <Route
            path="list"
            element={
              <ProtectedRoute>
                <WarehouseList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="detail/:id"
            element={
              <ProtectedRoute>
                <WarehouseDetail />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form"
            element={
              <ProtectedRoute>
                <WarehouseForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form/:id"
            element={
              <ProtectedRoute>
                <WarehouseForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="zone/list/:warehouseId"
            element={
              <ProtectedRoute>
                <ZoneSetupList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="zone/form/:warehouseId"
            element={
              <ProtectedRoute>
                <ZoneSetupForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="zone/form/:warehouseId/:id"
            element={
              <ProtectedRoute>
                <ZoneSetupForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="zone/detail/:id"
            element={
              <ProtectedRoute>
                <ZoneDetail />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="location/list/:ZoneID"
            element={
              <ProtectedRoute>
                <LocationList />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="location/form/:ZoneID/:zoneTypeID"
            element={
              <ProtectedRoute>
                <LocationSetupForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="location/form/:ZoneID/:zoneTypeID/:id"
            element={
              <ProtectedRoute>
                <LocationSetupForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="location/detail/:id"
            element={
              <ProtectedRoute>
                <LocationDetail />
              </ProtectedRoute>
            }
          ></Route>
        </Route>
        <Route path="carrier">
          <Route
            path="list"
            element={
              <ProtectedRoute>
                <CarrierList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form"
            element={
              <ProtectedRoute>
                <CarrierForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="form/:id"
            element={
              <ProtectedRoute>
                <CarrierForm />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="detail/:id"
            element={
              <ProtectedRoute>
                <CarrierDetailForm />
              </ProtectedRoute>
            }
          ></Route>
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
