import React, { useEffect, useRef, useState, useMemo } from "react";
import PageHeader from "../../../shared/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import GridTabs from "../../../shared/GridTabs";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import "./style.scss";
import PrintBarcode from "../../../shared/PrintBarcodePopup";
import httpClient from "../../../_util/api";
import VIV_DocViewer from "../../../shared/Doc-Viewer";
import { useDispatch } from "react-redux";
import { shrinkSideBar } from '../../../redux/sidebarSlice';
import { hideLoader, showLoader } from '../../../redux/mainSlice';
import VIVGrid from "../../../shared/VIV-Grid";
import VivToast from "../../../shared/VivitechToast";

const UploadRMADetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [index, setIndex] = useState(0);
    const [RMA_FileURL, setRMA_FileURL] = useState([]);
    const [isEditable,setIsEditable] = useState(false)
    const [RMA_Data, setRMAData] = useState(null);
    const [printDialogConfig, setprintDialogConfig] = useState({
        title: "Package Barcode",
        btnLabel: "Print Package Barcode"
    });
    const packageBarcode_PopUpRef = useRef();
    const [barcodeData, setBarcodeData] = useState([])
    const toast_Ref = useRef(null);


    const headerAction = [
        {
            type: "button",
            icon: "",
            containerClass: "",
            classes: "btn-secondary",
            title: "Print Package Barcodes",
        },
        {
            type: "button",
            icon: "",
            containerClass: "",
            classes: "btn-secondary",
            title: "Print Document Barcode",
        },
        {
            type: "editButton",
            icon: "",
            containerClass: "",
            classes: "",
            disabled: isEditable ? false : true
        },
    ];

    const grid_Tabs = [{ label: "RMA Info" }, { label: "Item Details" }];

    const grid_columns = [
        {
            field: "upc",
            header: "UPC",
            feildtype: "text",
            editable: false,
            widthClass: "w-2rem",
            islink: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "model",
            header: "Model",
            feildtype: "text",
            isNumeric: true,
            editable: false,
            widthClass: "w-2rem",
            islink: false,
            statusfeild: false,
            visible: true,

        },
        {
            field: "description",
            header: "Description",
            feildtype: "text",
            editable: false,
            widthClass: "w-5rem",
            islink: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "quantity",
            header: "Qty",
            feildtype: "text",
            editable: false,
            isNumeric: true,
            widthClass: "w-2rem",
            islink: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "cost",
            header: "Cost",
            feildtype: "text",
            editable: false,
            widthClass: "w-2rem",
            islink: false,
            statusfeild: false,
            visible: true,
        }
    ];

    const handlePageHeaderAction = (e) => {
        if (e.title == 'Print Package Barcodes') {
            handlePrintPackageBarcode()
         
        }
        if (e.title == 'Print Document Barcode') {
            handlePrintdocumentBarcode()
        }
        if (e.actiontype == "editButton") {
            navigate(`/receiving/upload-rma/form/${id}`);
        }
    };

    const handleTabChange = (activeTab) => {
        setIndex(activeTab);
    };

    const fetchRMA_Detail = async () => {
        dispatch(showLoader());

        let resp = await httpClient.get(`RMA/GetById?id=${id}`).catch((error) => { dispatch(hideLoader()); });
        if (resp?.status == 200) {
            setRMA_FileURL([...[{ uri: resp?.data.rmaInfo.url }]]);
            setRMAData(resp?.data.rmaInfo);
            setIsEditable(resp?.data.rmaInfo.isEditable)
            dispatch(hideLoader());
        }

        console.log("isEditable:",isEditable);
    }

    const handlePrintPackageBarcode = async () => {
        dispatch(showLoader());
        const res = await httpClient.get('/RMA/GetPackageBarcode', {
            params: {
                RMAID: id
            }
        })
            .catch((error) => {
                dispatch(hideLoader());
                toast_Ref.current.showMessage(
                    "error", error?.response.data.message, "", "i-notify");
            })
        if (res?.status == 200) {
            
            setprintDialogConfig({
                title: "Package Barcode",
                btnLabel: "Print Package Barcode"
            })
            setBarcodeData(res?.data.packageBarcodes);
            if (res?.data.packageBarcodes.length) {
                packageBarcode_PopUpRef.current.showPopUp();
            }
            dispatch(hideLoader())
             setTimeout(() => {
        toast_Ref.current.showMessage(
            "success",
            res?.data.message,
            "",
            "i-chk-circle"
          );
        }, 300);
        }
    }

    const handlePrintdocumentBarcode = async () => {
        dispatch(showLoader());
        const res = await httpClient.get('/RMA/GetDocumentBarcode', {
            params: {
                id: id
            }
        })
            .catch((error) => {
                dispatch(hideLoader());
                toast_Ref.current.showMessage(
                    "error", error?.response.data.message, "", "i-notify");
            })

        if (res?.status == 200) {
            
            setprintDialogConfig({
                title: "Document Barcode",
                btnLabel: "Print Document Barcode"
            })
            setBarcodeData([res?.data]);
            if (res?.data) {
                packageBarcode_PopUpRef.current.showPopUp();
            }
            dispatch(hideLoader())
            setTimeout(() => {
            toast_Ref.current.showMessage(
                "success",
                res?.data.message,
                "",
                "i-chk-circle"
              );
            }, 300);
        }

    }

    useEffect(() => {
        dispatch(shrinkSideBar());
        fetchRMA_Detail();
    }, []);

    const template = (options, label) => {
        const toggleIcon = options.collapsed
            ? "pi pi-chevron-down"
            : "pi pi-chevron-up";
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} ml-2 text-secondary`;
        const style = { fontSize: "1.25rem", font_weight: "500" };


        return (
            <div className={className}>
                <span
                    className={titleClassName}
                    style={{ fontSize: "1.25rem", fontWeight: 500 }}
                >
                    {label}
                </span>

                <button
                    className={`panel-toggleBtn ${options.togglerClassName}`}
                    onClick={options.onTogglerClick}
                >
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };

    const document_viewerTemplate = useMemo(() => <VIV_DocViewer documents={RMA_FileURL} />, [RMA_FileURL]);

    return (
        <>
            <VivToast ref={toast_Ref} />
            <div className="page_content pb-0">
                <PrintBarcode ref={packageBarcode_PopUpRef} title={printDialogConfig.title} btnLabel={printDialogConfig.btnLabel} barcodeData={barcodeData} />
                <PageHeader
                    onPageActoin={handlePageHeaderAction}
                    PageTitle={"Upload RMA"}
                    showBackButton={true}
                    backButtonRoute="/receiving/upload-rma/list"
                    HeaderItems={headerAction}
                />

                <div className="upload-rma-content detailView mt-3">
                    <div className="grid">
                        <div className="col-5 upload-rma">
                            {document_viewerTemplate}
                        </div>
                        <div className="col-7">
                            <GridTabs
                                tabs={grid_Tabs}
                                onTabChange={handleTabChange}
                                activeIndex={index}
                            />

                            {
                                index === 0 && (
                                    <div className="panel-container">
                                        <div className="panel-1-container">
                                            <Panel
                                                collapsed={false}
                                                className="upload-rma-panel mt-2"
                                                headerTemplate={(e) => template(e, "RMA Info")}
                                                toggleable
                                            >
                                                <div className="grid">
                                                    <div className="col-6 flex flex-column gap-2">
                                                        <p className="detail-lable">Entry Date</p>
                                                        <p className="detail-text">{RMA_Data?.entryDate ? RMA_Data.entryDate : '-'}</p>
                                                    </div>
                                                    <div className="col-6 flex flex-column gap-2">
                                                        <p className="detail-lable">RMA Date</p>
                                                        <p className="detail-text">{RMA_Data?.rmaDate ? RMA_Data.rmaDate : '-'}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="detail-lable">RMA Number</p>
                                                        <p className="detail-text">{RMA_Data?.rmaNumber ? RMA_Data.rmaNumber : '-'}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="detail-lable">Package Count</p>
                                                        <p className="detail-text">{RMA_Data?.packageCount ? RMA_Data.packageCount : '-'}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="detail-lable">PRO Number</p>
                                                        <p className="detail-text">{RMA_Data?.proNumber ? RMA_Data.proNumber : '-'}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="detail-lable">Invoice Number</p>
                                                        <p className="detail-text">{RMA_Data?.invoiceNumber ? RMA_Data.invoiceNumber : '-'}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="detail-lable">Bill of Lading Number</p>
                                                        <p className="detail-text">{RMA_Data?.billOfLadingNumber ? RMA_Data.billOfLadingNumber : '-'}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p className="detail-lable">Carrier Name</p>
                                                        <p className="detail-text">{RMA_Data?.carrierName ? RMA_Data.carrierName : '-'}</p>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </div>

                                        <Panel
                                            collapsed={true}
                                            className="upload-rma-panel mt-2"
                                            headerTemplate={(e) => template(e, "Ship From Address")}
                                            toggleable
                                        >
                                            <div className="grid">
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Addressbook (From)</p>
                                                    <p className="detail-text">{RMA_Data?.carrierName ? RMA_Data.carrierName : '-'}</p>
                                                </div>
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Ship From Name</p>
                                                    <p className="detail-text">{RMA_Data?.shipFromName ? RMA_Data.shipFromName : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Ship From Address</p>
                                                    <p className="detail-text">{RMA_Data?.shipFromAddress ? RMA_Data.shipFromAddress : '-'}</p>
                                                </div>
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Ship from City</p>
                                                    <p className="detail-text">{RMA_Data?.shipFromCity ? RMA_Data.shipFromCity : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Ship From State</p>
                                                    <p className="detail-text">{RMA_Data?.shipFromState ? RMA_Data.shipFromState : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Ship From Zip</p>
                                                    <p className="detail-text">{RMA_Data?.shipFromZip ? RMA_Data.shipFromZip : '-'}</p>
                                                </div>
                                            </div>
                                        </Panel>

                                        <Panel
                                            collapsed={true}
                                            className="upload-rma-panel mt-2"
                                            headerTemplate={(e) => template(e, "Ship To Address")}
                                            toggleable
                                        >
                                            <div className="grid">
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Addressbook (To)</p>
                                                    <p className="detail-text">{RMA_Data?.shipToName ? RMA_Data.shipToName : '-'}</p>
                                                </div>
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Ship To Name</p>
                                                    <p className="detail-text">{RMA_Data?.shipToName ? RMA_Data.shipToName : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Ship To Address</p>
                                                    <p className="detail-text">{RMA_Data?.shipToAddress ? RMA_Data.shipToAddress : '-'}</p>
                                                </div>
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Ship To City</p>
                                                    <p className="detail-text">{RMA_Data?.shipToCity ? RMA_Data.shipToCity : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Ship To State</p>
                                                    <p className="detail-text">{RMA_Data?.shipToState ? RMA_Data.shipToState : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Ship To Zip</p>
                                                    <p className="detail-text">{RMA_Data?.shipToZip ? RMA_Data.shipToZip : '-'}</p>
                                                </div>
                                            </div>
                                        </Panel>

                                        <Panel
                                            collapsed={true}
                                            className="upload-rma-panel mt-2"
                                            headerTemplate={(e) => template(e, "Description Fields")}
                                            toggleable
                                        >
                                            <div className="grid">
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Description 1</p>
                                                    <p className="detail-text">{RMA_Data?.description1 ? RMA_Data.description1 : '-'}</p>
                                                </div>
                                                <div className="col-6 flex flex-column gap-2">
                                                    <p className="detail-lable">Description 2</p>
                                                    <p className="detail-text">{RMA_Data?.description2 ? RMA_Data.description2 : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Description 3</p>
                                                    <p className="detail-text">{RMA_Data?.description3 ? RMA_Data.description3 : '-'}</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="detail-lable">Description 4</p>
                                                    <p className="detail-text">{RMA_Data?.description4 ? RMA_Data.description4 : '-'}</p>
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                )
                            }
                            {
                                index === 1 && (
                                    <VIVGrid
                                        gridActions={[]}
                                        paginated={false}
                                        gridCol={grid_columns}
                                        selection={false}
                                        gridData={RMA_Data?.itemDetails ? RMA_Data?.itemDetails : []}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadRMADetail;