import React, { useState, useRef } from "react";
import "./style.scss";
import PalletScan from "./components/PalletScan";
import PalletDetail from "./components/PalletDetail";
import { useDispatch } from 'react-redux';
import { shrinkSideBar } from "../../../redux/sidebarSlice";
import VivToast from "../../../shared/VivitechToast";


const CreateManifest = () => {
    const dispatch = useDispatch();
    const toast_Ref = useRef(null);
    const [viewMode, setViewMode] = useState("PalletScan");

    const handleViewModeChange = (mode) => {
        dispatch(shrinkSideBar());
        setViewMode(mode);

        if(mode == "PalletScan"){
            //setPakgInfo({});
        }
        else{
            //setPakgInfo({});
        }
    }


    return (
        <>
        <VivToast ref={toast_Ref} />
            {viewMode == "PalletScan" && <PalletScan changeMode={(e) => { handleViewModeChange(e); }} />}
            {(viewMode == "CreateCarton" || viewMode == "CreatePallet") && <PalletDetail viewmode={viewMode} changeMode={(e) => { handleViewModeChange(e); }} />}
        </>

    )
}

export default CreateManifest;