import React, { useRef, useState, useEffect } from "react";
import PageHeader from "../../../shared/PageHeader";
import ExportPopup from "../../../shared/ExportPopup";
import GridTabs from "../../../shared/GridTabs";
import VIVGrid from "../../../shared/VIV-Grid";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import GridFilter from "../../../shared/GridFilter";
//import ImportRMA from "components/import-rma";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import useDebounce from "../../../_util/UseDebounce";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../shared/VivitechToast";

const ReceivedRMA = () => {
    const initialMount = useRef(true);
    const toast_Ref = useRef(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [grid_data, setGridData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordsFrom, setRecordsFrom] = useState(0);
    const [recordsTo, setRecordsTo] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [filterString, setFilterString] = useState("");
    const [gridActionsList, setgridActionsList] = useState([{ label: "Edit", icon: "i-edit", disabled: false }, { label: "Delete", icon: "i-bin", disabled: false }]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const export_PopUpRef = useRef();
    const import_PopUpRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useDebounce(() => { initialMount.current ? initialMount.current = false : LoadGridData(); }, [searchString], 1500);

    const headerItems = [
        {
            type: "import",
            icon: "i-import",
            containerClass: "cursor-pointer flex align-items-center gap-2",
        },
        {
            type: "export",
            icon: "i-export",
            containerClass: "cursor-pointer flex align-items-center gap-2",
        }
    ];

    const gridTabs = [
        { label: "All" },
        { label: "New" },
        { label: "In Progress" },
        { label: "Completed" },
    ];

    const grid_columns = [
        {
            field: "rmaNumber",
            header: "RMA#",
            islink: true,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "date",
            header: "Date",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "shipFromName",
            header: "Ship From",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "repackProgress",
            header: "Repack Progress",
            islink: false,
            isProgressbar: true,
            statusfeild: false,
            visible: true,
        },
        {
            field: "status",
            header: "Status",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
        {
            field: "action",
            header: "Actions",
            islink: false,
            isProgressbar: false,
            statusfeild: false,
            visible: true,
        },
    ];

    const filterData = [
        {
            label: "RMA #",
            filter_key: "rmaNumber",
            feild: "input",
            value: "",
        },
        {
            label: "Ship From",
            filter_key: "ShipFrom",
            feild: "input",
            value: "",
        },
        {
            label: "From",
            filter_key: "fromDate",
            feild: "date",
            value: "",
        },
        {
            label: "To",
            filter_key: "toDate",
            feild: "date",
            value: "",
        },
        // {
        //   label: "Ship From",
        //   feild: "dropdown",
        //   dataforselect: [
        //     { value: "open", viewValue: "Open" },
        //     { value: "assigned", viewValue: "Assigned" },
        //     { value: "in-fulfillment", viewValue: "In Fulfillment" },
        //     { value: "closed", viewValue: "Closed" },
        //   ],
        // },
        // {
        //   label: "Ship To",
        //   feild: "dropdown",
        //   dataforselect: [
        //     { value: "open", viewValue: "Open" },
        //     { value: "assigned", viewValue: "Assigned" },
        //     { value: "in-fulfillment", viewValue: "In Fulfillment" },
        //     { value: "closed", viewValue: "Closed" },
        //   ],
        // }
    ];

    const LoadGridData = async () => {
        dispatch(showLoader());

        let URL = `RMA/GetReceivedRMAGrid?pageNumber=${currentPage}&pageSize=${pageSize}&search=${searchString}&status=${gridTabs[activeTabIndex].label}`;

        if (filterString !== "") {
            URL = URL + "&" + filterString;
        }

        const resp = await httpClient.get(URL).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", (error?.response?.data) ? error?.response?.data.message : error?.message, "", "i-notify");
            setGridData([]);
        });

        if (resp?.data.isSuccess) {
            setTotalRecords(resp?.data.page_TotalRecords);
            setRecordsFrom(resp?.data.page_From);
            setRecordsTo(resp?.data.page_To);
            setGridData(resp?.data.details);
        }
        dispatch(hideLoader());
    };

    useEffect(() => {
        LoadGridData();
    }, [currentPage, pageSize, filterString, activeTabIndex]);

    const handlePageHeaderAction = (data) => {
        //alert(data);
        if (data.actiontype == "export") {
            export_PopUpRef.current.showPopUp();
            // toast_Ref.current.showMessage();
        }
        if (data.actiontype == "import") {
            //import_PopUpRef.current.showPopUp();
        }

    };

    const handleTabChange = (data) => {
        setCurrentPage(1);
        setActiveTabIndex(data);
    };

    const handlePageChnage = (evnt) => {
        setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
        setpageSize(evnt.rows);
    };

    const handleFilters = (data) => {
        setFilterString(data);
    };

    const deleteRMA = async (id) => {
        dispatch(showLoader());
        let resp = await httpClient.delete(`RMA/DeleteRMA?Id=${id}`).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
        });

        if (resp?.status == 200) {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("success", resp?.data.message, "", "i-chk-circle");
            LoadGridData();
        }
    }

    const handleGridAction = (e) => {
        if (e?.action.toLowerCase() == "delete") {
            confirmDialog({
                message: 'Are you sure you want to delete this RMA?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                draggable: false,
                accept: () => { deleteRMA(e.recordId) },
                reject: () => { }
            });
        }
        else {
            navigate(`/receiving/upload-rma/form/${e?.recordId}`);
        }
    }

    return (
        <>
            <ConfirmDialog />
            <VivToast ref={toast_Ref} />
            <GridFilter data={filterData} visible={showFilter} visibleState={setShowFilter} onApplyFilter={handleFilters} />
            <ExportPopup ref={export_PopUpRef} title={`Export`} />
            {/* <ImportRMA ref={import_PopUpRef} title={"Sync from Email"} /> */}
            <div className="page_content pb-0" ref={initialMount}>

                <PageHeader
                    onPageActoin={handlePageHeaderAction}
                    PageTitle={"Received RMA"}
                    HeaderItems={headerItems}
                />

                <div className="mt-5">
                    <div className="grid">
                        <div className="col-8">
                            <GridTabs
                                tabs={gridTabs}
                                onTabChange={handleTabChange}
                                activeIndex={activeTabIndex}
                            />
                        </div>
                        <div className="col-4 align-items-center flex gap-3 justify-content-between">
                            <span className="p-input-icon-left w-full">
                                <i className="i-search" />
                                <InputText placeholder="Search" value={searchString}
                                    onChange={(e) => {
                                        setSearchString(e.target.value);
                                    }} />
                            </span>

                            <Button
                                label="Filter"
                                icon="i-filter"
                                onClick={() => setShowFilter(true)}
                                className="bg-white justify-content-center clr-b"
                                text
                                raised
                            />
                        </div>
                    </div>
                    <VIVGrid
                        gridActions={gridActionsList}
                        paginated={true}
                        centerAlign={true}
                        gridCol={grid_columns}
                        selection={true}
                        gridData={grid_data}
                        TotalRecords={totalRecords}
                        recordfrom={recordsFrom}
                        recordto={recordsTo}
                        onLineAction={handleGridAction}
                        onPageChange={handlePageChnage}
                        baseURL={"/receiving/received-rma/detail/"}
                    />
                </div>
            </div>
        </>
    );
};

export default ReceivedRMA;
