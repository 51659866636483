import React, {useState, useEffect} from "react";
import { TabView, TabPanel } from 'primereact/tabview';
import './style.scss';

const GridTabs = (props) => {
    const [activeIndex, setActiveIndex] = useState(props.activeIndex ? props.activeIndex : 0);

    const handleTabChange = (activeTab) => {
        setActiveIndex(activeTab);
        props.onTabChange(activeTab);
    }

    // useEffect(() => {
    //     console.log(activeIndex);
    // },[props.activeIndex])

    return(
        <TabView scrollable className="w-full viv_tabs" activeIndex={props.activeIndex} onTabChange={(e) => handleTabChange(e.index)}>
            {
                props.tabs.map((tab, i) => {
                    return(
                        <TabPanel header={tab.label} key={i} disabled={(props?.allowTabClick !== undefined) ? !props.allowTabClick : false}></TabPanel>
                    )
                })
            }
        </TabView>
    )
}

export default GridTabs;