import React, { forwardRef, useState, useImperativeHandle, useEffect, } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import "./style.scss";
import QRCode from 'react-qr-code'
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';

const PrintBarcode = forwardRef((props, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [barcodeData, setBarcodeData] = useState(props.barcodeData)

    useImperativeHandle(ref, () => ({
        showPopUp() {
            setIsVisible(true);
        },
    }));

    const barcodeTempelate = (barcodeData) => {
        return (
                <div className="text-center m-0 p-0" style={{
                    border: '2px solid #8080803b',
                    borderRadius: '10px'
                }}>
                    <div className='flex justify-content-center pt-0 mt-0' >
                        <img className='flex align-items-center' src="/images/logo_sm.svg" style={{ display: 'inline' }} />
                        <h5 className='flex align-items-center ml-2' style={{ display: 'inline' }}>Vivitech Solutions</h5>
                    </div>
                    <QRCode className='w-5' style={{height: '170px'}} value={barcodeData.barcodeContent} />
                    <p className=''><span>Sender:</span><span>{barcodeData.sender}</span></p>
                    <p className='mt-2'><span>RMA Number:</span><span>{barcodeData.raNumber}</span></p>
                    <p className='mt-2'><span>Package #:</span><span>{barcodeData.packageNumber}</span></p>
                    <p className='mt-2'><span>{barcodeData.packageCount}</span></p>
                    <p className='mt-2'><span>Recieved Date:</span><span>{barcodeData.receivedDate}</span></p>
                </div>
        )
    }

    return (
            <Dialog
                header={props.title}
                visible={isVisible}
                draggable={false}
                onHide={() => setIsVisible(false)}
                className="centered-dialog">
                <div className='text-center'>
                    <div className="flex">
                        <div className="card flex justify-content-center">
                            <Carousel value={props?.barcodeData} numScroll={1} numVisible={1} 
                            orientation="horizontal" 
                            itemTemplate={barcodeTempelate} />
                        </div>
                    </div>
                    <Button className='mt-4' onClick={() => setIsVisible(false)}>{props.btnLabel}</Button>
                </div>


            </Dialog>
        
    )
})

export default PrintBarcode