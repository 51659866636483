import React, { useRef, useEffect, useState } from "react";
import { Route, useNavigate } from "react-router-dom";
import PageHeader from "../../../shared/PageHeader";
import ExportPopup from "../../../shared/ExportPopup";
import VIVGrid from "../../../shared/VIV-Grid";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import httpClient from "../../../_util/api";
import GridFilter from "../../../shared/GridFilter";
import useDebounce from "../../../_util/UseDebounce";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import VivToast from "../../../shared/VivitechToast";

const ItemMasterList = () => {

    const userRoles = useSelector((state) => state.mainSlice.userRoles);
    const export_PopUpRef = useRef();
    const toast_Ref = useRef(null);
    const initialMount = useRef(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [headerItems, setheaderItems] = useState([{
        type: "import",
        icon: "i-import",
        containerClass: "cursor-pointer flex align-items-center gap-2",
    },
    {
        type: "export",
        icon: "i-export",
        containerClass: "cursor-pointer flex align-items-center gap-2",
    },
    {
        type: "button",
        icon: "",
        containerClass: "",
        classes: "",
        title: "Add Item",
        disabled: false
    },]);
    const [gridActionsList, setgridActionsList] = useState([{ label: "Edit", icon: "i-edit", disabled: false }, { label: "Delete", icon: "i-bin", disabled: false }]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const [searchString, setSearchString] = useState("");
    const [filterString, setFilterString] = useState("");
    const [gridData, setGridData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [recordsFrom, setRecordsFrom] = useState(0);
    const [recordsTo, setRecordsTo] = useState(0);
    const [showFilter, setShowFilter] = useState(false);

    useDebounce(() => { initialMount.current ? initialMount.current = false : LoadGridData(); }, [searchString], 1500);


    const grid_columns = [
        { field: 'upc', header: 'UPC', islink: true, statusfeild: false, visible: true },
        { field: 'modelNo', header: 'Model #', islink: false, statusfeild: false, visible: true },
        { field: 'brand', header: 'Brand', islink: false, statusfeild: false, visible: true },
        { field: 'category', header: 'Category', islink: false, statusfeild: false, visible: true },
        { field: 'condition', header: 'Condition', islink: false, statusfeild: false, visible: true },
        { field: 'averageCost', header: 'Avg Cost', islink: false, statusfeild: false, visible: true },
        { field: 'status', header: 'Status', islink: false, statusfeild: false, visible: true },
        { field: 'action', header: 'Actions', islink: false, statusfeild: false, visible: true }
    ];

    const filterData = [
        {
            label: "UPC",
            filter_key: "upcSearch",
            feild: "input",
            value: "",
        },
        {
            label: "Model #",
            filter_key: "modelNoSearch",
            feild: "input",
            value: "",
        },
        // {
        //   label: "Roles",
        //   filter_key: "brandSearch",
        //   feild: "multiselect",
        //   value: "",
        //   dataforselect: [
        //     { value: "admin", viewValue: "Admin" },
        //     { value: "supervisor", viewValue: "Supervisor" },
        //   ],
        // },
        {
            label: "Status",
            filter_key: "statusSearch",
            feild: "multiselect",
            value: "",
            dataforselect: [
                { value: "active", viewValue: "Active" },
                { value: "inactive", viewValue: "In-Active" },
            ],
        },
    ];

    const LoadGridData = async () => {
        dispatch(showLoader());

        let URL = `ItemMaster/GetItemMasterDetails?pageNumber=${currentPage}&pageSize=${pageSize}&searchTerm=${searchString}`;

        if (filterString !== "") {
            URL = URL + "&" + filterString;
        }
        const resp = await httpClient.get(URL).catch((error) => {
            dispatch(hideLoader());
            if (error?.response.status == 404) {
                toast_Ref.current.showMessage("error", "No record found", "", "i-notify");
                setTotalRecords(0);
                setRecordsFrom(0);
                setRecordsTo(0);
                setGridData([]);
            }
            else {
                toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
            }
        });

        if (resp?.data.isSuccess) {
            setTotalRecords(resp?.data.page_TotalRecords);
            setRecordsFrom(resp?.data.page_From);
            setRecordsTo(resp?.data.page_To);
            setGridData(resp?.data.details);
        }
        dispatch(hideLoader());
    };

    const LoadRolebase_Actions = () => {
        let currentHeaderItems = headerItems;
        let gridActionList = gridActionsList;
        let module = userRoles.find(r => r.moduleName == "Setup");

        if (module) {
            if (module.functions[0].actions[3].value) {
                currentHeaderItems[2].disabled = false;
            }
            else { currentHeaderItems[2].disabled = true; }

            if (module.functions[0].actions[1].value) {
                gridActionList[0].disabled = false;
            }
            else {
                gridActionList[0].disabled = true;
            }

            if (module.functions[0].actions[2].value) {
                gridActionList[1].disabled = false;
            }
            else {
                gridActionList[1].disabled = true;
            }

            setgridActionsList(gridActionList);
            setheaderItems(currentHeaderItems);
        }

    }

    useEffect(() => {
        LoadRolebase_Actions();
    }, []);

    useEffect(() => {
        LoadGridData();
    }, [currentPage, pageSize, filterString]);

    const handlePageHeaderAction = (data) => {
        if (data.actiontype == "export") {
            export_PopUpRef.current.showPopUp();
        } else if (data.actiontype == "button") {
            navigate("/setup/item/form");
        }
    };

    const handlePageChnage = (evnt) => {
        setCurrentPage(evnt.page == 0 ? 1 : evnt.page + 1);
        setpageSize(evnt.rows);
    };

    const handleFilters = (data) => {
        setFilterString(data);
    };

    const deleteItem = async (id) => {
        dispatch(showLoader());
        let resp = await httpClient.delete(`ItemMaster/RemoveProductSetup?id=${id}`).catch((error) => {
            //console.log(error); 
            dispatch(hideLoader());
            toast_Ref.current.showMessage("error", error?.message, "", "i-notify");
        });

        if (resp?.status == 200) {
            dispatch(hideLoader());
            toast_Ref.current.showMessage("success", "Item deleted successfully", "", "i-chk-circle");
            LoadGridData();
        }
    }

    const handleGridAction = (e) => {
        if (e?.action.toLowerCase() == "delete") {
            confirmDialog({
                message: 'Are you sure you want to delete this item?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                draggable: false,
                accept: () => { deleteItem(e.recordId) },
                reject: () => { }
            });
        }
        else {
            navigate(`/setup/item/form/${e?.recordId}`);
        }
    }


    return (
        <>
            <ConfirmDialog />
            <VivToast ref={toast_Ref} />
            <GridFilter
                data={filterData}
                visible={showFilter}
                visibleState={setShowFilter}
                onApplyFilter={handleFilters}
            />
            <div className="page_content pb-0">
                <ExportPopup ref={export_PopUpRef} title={`Export`} />
                <PageHeader onPageActoin={handlePageHeaderAction} PageTitle={'Item Master'} HeaderItems={headerItems} />

                <div className="mt-4">
                    <div className="flex gap-3 align-items-center justify-content-end">
                        <span className="p-input-icon-left">
                            <i className="i-search" />
                            <InputText placeholder="Search" value={searchString}
                                onChange={(e) => {
                                    setSearchString(e.target.value);
                                }} />
                        </span>

                        <Button label="Filter" icon="i-filter"
                            onClick={() => setShowFilter(true)}
                            className="bg-white justify-content-center clr-b" text raised />
                    </div>

                    <VIVGrid
                        gridActions={gridActionsList}
                        paginated={true}
                        gridCol={grid_columns}
                        selection={true}
                        gridData={gridData}
                        TotalRecords={totalRecords}
                        recordfrom={recordsFrom}
                        recordto={recordsTo}
                        centerAlign={true}
                        onLineAction={handleGridAction}
                        onPageChange={handlePageChnage}
                        baseURL={"/setup/item/detail/"}
                    />
                </div>

            </div>
        </>

    )
}

export default ItemMasterList;