import React, { useEffect, useState } from "react";

import PageHeader from "../../../../shared/PageHeader";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { useNavigate, useParams } from "react-router-dom";
import httpClient from "../../../../_util/api";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../redux/mainSlice";

const LocationDetail = () => {
  const navigate = useNavigate();
  const [activeTab, setactiveTab] = useState(0);
  const [zoneData, setZoneData] = useState("")
  const dispatch = useDispatch()
  const { id } = useParams();
  const userRoles = useSelector((state) => state.mainSlice.userRoles);
  const [headerItems, setheaderItems] = useState([{
    type: "editButton",
    icon: "",
    containerClass: "",
    classes: "",
    disabled: false
  }]);

  const LoadZoneData = async () => {
    dispatch(showLoader())

    const res = await httpClient.get(`/Zone/GetZoneByID?id=${id}`)
      .catch((error) => {
        dispatch(hideLoader())
      })

    if (res.status == 200) {
      dispatch(hideLoader())
      setZoneData(res?.data.data);
    }

  }


  const handlePageHeaderAction = (data) => {
    //alert(JSON.stringify(data));

    if (data.title == "Create Zone") {
      navigate("/setup/warehouse/zone/form");
    }

    if (data.title == "Create Location") {
      navigate("/setup/warehouse/location/form");
    }

    if (data.actiontype == "editButton") {
      navigate(`/setup/warehouse/zone/form/${zoneData.warehouseID}/${id}`);
    }
  };

  const LoadRolebase_Actions = () => {
    let currentHeaderItems = headerItems;
    let module = userRoles.find(r => r.moduleName == "Setup");

    if (module) {
      if (module.functions[1].actions[3].value) {
        currentHeaderItems[0].disabled = false;
      }
      else { currentHeaderItems[0].disabled = true; }

      setheaderItems(currentHeaderItems);
    }

  }

  useEffect(() => {
    LoadRolebase_Actions();
    LoadZoneData();
  }, []);


  useEffect(() => {
    LoadZoneData()
    //console.log(`Warehouse Id:${warehouseId}`);
  }, [])


  return (
    <>
      <div className="page_content pb-0">
        <PageHeader
          PageTitle={"Zone Detail"}
          onPageActoin={handlePageHeaderAction}
          showBackButton={true}
          backButtonRoute={`/setup/warehouse/zone/list/${zoneData.warehouseID}`}
          HeaderItems={headerItems}
        />
        <div className="user_tabs_content bg-white mt-3">
          <div className="grid pl-4 pr-4 mt-2"></div>
          <div className="grid pl-4 pr-4 mt-2">
            <div className="col-3">
              <p className="detail-lable">Zone Name</p>
              <p className="detail-text">{zoneData?.zoneName ? zoneData.zoneName : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Zone Code</p>
              <p className="detail-text">{zoneData?.zoneCode ? zoneData.zoneCode : '-'}</p>
            </div>
            <div className="col-6">
              <p className="detail-lable">Default Type</p>
              <p className="detail-text">{zoneData?.zoneTypeID ? (zoneData.zoneTypeID == 1 ? "Staging Area" : zoneData.zoneTypeID == 2 ? "Unmanifest Area" : "Recall Area") : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Description</p>
              <p className="detail-text">{zoneData?.description ? zoneData.description : '-'}</p>
            </div>
            <div className="col-3">
              <p className="detail-lable">Status</p>
              <p className="detail-text">
                <Badge severity={zoneData?.status == "Active" ? "success" : "danger"}></Badge>&nbsp;
                {zoneData?.status ? zoneData.status : '-'}
              </p>
            </div>
          </div>
        </div>
        <div className="page_footer flex gap-3 justify-content-end pt-3">
          <Button
            label={"Close"}
            className="btn-secondary"
            onClick={() => navigate(`/setup/warehouse/zone/list/${zoneData.warehouseID}`)}
          />
        </div>
      </div>
    </>
  );
};

export default LocationDetail;
