import React, { useState, useRef, useEffect } from "react";
import PageHeader from "../../../shared/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../../shared/Form-Input";
import { Button } from "primereact/button";
import VIV_Dropdown from "../../../shared/Form-Select";
import httpClient from "../../../_util/api";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import VivToast from "../../../shared/VivitechToast";
import { InputSwitch } from "primereact/inputswitch";

const WarehouseForm = () => {

    const { id } = useParams();
    const [timeZones, setTimeZones] = useState([]);
    const [formData, setFormData] = useState({
        warehouseName: "",
        contactPersonName: "",
        contactNumber: "",
        emailAddress: "",
        sizeWarehouse: "",
        timeZone: "",
        country: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        status: "Active",
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast_Ref = useRef(null);

    const handlePageHeaderAction = (data) => { };

    const handleInputChange = (data, field) => {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: data }));
    };

    const handleDDChange = (data, field) => {
        setFormData((prevFormData) => ({ ...prevFormData, [field]: data }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(showLoader());
        if (id) {
            let apiResp = await httpClient.put("Warehouse/UpdateWarehouse", formData).catch((error) => {
                dispatch(hideLoader()); 
                if(error?.response.data.errors.length){
                    toast_Ref.current.showMessage(
                        "error", error?.response.data.errors[0].error, "", "i-notify");    
                }
                else{
                    toast_Ref.current.showMessage(
                        "error", error?.response.data.message, "", "i-notify");
                }
            });

            if (apiResp?.status == 200) {
                dispatch(hideLoader());
                toast_Ref.current.showMessage(
                    "success", "Warehouse updated successfully", "", "i-chk-circle");

                setTimeout(() => {
                    navigate("/setup/warehouse/list");
                }, 1000);
            }
        }
        else {
            let apiResp = await httpClient.post("Warehouse/CreateWarehouse", formData).catch((error) => {
                dispatch(hideLoader());
                if(error?.response.data?.errors?.length){
                    toast_Ref.current.showMessage(
                        "error", error?.response.data.errors[0].error, "", "i-notify");    
                }
                else{
                    toast_Ref.current.showMessage(
                        "error", error?.response.data.message, "", "i-notify");
                }
            });

            if (apiResp?.status == 200) {
                dispatch(hideLoader());
                toast_Ref.current.showMessage(
                    "success", "Warehouse created successfully", "", "i-chk-circle");

                setTimeout(() => {
                    navigate("/setup/warehouse/list");
                }, 1000);
            }
        }

    }

    const fetchwarehouseDetail = async () => {
        dispatch(showLoader());
        let apiResp = await httpClient.get("Warehouse/GetWarehouseByID?id=" + id).catch((error) => {
            dispatch(hideLoader());
            toast_Ref.current.showMessage(
                "error", error?.response.data.message, "", "i-notify");
        });

        if (apiResp?.status == 200) {
            setFormData(apiResp?.data.data);
            dispatch(hideLoader());
        }
    }

    const LoadTimeZones = async () => {
        let apiResp = await httpClient.get("TimeZone/GetAllTimeZones").catch((error) => { dispatch(hideLoader()); });

        if (apiResp?.status == 200) {
            setTimeZones(apiResp?.data.data);
            dispatch(hideLoader());
        }
    }

    useEffect(() => {
        if (id) {
            fetchwarehouseDetail();
        }
        LoadTimeZones();
    }, []);

    return (
        <>
            <VivToast ref={toast_Ref} />
            <form onSubmit={handleSubmit} className="page_content pb-0">
                <PageHeader
                    onPageActoin={handlePageHeaderAction}
                    PageTitle={id ? "Update Warehouse" : "Create Warehouse"}
                    showBackButton={true}
                    backButtonRoute="/setup/warehouse/list"
                    HeaderItems={[]}
                />

                <div className="content-section bg-white mt-3">
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <FormInput
                                title={"Warehouse Name"}
                                value={formData.warehouseName}
                                required={true}
                                onchange={(e) => handleInputChange(e, "warehouseName")}
                            />
                        </div>
                        <div className="col-4">
                            <FormInput
                                title={"Contact Person Name"}
                                value={formData.contactPersonName}
                                onchange={(e) => handleInputChange(e, "contactPersonName")}
                            />
                        </div>
                    </div>
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <FormInput
                                title={"Contact Number"}
                                value={formData.contactNumber}
                                inputtype={"phone"}
                                onchange={(e) => handleInputChange(e, "contactNumber")}
                            />
                        </div>
                        <div className="col-4">
                            <FormInput
                                title={"Email Address"}
                                inputtype={"email"}
                                required={true}
                                value={formData.emailAddress}
                                onchange={(e) => handleInputChange(e, "emailAddress")}
                            />
                        </div>
                    </div>
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <FormInput
                                title={"Size of Warehouse"}
                                value={formData.sizeWarehouse}
                                onchange={(e) => handleInputChange(e, "sizeWarehouse")}
                            />
                        </div>
                        <div className="col-4">
                            <VIV_Dropdown
                                title={"Time Zone"}
                                selectedOption={formData.timeZone}
                                options={timeZones}
                                optionLabel={"timeZoneUTC"}
                                optionValue={"timeZoneUTC"}
                                onSelect={(e) => handleDDChange(e, "timeZone")}
                            />
                        </div>
                    </div>
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <VIV_Dropdown
                                title={"Country"}
                                required={true}
                                selectedOption={formData.country}
                                optionLabel={"name"}
                                optionValue={"name"}
                                options={[
                                    { name: "USA", id: "USA" },
                                    { name: "Mexico", id: "Mexico" },
                                    { name: "Canada", id: "Canada" },
                                ]}
                                onSelect={(e) => handleDDChange(e, "country")}
                            />

                        </div>
                        <div className="col-4">
                            <FormInput
                                title={"Address 1"}
                                required={true}
                                value={formData.address1}
                                onchange={(e) => handleInputChange(e, "address1")}
                            />
                        </div>
                    </div>
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <FormInput
                                title={"Address 2"}
                                value={formData.address2}
                                onchange={(e) => handleInputChange(e, "address2")}
                            />
                        </div>
                        <div className="col-4">
                            <FormInput
                                title={"City"}
                                required={true}
                                value={formData.city}
                                onchange={(e) => handleInputChange(e, "city")}
                            />
                        </div>
                    </div>
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <FormInput
                                title={"State/Province"}
                                required={true}
                                value={formData.state}
                                onchange={(e) => handleInputChange(e, "state")}
                            />

                        </div>
                        <div className="col-4">
                            <FormInput
                                title={"Postal Code"}
                                value={formData.postalCode}
                                onchange={(e) => handleInputChange(e, "postalCode")}
                            />
                        </div>
                    </div>
                    <div className="grid pl-4 pr-4 mt-2">
                        <div className="col-4">
                            <div className="flex flex-column gap-2">
                                <label>Is Active</label>
                                <InputSwitch
                                    value={formData.status}
                                    checked={formData.status == "Active" ? true : false}
                                    onChange={(e) =>
                                        setFormData((prevFormData) => ({
                                            ...prevFormData,
                                            ["status"]: e.value ? "Active" : "Inactive",
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page_footer flex gap-3 justify-content-end pt-3">
                    <Button
                        label={"Cancel"}
                        type="button"
                        className="btn-secondary"
                        onClick={() => navigate("/setup/warehouse/list")}
                    />
                    <Button
                        label={id ? "Update" : "Create"}
                        disabled={formData.warehouseName == "" || formData.emailAddress == "" || formData.country == "" || formData.city == "" ||
                            formData.address1 == "" || formData.state == ""}
                    />
                </div>
            </form>
        </>
    );
}

export default WarehouseForm;