import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import './style.scss';

const VIV_DocViewer = (props) => {
    return (
        <DocViewer
            documents={props?.documents ? props?.documents : []}
            pluginRenderers={DocViewerRenderers}
            config={{ header: { disableHeader: true } }} />
    )
}

export default VIV_DocViewer;