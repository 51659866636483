import React, { useState, useRef } from "react";
import "./scan.scss";
import PackageScan from "./components/PackageScan";
import ScanRmaDetail from "./components/ScanRmaDetail";
import { useDispatch } from 'react-redux';
import { shrinkSideBar } from "../../../redux/sidebarSlice";
import VivToast from "../../../shared/VivitechToast";


const ScanRMA = () => {
    const dispatch = useDispatch();
    const toast_Ref = useRef(null);
    const [viewMode, setViewMode] = useState("PackageScan");
    const [pakgInfo, setPakgInfo] = useState({});

    const handleViewModeChange = (mode, data) => {
        dispatch(shrinkSideBar());
        setViewMode(mode);

        if(mode == "PackageScan"){
            if(data !== ""){
                toast_Ref.current.showMessage(
                    "success", data, "", "i-chk-circle");   
            }
            setPakgInfo({});
        }
        else{
            setPakgInfo(data);
        }
    }


    return (
        <>
        <VivToast ref={toast_Ref} />
            {viewMode == "PackageScan" && <PackageScan changeMode={(e) => { handleViewModeChange("ScanDetail", e); }} />}
            {viewMode == "ScanDetail" && <ScanRmaDetail pakgDetail={pakgInfo} changeMode={(e) => { handleViewModeChange("PackageScan", e); }} />}
        </>

    )
}

export default ScanRMA;